import React, { useState } from 'react'
import './employeess.css'

import user1 from '../../assets/images/users/avatar-1.jpg'
import user2 from '../../assets/images/users/avatar-2.jpg'
import user3 from '../../assets/images/users/avatar-3.jpg'
import user4 from '../../assets/images/users/avatar-4.jpg'
import user5 from '../../assets/images/users/avatar-5.jpg'
import user6 from '../../assets/images/users/avatar-6.jpg'
import user7 from '../../assets/images/users/avatar-7.jpg'
import user8 from '../../assets/images/users/avatar-8.jpg'
import user9 from '../../assets/images/users/avatar-9.jpg'
import user10 from '../../assets/images/users/avatar-10.jpg'
import {  Card, Col, Modal, Select, Spin } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import {AiFillDelete, AiOutlineEdit} from 'react-icons/ai'
import {Container, DropdownItem, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane, UncontrolledDropdown, CardHeader, CardBody } from "reactstrap";

import classnames from "classnames";
import axios from 'axios'
import { useEffect } from 'react'
import { toast } from 'react-toastify'




function Customer() {
  const localData=localStorage.getItem('authUser');
  let userAuth=localData&&JSON.parse(localData);

  const navigate=useNavigate();
  const [pageloading,setpageloading]=useState(true);
    const [employeedata,setemployeedata]=useState({
    name:'',
    phone:'',
    job:'',
    address:'',
    type:'',
  });
  const [addloading,setaddloading]=useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [employeessdata,setemployeessdata]=useState([]);
  const [showleco,setshowleco]=useState("leads");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
      if(employeedata.name==""){
      toast.warn("enter client name");
      return;
    }
    if(employeedata.phone==""||isNaN(employeedata.phone)){
      toast.warn("enter client phone(valied)");
      return;
    }
    if(employeedata.job==""){
      toast.warn("enter client job");
      return;
    }
    if(employeedata.address==""){
      toast.warn("enter client address");
      return;
    }
    setIsModalOpen(false);
    const data_Send={
      ...employeedata,
      added_by_agent_id:userAuth.agent_id,
    }
    console.log(data_Send);
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/insert_client.php",data_Send)
    .then((res)=>{
      console.log(res)
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else {
        toast.error("something is error try again");
      }
    }).finally(()=>{
      setaddloading(false);
    })
  };
  const handleCancel = () => {
    setIsModalOpen(false);

  };

  const geteployees=()=>{
    axios.get("https://ahmed.its.ae/new_quad_wall/admin/select_client.php")
    .then((res)=>{
      //console.log(res)
      console.log(res);
      setemployeessdata(res.message);
    }).finally(()=>{
      setpageloading(false);
    })
  }
  useEffect(()=>{
    geteployees();
  },[])
  function leadscustomer(){
    return(
      <table className="table table-nowrap table-hover mb-0">
      <thead>
          <tr>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col">phone</th>
              <th scope="col">action</th>
{/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
      </thead>
      <tbody>
        {
          employeessdata.filter((ite)=>ite.client_type=="leads").map((item,index)=>{
            return(
              <tr>
              <th
              onClick={()=>{
                //navigate("/clientprofile",{state:{data:item}});
                navigate("/clientprofile2",{state:{data:item}});
              }}
              style={{
                cursor:'pointer',
                color:'blue',
                textDecoration:'underline'
              }} scope="row">{item.client_id}</th>
              <td><Link to="#" className="text-dark">{item.name}</Link></td>
              <td><Link to="#" className="text-dark">{item.phone}</Link></td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item.user_email}</span>
              </td>
              <td>
                <AiFillDelete style={{color:'red',fontSize:'30px',cursor:'pointer'}}/>
                <AiOutlineEdit style={{color:'green',fontSize:'30px',cursor:'pointer'}}/>
              </td>
          </tr>
            )
          })
        }

      </tbody>
      </table>
    )
  }
  function coldcustomer(){
    return(
      <table className="table table-nowrap table-hover mb-0">
      <thead>
          <tr>
              <th scope="col">id</th>
              <th scope="col">name</th>
              <th scope="col">phone</th>
              <th scope="col">action</th>
{/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
      </thead>
      <tbody>
        {
          employeessdata.filter((ite)=>ite.client_type=="cold").map((item,index)=>{
            return(
              <tr>
              <th
              onClick={()=>{
                //navigate("/clientprofile",{state:{data:item}});
                navigate("/clientprofile2",{state:{data:item}});
              }}
              style={{
                cursor:'pointer',
                color:'blue',
                textDecoration:'underline'
              }} scope="row">{item.client_id}</th>
              <td><Link to="#" className="text-dark">{item.name}</Link></td>
              <td><Link to="#" className="text-dark">{item.phone}</Link></td>
              <td>
                  <span className="badge badge-soft-primary font-size-12">{item.user_email}</span>
              </td>
              <td>
                <AiFillDelete style={{color:'red',fontSize:'30px',cursor:'pointer'}}/>
                <AiOutlineEdit style={{color:'green',fontSize:'30px',cursor:'pointer'}}/>
              </td>
          </tr>
            )
          })
        }

      </tbody>
  </table>
    )
  }
  function renderfuncs(){
    if(showleco=="leads"){
      return leadscustomer();
    }
    else {
      return coldcustomer();
    }
  }
  return <>
  {
    pageloading?(
      [1, 2, 3].map(x => (
        <Col xs={24} md={12} lg={8} key={x}>
          <Card loading minHeight={200} />
        </Col>
      ))
    ):(
      <div className="container-fluid py-5">

      <div className="page-content py-5">
       <div className="row">

         <div className="col-lg-6">
       <h2>Customer</h2>
         </div>

         <div className="col-lg-6 text-end">

         <Modal title="Add employee" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} centered>
             <form>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,name:e.target.value});
               }} type="text" placeholder='enter client name'/>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,phone:e.target.value});
               }} type="text" placeholder='enter client phone'/>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,job:e.target.value});
               }} type="text" placeholder='enter client job'/>
               <input onChange={(e)=>{
                 setemployeedata({...employeedata,address:e.target.value});
               }} type="text" placeholder='enter client address'/>
               <Select
      defaultValue="lucy"
      style={{
        width: "100%",
      }}
      onChange={(e)=>{
        setemployeedata({...employeedata,type:e});
      }}
      options={[
        {
          value: 'cold',
          label: 'cold',
        },
        {
          value: 'leads',
          label: 'leads',
        },
      ]}
    />
             </form>
         </Modal>

         {
           addloading?(
             <Spin/>
           ):(
             <button className='btn btn-outline-info' type="button"
             onClick={showModal}>
               Add client
             </button>
           )
         }
         </div>
         <div className="methods_btns">
          <button
            onClick={()=>{
              setshowleco("leads");
            }}
          className={`${showleco=="leads"?'active':''}`}>leads</button>
          <button
          onClick={()=>{
            setshowleco("cold");
          }}
          className={`${showleco=="cold"?'active':''}`}>cold</button>
        </div>
        {
          renderfuncs()
        }
         {/* <table className="table table-nowrap table-hover mb-0">
           <thead>
               <tr>
                   <th scope="col">id</th>
                   <th scope="col">name</th>
                   <th scope="col">phone</th>
                   <th scope="col">action</th>
               </tr>
           </thead>
           <tbody>
             {
               employeessdata.map((item,index)=>{
                 return(
                   <tr>
                   <th
                   onClick={()=>{
                     //navigate("/clientprofile",{state:{data:item}});
                     navigate("/clientprofile2",{state:{data:item}});
                   }}
                   style={{
                     cursor:'pointer',
                     color:'blue',
                     textDecoration:'underline'
                   }} scope="row">{item.client_id}</th>
                   <td><Link to="#" className="text-dark">{item.name}</Link></td>
                   <td><Link to="#" className="text-dark">{item.phone}</Link></td>
                   <td>
                       <span className="badge badge-soft-primary font-size-12">{item.user_email}</span>
                   </td>
                   <td>
                     <AiFillDelete style={{color:'red',fontSize:'30px',cursor:'pointer'}}/>
                     <AiOutlineEdit style={{color:'green',fontSize:'30px',cursor:'pointer'}}/>
                   </td>
               </tr>
                 )
               })
             }
           </tbody>
       </table> */}
       </div>
      </div>
   </div>
    )
  }



  </>
}

export default Customer
