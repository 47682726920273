import React from 'react'
import { useContext } from 'react'
import { AppContext } from '../../components/context/AppContextProvider'
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import { Card, Col, Spin } from 'antd';
import { MdEditNote } from "react-icons/md";
import { FidgetSpinner, ThreeDots } from "react-loader-spinner";
import { AiFillDelete, AiOutlineEdit } from 'react-icons/ai';
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Select } from 'antd'
import { toast } from 'react-toastify';
const Developers = () => {
  const navigate=useNavigate();
  const {user}=useContext(AppContext);
  const [newClient,setNewClient]=useState({
    name:'',
    phone:'',
    job:'',
    address:'',
  });
  const [showAddByExcel,setShowAddByExcel]=useState(false)
  const [addLoading,setAddLoading]=useState(false)
  const [loading,setloading]=useState(true);
  const [developers,setDevelopers]=useState([]);
  const [rowData,setRowData]=useState({});
  const [showEdit,setShowEdit]=useState(false);
  const [showAdd,setShowAdd]=useState(false);
  const [editLoading,setEditLoading]=useState(false)
  const [newDev,setNewDev]=useState({
    developer_name:'',
    company_name:'',
    company_description:'',
  })
  const [developersFile,setDevelopersFile]=useState(null);
  const getDevelopers=()=>{
    setloading(true)
    axios.get("https://ahmed.its.ae/new_quad_wall/admin/get_developers.php")
    .then((res)=>{
      if(Array.isArray(res.message)){
        setDevelopers(res.message)
      }
      console.log(res)
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setloading(false)
    })
  }
  useEffect(()=>{
    getDevelopers()
  },[])

  const handleAddByExcel=()=>{
    setAddLoading(true)
    const formData=new FormData();
    formData.append("file_attachment",developersFile);
    axios.post('https://ahmed.its.ae/new_quad_wall/admin/upload_excel_file_for_developers.php',formData)
    .then((res)=>{
      console.log(res)
      toast.success(`done for ${res.add_counter}`)
      toast.warn(`duplication for ${res.duplication_counter}`)
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setAddLoading(false)
    })
  }
  const handleEditDev=()=>{
    setEditLoading(true)
    const data_send={
      ...rowData
    }
    axios.post('https://ahmed.its.ae/new_quad_wall/admin/edit_developer.php',data_send)
    .then((res)=>{
      if(res.status=='success'){
        toast.success(res.message)
        setShowEdit(false)
        getDevelopers()
      }
      else if(res.status=='faild'){
        toast.error(res.message)
      }
      else{
        toast.error('Something Went Error')
      }
    })
    .finally(()=>{
      setEditLoading(false);
    })
    .catch(e=>console.log(e))
  }
  const handleAddDev=()=>{
    if(newDev.developer_name==''){
      toast.warn('Enter Developer Name');
      return
    }
    if(newDev.company_name==''){
      toast.warn('Enter Company Name');
      return
    }
    if(newDev.company_description==''){
      toast.warn('Enter Company Description');
      return
    }

    setAddLoading(true)
    const data_send={
      ...newDev
    }
    console.log(data_send)
    axios.post('https://ahmed.its.ae/new_quad_wall/admin/add_new_developer.php',data_send)
    .then((res)=>{
      if(res.status=='success'){
        toast.success(res.message)
        getDevelopers()
        setShowAdd(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else {
        toast.error("Something Went Error")
      }
    })
    .finally(()=>{
      setAddLoading(false)
    })
    .catch(e=>{
      console.log(e)
    })
  }
  return (
    <>
      {
        loading?(
          [1, 2, 3].map(x => (
            <Col  xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div className='container py-6' style={{marginTop:'78px'}}>
            <div className='d-flex justify-content-between my-5 mt-6 pt-3'>
              <h5 onClick={()=>{
                setShowAdd(true)
              }} style={{cursor:'pointer'}}>
                <span>Add</span>
                <span>+</span>
              </h5>
              <div>
                <button onClick={()=>{
                  setShowAddByExcel(true)
                }} className='btn btn-success'>Add By Excel</button>
              </div>
            </div>
            <table className="table mb-0 table-bordered">
              <thead>
                <tr style={{textAlign:'center'}}>
                  <th>id</th>
                  <th>Developer name</th>
                  <th>Company name</th>
                  <th>Company description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  developers.map((item,index)=>{
                    return(
                      <tr>
                        <td onClick={()=>{
                          navigate('/dev_projects',{state:{devData:item}})
                        }} style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}>{item.developer_id}</td>
                        <td>{item.developer_name}</td>
                        <td>{item.company_name}</td>
                        <td>{item.company_description}</td>
                        <td>
                          <MdEditNote onClick={()=>{
                            setRowData(item)
                            setShowEdit(true)
                          }} style={{color:'green',cursor:'pointer',fontSize:'30px'}}/>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        )
      }

      <Modal title="Add Developers In Excel Sheet" open={showAddByExcel} onOk={handleAddByExcel} onCancel={()=>{
        setShowAddByExcel(false)
      }}>
        <>
          <input
            style={{
              width:'100%',
              marginTop:'20px'
            }}
            type="file"
            onChange={(e)=>{
              setDevelopersFile(e.target.files[0])
            }}
          />
          {
            addLoading?
            <FidgetSpinner style={{color:'blue'}}/>
            :
            null
          }
        </>

      </Modal>


      <Modal title="Edit Developer Data" open={showEdit} onOk={handleEditDev} onCancel={()=>{
        setShowEdit(false)
      }}>
        <>
          <form action="">
            <div style={{width:'100%'}}>
              <label htmlFor="">Developer Name</label>
              <input type="text" placeholder='Developer Name' value={rowData.developer_name} onChange={(e)=>{
                setRowData({...rowData,developer_name:e.target.value})
              }} />
            </div>
            <div style={{width:'100%'}}>
              <label htmlFor="">Company Name</label>
              <input type="text" placeholder='Developer Name' value={rowData.company_name} onChange={(e)=>{
                setRowData({...rowData,company_name:e.target.value})
              }} />
            </div>
            <div style={{width:'100%'}}>
              <label htmlFor="">Company Description</label>
              <input type="text" placeholder='Developer Name' value={rowData.company_description} onChange={(e)=>{
                setRowData({...rowData,company_description:e.target.value})
              }} />
            </div>
          </form>
          {
            editLoading?
            <FidgetSpinner style={{color:'blue'}}/>
            :
            null
          }
        </>

      </Modal>

      <Modal title="Edit Developer Data" open={showAdd} onOk={handleAddDev} onCancel={()=>{
        setShowAdd(false)
      }}>
        <>
          <form action="">
            <div style={{width:'100%'}}>
              <label htmlFor="">Developer Name</label>
              <input type="text" placeholder='Developer Name' onChange={(e)=>{
                setNewDev({...newDev,developer_name:e.target.value})
              }} />
            </div>
            <div style={{width:'100%'}}>
              <label htmlFor="">Company Name</label>
              <input type="text" placeholder='Developer Name' onChange={(e)=>{
                setNewDev({...newDev,company_name:e.target.value})
              }} />
            </div>
            <div style={{width:'100%'}}>
              <label htmlFor="">Company Description</label>
              <input type="text" placeholder='Developer Name' value={rowData.company_description} onChange={(e)=>{
                setNewDev({...newDev,company_description:e.target.value})
              }} />
            </div>
          </form>
          {
            addLoading?
            <FidgetSpinner style={{color:'blue'}}/>
            :
            null
          }
        </>

      </Modal>
    </>
  )
}

export default Developers
