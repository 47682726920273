import { Modal, Spin } from 'antd';
import React from 'react'
import { useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify';
import './buildingdetials.css'
const BuildingDetials = () => {
  const [inputList, setinputList]= useState([{amenite_data :''}]);
  const [inputList2, setinputList2]= useState([{title:'', value:''}]);
  const [image,setimage]=useState("");
  const [imagesarr,setimagesarr]=useState([]);
  const location=useLocation();
  const {data}=location.state;
  //console.log(data)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isModalOpen3, setIsModalOpen3] = useState(false);
  const [uploadloading,setuploadloading]=useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const showModal3 = () => {
    setIsModalOpen3(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const handleCancel3 = () => {
    setIsModalOpen3(false);
  };
  const handleaddclick=()=>{
    setinputList([...inputList, {amenities_data :''}]);
  }

  const handleinputchange=(e, index)=>{
    const {name, value}= e.target;
    const list= [...inputList];
    list[index][name]= value;
    setinputList(list);
  }
  const handleinputchange2=(e, index)=>{
    const {name, value}= e.target;
    const list= [...inputList2];
    list[index][name]= value;
    setinputList(list);
  }

  const handleaddclick2=()=>{
    setinputList2([...inputList2, { title:'', value:''}]);
  }
  const handleOk = () => {
    setIsModalOpen(false);
    const sendedarr=[...inputList];
    const arr=[...inputList];
    let money="";

    for(let i=0;i<arr.length;i++){
      if(i==0){
        const str=arr[i].amenite_data;
        //console.log(str)
        money+=str;
        //console.log(money);
      }
      else{
        const str="**UAD**"+arr[i].amenite_data;
        //console.log(str)
        money+=str;
        //console.log(money);
      }
    }
    const data_send={
      builder_id:data.item_id,
      amenities_data:money,
    }
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/insert_amenities.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again");
      }
    })
  };
  const handleOk2 = () => {

    const arr=[...inputList2];
    let money="";

    for(let i=0;i<arr.length;i++){
      if(i==0){
        const str=arr[i].title+"**"+arr[i].value+" "+"sq feet";
        money+=str;
      }
      else{
        const str="**UAD**"+arr[i].title+"**"+arr[i].value+" "+"sq feet";
        money+=str;
      }
    }
    const data_send={
      feature_data:money,
      builder_id:data.item_id,
    }
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/insert_facts_features.php",JSON.stringify(data_send))
      .then((res)=>{
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again");
      }
    })
    setIsModalOpen2(false);

  };
  const uploadimage=()=>{
    if(image==""){
      toast.warn("enter image");
      return;
    }
    const formdata=new FormData();
    formdata.append("image",image);
    setuploadloading(true);
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/upload_img.php",formdata)
    .then((res)=>{
      console.log(res)
      if(res=="error"){
        toast.error(res);
      }
      else {
        toast.success("done");
        setimagesarr([...imagesarr,res]);
      }
    /*   if(res.status==="success"){
        toast.success(res.message);
        setimagesarr([...imagesarr,res.message]);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again");
      } */
    }).finally(()=>{
      setuploadloading(false);
    })
  }
  const handleOk3 = () => {
    setIsModalOpen3(false);
    const allimages=[...imagesarr];
    let imagestxt="";
    for(let i=0;i<allimages.length;i++){
      if(i==0){
        imagestxt+=allimages[i];
      }
      else{
        imagestxt+="**UAD**"+allimages[i];
      }
    }

    const data_Send={
      image_data:imagestxt,
      builder_id:data.item_id
    }
    console.log(data_Send);
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/insert_floor_plan.php",JSON.stringify(data_Send))
    .then((res)=>{
      if(res.status==="success"){
        toast.success(res.message);
      }
      else if(res.status==="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again");
      }
    })

  };

  return (
    <div className='container py-5' style={{
      marginTop:'40px',
      minHeight:'100vh'
    }}>
        <div className='basic_add_div'>
        <div>
        <h6>add features</h6>
      <AiOutlinePlus style={{cursor:'pointer'}} onClick={showModal}/>
        </div>
        <div style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between'
        }}>
          <h6>add facts and feature</h6>
          <AiOutlinePlus style={{cursor:'pointer'}} onClick={showModal2}/>

        </div>
        <div style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'space-between'
        }}>
          <h6>add floor plans</h6>
          <AiOutlinePlus style={{cursor:'pointer'}} onClick={showModal3}/>

        </div>
        </div>
        <Modal title="Add Facts and Feature" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel2}>
      {
        inputList2.map( (x,i)=>{
        return(
        <div className="row mb-3"
        key={i}
        >
          <div class="div_input col-md-6">
          <label >Money title</label>
            <input type="text"  name="title" class="form-control"  placeholder="Enter feature title" onChange={ e=>handleinputchange2(e,i)} />
        </div>
        <div class="div_input col-md-6">
        <label >Money Value</label>
            <input type="text"  name="value" class="form-control"   placeholder="Enter feature value" onChange={ e=>handleinputchange2(e,i) }/>
        </div>
        <div class="div_input col-md-12 mt-12">
        { inputList2.length-1===i &&
        <button style={{ marginTop:'5px' }} className="btn btn-success" onClick={ handleaddclick2}>Add More</button>
        }
        </div>
      </div>
        );
      } )}
        </Modal>
        <Modal title="Add Foolr Plan" open={isModalOpen3} onOk={handleOk3} onCancel={handleCancel3}>
            <div className='uploadimage_idv'>
              <input type="file" onChange={(e)=>{
                  setimage(e.target.files[0]);
                  //setimagesarr([...imagesarr,e.target.files[0]]);
  /*                 if(images==""){
                    setimages(e.target.value)
                  }
                  else setimages(images,"**UAD**",e.target.value); */
                }}/>
                <button
                  style={{ border:'none' }}
                  disabled={uploadloading}
                  onClick={(e)=>{
                    uploadimage();
                  }}
                >
                  {
                    uploadloading?(
                      <Spin/>
                    ):(
                      'up'
                    )
                  }
                </button>
            </div>
        </Modal>
        <Modal title="Add New Feature" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
          {
          inputList.map( (x,i)=>{
            return(
            <div className="row mb-3"
            key={i}
            >
            <div class="div_input col-md-6">
            <label >Feature</label>
                <input type="text"  name="amenite_data" class="form-control" placeholder="Enter feature name" onChange={ e=>handleinputchange(e,i) }/>
            </div>
            <div class="div_input col-md-12 mt-12">
            { inputList.length-1===i &&
            <button style={{ marginTop:'5px' }} className="btn btn-success" onClick={ handleaddclick}>Add More</button>
            }
            </div>
          </div>
            );
          } )}
        </Modal>
    </div>
  )
}

export default BuildingDetials
