import React from "react";
import { useContext } from "react";
import { AppContext } from "../context/AppContextProvider";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Card, Col } from "antd";
import { ThreeDots } from "react-loader-spinner";
import { AiFillDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { Modal, Select } from "antd";
import { toast } from "react-toastify";
import { MdOutlineApartment } from "react-icons/md";
import { BiBuildingHouse } from "react-icons/bi";
import { BsHouseSlashFill, BsHouses } from "react-icons/bs";
import moment from "moment";
import "./sellesAssign.css";
import { useSelector } from "react-redux";
const SellesAssign = () => {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  // console.log(user)
  const [showAddModal, setShowAddModal] = useState(false);
  const [clientId, setClientId] = useState("");
  const [not_answer_Content, setnot_answer_Content] = useState("");
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const [rowData, setRowData] = useState({});
  const [showEdit, setShowEdit] = useState(false);
  const [paymentPlanFile, setPaymentPlanFile] = useState(null);

  const [newClient, setNewClient] = useState({
    name: "",
    phone: "",
    job: "",
    address: "",
    type: "cold",
  });
  const [followAffterMeeting, setFollowAffterMeeting] = useState("");
  const [layoutFile, setLayOutFile] = useState(null);
  const [sublocations, setsublocations] = useState([]);
  const [suplocation_id, setsuplocation_id] = useState("");
  const [coumpound_id, setcoumpound_id] = useState("");
  const [location_id, setlocation_id] = useState("");
  const [date, setdate] = useState("");
  const [note, setnote] = useState("");
  const [answertype, setanswertype] = useState("");
  const [time, settime] = useState("");
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 500000,
      label: "500,000",
    },
    {
      value: 1000000,
      label: "1000,000",
    },
    {
      value: 1500000,
      label: "1,500,000",
    },
    {
      value: 2000000,
      label: "2,000,000",
    },
    {
      value: 2500000,
      label: "2,500,000",
    },
    {
      value: 3000000,
      label: "3,000,000",
    },
    {
      value: 3500000,
      label: "3,500,000",
    },
    {
      value: 4000000,
      label: "4,000,000",
    },
    {
      value: 4500000,
      label: "4,500,000",
    },
    {
      value: 5000000,
      label: "5,000,000",
    },
    {
      value: 5500000,
      label: "5,500,000",
    },
    {
      value: 6000000,
      label: "6,000,000",
    },
    {
      value: 6500000,
      label: "6,500,000",
    },
    {
      value: 7000000,
      label: "7,000,000",
    },
    {
      value: 7500000,
      label: "7,500,000",
    },
    {
      value: 8000000,
      label: "8,000,000",
    },
    {
      value: 8500000,
      label: "8,500,000",
    },
    {
      value: 9000000,
      label: "9,000,000",
    },
    {
      value: 9500000,
      label: "9,500,000",
    },
    {
      value: 10000000,
      label: "10,000,000",
    },
    {
      value: 11000000,
      label: "11,000,000",
    },
    {
      value: 11500000,
      label: "11,500,000",
    },
    {
      value: 12000000,
      label: "12,000,000",
    },
    {
      value: 12500000,
      label: "12,500,000",
    },
    {
      value: 13000000,
      label: "13,000,000",
    },
    {
      value: 13500000,
      label: "13,500,000",
    },
    {
      value: 14000000,
      label: "14,000,000",
    },
    {
      value: 14500000,
      label: "14,500,000",
    },
    {
      value: 15000000,
      label: "15,000,000",
    },
    {
      value: 15500000,
      label: "15,500,000",
    },
    {
      value: 16000000,
      label: "16,000,000",
    },
    {
      value: 16500000,
      label: "16,500,000",
    },
    {
      value: 17000000,
      label: "17,000,000",
    },
    {
      value: 17500000,
      label: "17,500,000",
    },
    {
      value: 18000000,
      label: "18,000,000",
    },
    {
      value: 18500000,
      label: "18,500,000",
    },
    {
      value: 19000000,
      label: "19,000,000",
    },
    {
      value: 19500000,
      label: "19,500,000",
    },
    {
      value: 20000000,
      label: "20,000,000",
    },
  ];

  const [compound_id, setcompound_id] = useState("");

  const [compoundes, setcompoundes] = useState([]);
  const [link, setlink] = useState("");
  const [masterPlanFile, setMasterPlanFile] = useState(null);
  const [answer_after, setanswer_after] = useState("");
  const [bedroomsnumber, setbedroomsnumber] = useState("");
  const [bathroomnumber, setbathroomnumber] = useState("");
  const [delivary, setdelivary] = useState("");
  const [saletype, setsaletype] = useState("");
  const [finishigstatus, setfinishigstatus] = useState("");
  const [install, setinstall] = useState("");
  const [money_from, setmoney_from] = useState("");
  const [money_to, setmoney_to] = useState("");
  const [minsqu, setminsqu] = useState("");
  const [maxsqu, setmaxsqu] = useState("");
  const [downpay, setdownpay] = useState("");
  const [monthinstall, setmonthinstall] = useState("");
  const [serviceApart, setServiceApart] = useState("residential");
  const [prottype, setprottype] = useState("");
  const [calltype, setcalltype] = useState("");
  const [feedBack, setFeedBack] = useState("");
  const [sendWhats, setSendWhats] = useState(false);
  const [locations, setlocations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [originalLeads, setOriginalLeads] = useState([]);
  const [originalColds, setOriginalColds] = useState([]);
  const [sailData, setSailData] = useState({
    developer: "",
    destination: "",
    project: "",
    phase: "",
    type: "",
    num_of_bed: "",
    number_of_baths: "",
    delivery_date: "",
    area: "",
    down_payment: "",
    total_price: "",
    layout: "",
    master_plan: "",
    payment_plan: "",
  });
  const [showModal, setShowModal] = useState(false);
  const agent_id = JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [clients, setclients] = useState([]);
  const [originalClients, setOriginalClients] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [loading, setloading] = useState(true);
  const [showleco, setshowleco] = useState("leads");
  const getsellsclients = () => {
    const data_send = {
      agent_id: authUser?.agent_id || agent_id,
      type: authUser?.user_type,
    };
    console.log(data_send);
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/select_client_agent.php",
        data_send
      )
      .then((res) => {
        setclients(res.message);
        if (Array.isArray(res.message)) {
          setclients(res.message);
          setOriginalColds(
            res.message.filter((it) => it.client_type == "cold")
          );
          // console.log(res.message.filter(it=>it.client_type=='cold'))
          setOriginalLeads(
            res.message.filter((it) => it.client_type == "leads")
          );

          if (res.message.length > 0) {
            setClientId(res.message[0].client_id);
          }
          setOriginalClients(res.message);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  const getcompounds = () => {
    axios
      .get(
        "https://ahmed.its.ae/new_quad_wall/admin/select_compounds.php"
      )
      .then((res) => {
        //console.log(res)
        setcompoundes(res?.message);
        if (res?.message.length > 0) {
          setcoumpound_id(res?.message[0]?.compound_id);
        }
      });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleOk = () => {
    if (answer_after == "to_resail") {
      const data_send = {
        ...sailData,
        agent_id: authUser.agent_id,
      };
      const formData1 = new FormData();
      formData1.append("image", layoutFile);
      axios
        .post(
          "https://ahmed.its.ae/new_quad_wall/admin/insert_resial_data.php",
          JSON.stringify(data_send)
        )
        .then((res) => {})
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsModalOpen(false);
      const data_send = {
        client_id: clientId,
        agent_id: authUser?.agent_id * 1,
        response: calltype,
        date_to_follow: date,
        send_whats: sendWhats ? "send" : "not Send",
        feed_back: feedBack,
        answer_details: calltype == "answer" ? answertype : not_answer_Content,
      };
      if (calltype == "answer" && answertype == "interested") {
        data_send["user_offer"] = link;
        data_send["note"] = "note";
        data_send["time"] = time;
        data_send["service_apart"] = serviceApart;
        data_send["bedrooms"] = bedroomsnumber;
        data_send["bathroom"] = bathroomnumber;
        data_send["min_price"] = money_from;
        data_send["max_price"] = money_to;
        data_send["max_square_meter"] = maxsqu;
        data_send["min_square_meter"] = minsqu;
        data_send["delivery_date"] = delivary;
        data_send["sale_type"] = saletype;
        data_send["finishing"] = finishigstatus;
        data_send["down_payment_plan"] = downpay;
        data_send["monthly_payment_plan"] = monthinstall;
        data_send["installment_year"] = install;
        data_send["location_id"] = location_id;
        data_send["sub_location_id"] = suplocation_id;
        data_send["compound_id"] = compound_id;
        data_send["property_type"] = prottype;
      }
      //console.log(JSON.stringify(data_send))
      // console.log(data_send)
      // return

      axios
        .post(
          "https://ahmed.its.ae/new_quad_wall/admin/calling_client.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          setcalltype("");
          setlink("");
          setbedroomsnumber("");
          setbathroomnumber("");
          setmoney_from("");
          setmoney_to("");
          setmaxsqu("");
          setminsqu("");
          setmaxsqu("");
          setdelivary("");
          setsaletype("");
          setfinishigstatus("");
          setdownpay("");
          setmonthinstall("");
          setinstall("");
          setprottype("");
          setanswer_after("");
          if (res.status == "success") {
            toast.success(res.message);
            // geteployees();
          } else if (res.status == "error") {
            toast.error(res.message);
          } else {
            toast.error("something is error try again");
          }
        });
    }
  };

  async function getdata() {
    let Location_data = await axios
      .get(`https://camp-coding.tech/quad_crm/user/select_locations.php`)
      .catch((err) => {
        console.log(err);
      });
    //setLocation(Location_data.message)
    let category_data = await axios
      .get(`https://camp-coding.tech/quad_crm/user/select_category.php`)
      .catch((err) => {
        console.log(err);
      });
    let companies = await axios
      .get(
        "https://ahmed.its.ae/new_quad_wall/admin/select_company.php"
      )
      .catch((err) => {
        console.log(err);
      });
    //console.log(Location_data.message,"dd")

    setlocations(Location_data.message);
    if (Location_data.message.length > 0) {
      setlocation_id(Location_data?.message[0]?.location_id);
    }
  }

  const handelDel = (client_id) => {
    // if(client_id==0){
    //   return
    // }
    const data_send = {
      client_id,
    };
    // console.log(data_send)
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/delete_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getsellsclients();
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e));
  };
  const color = useSelector((state) => state.dataReducer.data);

  useEffect(() => {
    getdata();
    getcompounds();
    getsellsclients();
  }, []);
  function leadscustomer() {
    return (
      <table
        style={{ overflow: "auto" }}
        className="table table-nowrap table-hover mb-0"
      >
        <thead>
          <tr>
            <th scope="col">number</th>
            <th scope="col">id</th>
            <th scope="col">name</th>
            <th scope="col">phone</th>
            <th scope="col">address</th>
            <th>Added Date</th>
            <th>Action Number</th>
            <th>Whatsapp</th>
            <th scope="col">Delete Client</th>
            {/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {clients
            .filter((ite) => ite.client_type == "leads")
            .map((item, index) => {
              return (
                <tr>
                  <th>{index + 1}</th>
                  <th
                    onClick={() => {
                      //navigate("/clientprofile",{state:{data:item}});
                      navigate("/clientprofile2", { state: { data: item } });
                    }}
                    style={{
                      cursor: "pointer",
                      // color:'blue',
                      textDecoration: "underline",
                    }}
                    scope="row"
                  >
                    {item.client_id}
                  </th>
                  <td>
                    <Link to="#" className="text-dark">
                      {item.client_name}
                    </Link>
                  </td>
                  <td>
                    <Link to="#" className="text-dark">
                      {item.client_phone}
                    </Link>
                  </td>
                  <td>
                    <span className="  font-size-12">
                      {item.client_address}
                    </span>
                  </td>

                  <td>
                    <span>{moment(item.added_at).format("L")}</span>
                  </td>
                  <td>
                    <span>{item.call_number}</span>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={`whatsapp://send?text=!&phone=+2${
                        item.client_phone.startsWith("0")
                          ? item.client_phone
                          : `0${item.client_phone}`
                      }`}
                    >
                      <img
                        style={{ width: "60px", cursor: "pointer" }}
                        src={require("../../assets/images/whats.png")}
                        alt=""
                      />
                    </a>
                  </td>
                  <td>
                    <AiFillDelete
                      onClick={() => {
                        handelDel(item.client_id);
                        console.log(item.client_id);
                      }}
                      style={{ fontSize: "30px", cursor: "pointer" }}
                    />
                    {/* <AiOutlineEdit onClick={()=>{
                  setShowEdit(true)
                  setRowData(item)
                }}  style={{color:'green',fontSize:'30px',cursor:'pointer'}}/> */}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }
  function coldcustomer() {
    return (
      <table className="table table-nowrap table-hover mb-0">
        <thead>
          <tr>
            <th scope="col">number</th>
            <th scope="col">id</th>
            <th scope="col">name</th>
            <th scope="col">phone</th>
            <th scope="col">address</th>
            <th scope="col">WhatsApp</th>
            <th scope="col">Delete Client</th>
            {/*                   <th scope="col" style={{ width: "120px" }}>Action</th> */}
          </tr>
        </thead>
        <tbody>
          {clients
            .filter((ite) => ite.client_type == "cold")
            .map((item, index) => {
              return (
                <tr>
                  <th>{index + 1}</th>
                  <th
                    onClick={() => {
                      //navigate("/clientprofile",{state:{data:item}});
                      navigate("/clientprofile2", { state: { data: item } });
                    }}
                    style={{
                      cursor: "pointer",
                      // color:'blue',
                      textDecoration: "underline",
                    }}
                    scope="row"
                  >
                    {item.client_id}
                  </th>
                  <td>
                    <Link to="#" className="text-dark">
                      {item.client_name}
                    </Link>
                  </td>
                  <td>
                    <Link to="#" className="text-dark">
                      {item.client_phone}
                    </Link>
                  </td>
                  <td>
                    <span className="font-size-12">{item.client_address}</span>
                  </td>
                  <td>
                    <a
                      target="_blank"
                      href={`whatsapp://send?text=!&phone=+2${
                        item.client_phone.startsWith("0")
                          ? item.client_phone
                          : `0${item.client_phone}`
                      }`}
                    >
                      <img
                        style={{ width: "60px", cursor: "pointer" }}
                        src={require("../../assets/images/whats.png")}
                        alt=""
                      />
                    </a>
                  </td>
                  <td>
                    <AiFillDelete
                      onClick={() => {
                        handelDel(item.client_id);
                      }}
                      style={{ fontSize: "30px", cursor: "pointer" }}
                    />
                    {/* <AiOutlineEdit onClick={()=>{
                  setShowEdit(true)
                  setRowData(item)
                }} style={{color:'green',fontSize:'30px',cursor:'pointer'}}/> */}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
  }
  function renderfuncs() {
    if (showleco == "leads") {
      return leadscustomer();
    } else {
      return coldcustomer();
    }
  }

  const handleOk6 = () => {
    const data_send = {
      client_id: clientId,
      feed_back: feedBack,
      send_whats: sendWhats ? "send" : "not Send",
      response: calltype,
      follow_up_after_meeting: followAffterMeeting,
      answer_details: calltype == "answer" ? answertype : not_answer_Content,
    };
    if (calltype == "answer" && answertype == "intrested") {
      data_send["user_offer"] = link;
      data_send["note"] = "note";
      data_send["time"] = time;
      data_send["bedrooms"] = bedroomsnumber;
      data_send["bathroom"] = bathroomnumber;
      data_send["min_price"] = money_from;
      data_send["max_price"] = money_to;
      data_send["max_square_meter"] = maxsqu;
      data_send["min_square_meter"] = minsqu;
      data_send["delivery_date"] = delivary;
      data_send["sale_type"] = saletype;
      data_send["finishing"] = finishigstatus;
      data_send["down_payment_plan"] = downpay;
      data_send["monthly_payment_plan"] = monthinstall;
      data_send["installment_year"] = install;
      data_send["location_id"] = location_id;
      data_send["sub_location_id"] = suplocation_id;
      data_send["compound_id"] = compound_id;
      data_send["property_type"] = prottype;
    }
    data_send["agent_id"] = agent_id;
    // return
    // console.log(JSON.stringify(data_send))
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/calling_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        setcalltype("");
        setlink("");
        setbedroomsnumber("");
        setbathroomnumber("");
        setmoney_from("");
        setmoney_to("");
        setmaxsqu("");
        setminsqu("");
        setmaxsqu("");
        setdelivary("");
        setsaletype("");
        setfinishigstatus("");
        setdownpay("");
        setmonthinstall("");
        setinstall("");
        setprottype("");
        setanswer_after("");
        if (res.status == "success") {
          toast.success(res.message);
          setShowAddModal(false);
          // getRequests();
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("something is error try again");
        }
      });
  };

  const handleSearch = (txt) => {
    if (txt == "") {
      setclients(originalClients);
    } else {
      if (showleco == "leads") {
        // console.log(originalClients)
        // console.log('rere')
        // console.log(originalLeads)
        let pushed = originalLeads.filter((it) => {
          // console.log(it.client_name,"client_name")
          if (
            it.client_name.toLowerCase().includes(txt.toLowerCase()) ||
            it.client_phone.includes(txt)
          ) {
            return { ...it };
          }
          return null;
        });
        // console.log(pushed)

        setclients(
          originalLeads.filter(
            (it) =>
              it.client_name.toLowerCase().includes(txt.toLowerCase()) ||
              it.client_phone.includes(txt)
          )
        );
      } else {
        setclients(
          originalColds.filter(
            (it) =>
              it.client_name.toLowerCase().includes(txt.toLowerCase()) ||
              it.client_phone.includes(txt)
          )
        );
      }
    }
    // let pushedData=[];
    // if(txt==''){
    //   setclients(originalClients)
    // }
    // else {
    //   for(let i=0;i<originalClients.length;i++){
    //     // console.log(originalClients[i]?.client_phone)
    //     if(originalClients[i]?.client_phone!=null){
    //       if((originalClients[i]?.client_phone.includes(txt)&&originalClients[i]?.client_phone!=null)||originalClients[i]?.client_name.includes(txt)){
    //         pushedData.push(originalClients[i]);
    //       }
    //     }
    //     else {
    //       if(originalClients[i]?.client_name!=null){
    //         if(originalClients[i]?.client_name.includes(txt)){
    //           pushedData.push(originalClients[i]);
    //         }
    //       }
    //     }
    //   }
    //   setclients(pushedData)
    // }
  };
  const getsublocationsfunc = async () => {
    let Location_data = await axios
      .get(`https://camp-coding.tech/quad_crm/user/select_locations.php`)
      .catch((err) => {});
    const filtereddata = Location_data.message.filter(
      (item) => item.location_id == location_id
    );
    setsublocations(filtereddata[0]?.sub_location);
    //console.log(filtereddata[0]?.sub_location)
    setsuplocation_id(filtereddata[0]?.sub_location[0].sub_id);
    //console.log(filtereddata[0]?.sub_location[0].sub_id)
  };
  const handleAddClient = () => {
    const data_send = {
      ...newClient,
      added_by_agent_id: authUser.agent_id * 1,
    };
    // console.log(data_send)
    // return
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/insert_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getsellsclients();
          setShowModal();
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("حدث خطأ ما");
        }
      })
      .catch((e) => console.log(e));
  };

  const handleEdit = () => {
    toast.warn("تحت التطوير الأن");
    return;
    setAddLoading(true);
    const data_send = {
      ...rowData,
    };
    console.log(data_send);
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/edit_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          getsellsclients();
          setShowEdit(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  useEffect(() => {
    getsublocationsfunc();
  }, [location_id]);
  return (
    <>
      {loading ? (
        [1, 2, 3].map((x) => (
          <Col xs={24} md={12} lg={8} key={x}>
            <Card loading minHeight={200} />
          </Col>
        ))
      ) : (
        <div
          className="container py-6"
          style={{ marginTop: "78px", overflow: "auto" }}
        >
          <div>
            <input
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
              className="form-control"
              type="text"
              placeholder="Search..."
            />
          </div>
          <div className="methods_btns py-6" style={{ marginTop: "30px" }}>
            <button
              onClick={() => {
                setshowleco("leads");
              }}
              className={`${showleco == "leads" ? "active" : ""}`}
            >
              leads
            </button>
            <button
              onClick={() => {
                setshowleco("cold");
              }}
              className={`${showleco == "cold" ? "active" : ""}`}
            >
              cold
            </button>
          </div>

          <div>
            <button
              onClick={() => {
                setShowModal(true);
              }}
              className="btn btn-primary"
            >
              Add Client
            </button>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                margin: "20px 0px",
              }}
            >
              <h4>add new call</h4>
              <AiOutlinePlus
                onClick={() => {
                  setShowAddModal(true);
                }}
                style={{
                  color: "green",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              />
            </div>
            <Modal
              title="add new call"
              open={isModalOpen}
              onOk={handleOk6}
              onCancel={handleCancel}
            >
              {/* <div onClick={()=>{
              setSendWhats(!sendWhats)
            }} style={{margin:'20px 0px',width:'fit-content',display:'flex',alignItems:'center',gap:'3px',cursor:'pointer'}}>
              <input style={{width:'20px',height:'20px',display:'block'}} type="checkbox" checked={sendWhats} onChange={()=>{
              }} />
              <label style={{margin:'0px'}} htmlFor="">Send WhatsApp</label>
            </div> */}
              <div className="my-3" style={{ margin: "10px 0px" }}>
                <label className="d-block">Clients</label>
                <Select
                  style={{ width: "100%" }}
                  value={clientId}
                  onChange={(e) => {
                    setClientId(e);
                  }}
                  options={clients.map((item, index) => {
                    return { label: item.name, value: item.client_id };
                  })}
                  classNamePrefix="select2-selection"
                />
              </div>
              <div
                onClick={() => {
                  setSendWhats(!sendWhats);
                }}
                style={{
                  margin: "20px 0px",
                  width: "fit-content",
                  display: "flex",
                  alignItems: "center",
                  gap: "3px",
                  cursor: "pointer",
                }}
              >
                <input
                  style={{ width: "20px", height: "20px", display: "block" }}
                  type="checkbox"
                  checked={sendWhats}
                  onChange={() => {}}
                />
                <label style={{ margin: "0px" }} htmlFor="">
                  Send WhatsApp
                </label>
              </div>
              <div style={{ width: "100%", margin: "10px 0px" }}>
                <label htmlFor="">FeedBack</label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => {
                    setFeedBack(e.target.value);
                  }}
                  placeholder="FeedBack"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    checked={calltype == "answer"}
                    onClick={() => {
                      setcalltype("answer");
                    }}
                    style={{ display: "block" }}
                    type="radio"
                    id="answer"
                    name="fav_language"
                    value="answer"
                  />
                  <label
                    onClick={() => {
                      setcalltype("answer");
                    }}
                    htmlFor="answer"
                    style={{}}
                  >
                    answer
                  </label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    checked={calltype == "not_answer"}
                    onClick={(e) => {
                      setcalltype("not_answer");
                    }}
                    style={{ display: "block" }}
                    type="radio"
                    id="not_answer"
                    name="fav_language"
                    value="company"
                  />
                  <label
                    onClick={() => {
                      setcalltype("not_answer");
                    }}
                    style={{}}
                    htmlFor="not_answer"
                  >
                    not answer
                  </label>
                </div>
              </div>
              {calltype == "answer" ? (
                <>
                  <Select
                    value={answertype}
                    style={{ width: "100%" }}
                    placeholder="enter answer"
                    //onChange={handleChange}
                    onChange={(e) => {
                      //console.log(e)
                      setanswertype(e);
                    }}
                    options={[
                      { value: "interested", label: "interested" },
                      { value: "not interested", label: "not interested" },
                      {
                        value: "interested but not_now",
                        label: "interested but not now",
                      },
                      { value: "low_budjet", label: "low budjet" },
                      { value: "pending", label: "pending" },
                    ]}
                  />
                  {answertype == "interested_but_not_now" ? (
                    <>
                      <input
                        style={{
                          width: "100%",
                          marginTop: "20px",
                        }}
                        type="date"
                        onChange={(e) => {
                          setdate(e.target.value);
                        }}
                      />
                    </>
                  ) : answertype == "not_interested" ? null : answertype ==
                    "interested" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          checked={answer_after == "continue"}
                          onClick={() => {
                            setanswer_after("continue");
                          }}
                          style={{ display: "block" }}
                          type="radio"
                          id="continue"
                          name="fav_language2"
                          value="continue"
                        />
                        <label
                          htmlFor="continue"
                          onClick={() => {
                            setanswer_after("continue");
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          continue
                        </label>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <input
                          checked={answer_after == "to_resail"}
                          onClick={() => {
                            setanswer_after("to_resail");
                          }}
                          style={{ display: "block" }}
                          type="radio"
                          id="to_resail"
                          name="fav_language2"
                          value="to_resail"
                        />
                        <label
                          htmlFor="to_resail"
                          onClick={() => {
                            setanswer_after("to_resail");
                          }}
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Client Need To Resale
                        </label>
                      </div>

                      <div style={{ display: "flex", alignItems: "center" }}>
                        <input
                          checked={answer_after == "reschadural"}
                          onClick={(e) => {
                            setlink("");
                            setnote("");
                            setanswer_after("reschadural");
                          }}
                          style={{ display: "block" }}
                          type="radio"
                          id="reschadural"
                          name="fav_language2"
                          value="reschadural"
                        />
                        <label
                          onClick={() => {
                            setanswer_after("reschadural");
                          }}
                          style={{}}
                          htmlFor="reschadural"
                        >
                          reschadural
                        </label>
                      </div>
                    </div>
                  ) : null}
                  {answer_after == "continue" ? (
                    <div>
                      <div className="time_date">
                        <div>follow up call</div>
                        <div>
                          <input
                            style={{
                              width: "100%",
                              height: "30px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                            }}
                            onChange={(e) => {
                              settime(e.target.value);
                            }}
                            type="time"
                          />
                          <input
                            style={{
                              width: "100%",
                              height: "30px",
                              borderRadius: "10px",
                              padding: "10px",
                              marginBottom: "10px",
                            }}
                            onChange={(e) => {
                              setdate(e.target.value);
                            }}
                            type="date"
                          />
                        </div>
                      </div>
                      <input
                        value={link}
                        style={{
                          width: "100%",
                          height: "30px",
                          borderRadius: "10px",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                        type="text"
                        onChange={(e) => {
                          setlink(e.target.value);
                        }}
                        placeholder="enter link of unit"
                      />
                      {/* <textarea
                                  onChange={(e)=>{
                                    setnote(e.target.value)
                                  }}
                                  placeholder='enter details'
                                  style={{
                                  resize:'none',
                                  width:'100%',
                                  height:'50px',
                                  border:'1px solid #ccc',
                                  padding:'10px',
                                  borderRadius:'10px'
                                }}></textarea> */}
                      <div className="" style={{ margin: "30px 0px" }}>
                        <label htmlFor="">serviced apartments</label>
                        <Select
                          style={{ width: "100%" }}
                          placeholder="enter answer"
                          //onChange={handleChange}
                          value={serviceApart}
                          onChange={(e) => {
                            setServiceApart(e);
                          }}
                          options={[
                            { value: "residential", label: "Residential" },
                            { value: "costal", label: "Costal" },
                            { value: "commercial", label: "Commercial" },
                            { value: "admin", label: "Admin" },
                            { value: "medical", label: "Medical" },
                          ]}
                        />
                      </div>
                      <div className="property_type_div">
                        <h5>property type</h5>
                        <div className="property_types">
                          <div
                            className="property_type"
                            onClick={() => {
                              setprottype("apartment");
                            }}
                          >
                            <div
                              className={`${
                                prottype == "apartment" ? "active" : ""
                              }`}
                              style={{
                                border: "1px solid #ccc",
                              }}
                            >
                              <MdOutlineApartment />
                            </div>
                            <h6>apartment</h6>
                          </div>
                          <div
                            className="property_type"
                            onClick={() => {
                              setprottype("vila");
                            }}
                          >
                            <div
                              className={`${
                                prottype == "vila" ? "active" : ""
                              }`}
                              style={{
                                border: "1px solid #ccc",
                              }}
                            >
                              <img
                                style={{ width: "30px" }}
                                src={require("../../assets/images/vila.png")}
                                alt=""
                              />
                            </div>
                            <h6>vila</h6>
                          </div>
                          <div
                            className="property_type"
                            onClick={() => {
                              setprottype("twinhouse");
                            }}
                          >
                            <div
                              className={`${
                                prottype == "twinhouse" ? "active" : ""
                              }`}
                              style={{
                                border: "1px solid #ccc",
                              }}
                            >
                              <BiBuildingHouse />
                            </div>
                            <h6>twinhouse</h6>
                          </div>
                          <div
                            className="property_type"
                            onClick={() => {
                              setprottype("duplex");
                            }}
                          >
                            <div
                              className={`${
                                prottype == "duplex" ? "active" : ""
                              }`}
                              style={{
                                border: "1px solid #ccc",
                              }}
                            >
                              <BsHouseSlashFill />
                            </div>
                            <h6>duplex</h6>
                          </div>
                          <div
                            className="property_type"
                            onClick={() => {
                              setprottype("townhouse");
                            }}
                          >
                            <div
                              className={`${
                                prottype == "townhouse" ? "active" : ""
                              }`}
                              style={{
                                border: "1px solid #ccc",
                              }}
                            >
                              <BsHouses />
                            </div>
                            <h6>townhouse</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          margin: "20px 0px",
                        }}
                      >
                        <h6>bedrooms number</h6>
                        <div className="bedrooms_numbers">
                          <div
                            onClick={() => {
                              setbedroomsnumber("all");
                            }}
                            className={
                              bedroomsnumber == "all"
                                ? "bedrooms_number active"
                                : "bedrooms_number"
                            }
                          >
                            all
                          </div>
                          <div
                            className={`${
                              bedroomsnumber * 1 >= 1
                                ? "bedrooms_number active"
                                : "bedrooms_number"
                            }`}
                            onClick={() => {
                              setbedroomsnumber("1");
                            }}
                          >
                            1
                          </div>
                          <div
                            className={`${
                              bedroomsnumber * 1 >= 2
                                ? "bedrooms_number active"
                                : "bedrooms_number"
                            }`}
                            onClick={() => {
                              setbedroomsnumber("2");
                            }}
                          >
                            2
                          </div>
                          <div
                            className={`${
                              bedroomsnumber * 1 >= 3
                                ? "bedrooms_number active"
                                : "bedrooms_number"
                            }`}
                            onClick={() => {
                              setbedroomsnumber("3");
                            }}
                          >
                            3
                          </div>
                          <div
                            className={`${
                              bedroomsnumber * 1 >= 4
                                ? "bedrooms_number active"
                                : "bedrooms_number"
                            }`}
                            onClick={() => {
                              setbedroomsnumber("4");
                            }}
                          >
                            4
                          </div>
                          <div
                            className={`${
                              bedroomsnumber * 1 >= 5
                                ? "bedrooms_number active"
                                : "bedrooms_number"
                            }`}
                            onClick={() => {
                              setbedroomsnumber("5");
                            }}
                          >
                            5
                          </div>
                          <div
                            className={`${
                              bedroomsnumber * 1 >= 6
                                ? "bedrooms_number active"
                                : "bedrooms_number"
                            }`}
                            onClick={() => {
                              setbedroomsnumber("6");
                            }}
                          >
                            6+
                          </div>
                        </div>
                      </div>
                      <div>
                        <h6 style={{ textTransform: "capitalize" }}>
                          bathrooms number
                        </h6>
                        <div className="bathrooms_numbers">
                          <div
                            onClick={() => {
                              setbathroomnumber("all");
                            }}
                            className={
                              bathroomnumber == "all"
                                ? "bathroom_number active"
                                : "bathroom_number"
                            }
                          >
                            all
                          </div>
                          <div
                            onClick={() => {
                              setbathroomnumber("1");
                            }}
                            className={`${
                              bathroomnumber * 1 >= 1
                                ? "bathroom_number active"
                                : "bathroom_number"
                            }`}
                          >
                            1
                          </div>
                          <div
                            onClick={() => {
                              setbathroomnumber("2");
                            }}
                            className={`${
                              bathroomnumber * 1 >= 2
                                ? "bathroom_number active"
                                : "bathroom_number"
                            }`}
                          >
                            2
                          </div>
                          <div
                            onClick={() => {
                              setbathroomnumber("3");
                            }}
                            className={`${
                              bathroomnumber * 1 >= 3
                                ? "bathroom_number active"
                                : "bathroom_number"
                            }`}
                          >
                            3
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              textAlign: "center",
                              width: "50%",
                              margin: "0px",
                              fontSize: "19px",
                            }}
                          >
                            from
                          </p>
                          <p
                            style={{
                              textAlign: "center",
                              width: "50%",
                              margin: "0px",
                              fontSize: "19px",
                            }}
                          >
                            to
                          </p>
                        </div>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Select
                            showSearch
                            onChange={(e) => {
                              setmoney_from(e);
                            }}
                            value={money_from}
                            style={{
                              width: "50%",
                            }}
                            options={marks.map((item, index) => {
                              return {
                                label: `${item.label}`,
                                value: `${item.value}`,
                              };
                            })}
                          />
                          <Select
                            showSearch
                            onChange={(e) => {
                              setmoney_to(e);
                            }}
                            value={money_to}
                            style={{
                              width: "50%",
                            }}
                            options={marks.map((item, index) => {
                              return {
                                label: `${item.label}`,
                                value: `${item.value}`,
                              };
                            })}
                          />
                        </div>
                      </div>
                      <div className="square_metter">
                        <h5>
                          square meter <span>(m2)</span>
                        </h5>
                        <div className="min_max_square">
                          <div className="min_square">
                            <input
                              value={minsqu}
                              onChange={(e) => {
                                setminsqu(e.target.value);
                              }}
                              type="text"
                              placeholder="min 42"
                            />
                            <span>(m2)</span>
                          </div>
                          <div className="max_square">
                            <input
                              value={maxsqu}
                              onChange={(e) => {
                                setmaxsqu(e.target.value);
                              }}
                              type="text"
                              placeholder="max 1287"
                            />
                            <span>(m2)</span>
                          </div>
                        </div>
                      </div>
                      <div className="delivary_date">
                        <h6 style={{ textTransform: "capitalize" }}>
                          Delivary Date
                        </h6>
                        <div className="delivaries">
                          <div
                            onClick={() => {
                              setdelivary("move_now");
                            }}
                            className={
                              delivary == "move_now"
                                ? "delivary active"
                                : "delivary"
                            }
                          >
                            Move Now
                          </div>
                          <div
                            onClick={() => {
                              setdelivary("2024");
                            }}
                            className={
                              delivary == "2024"
                                ? "delivary active"
                                : "delivary"
                            }
                          >
                            2024
                          </div>
                          <div
                            onClick={() => {
                              setdelivary("2025");
                            }}
                            className={
                              delivary == "2025"
                                ? "delivary active"
                                : "delivary"
                            }
                          >
                            2025
                          </div>
                          <div
                            onClick={() => {
                              setdelivary("2026");
                            }}
                            className={
                              delivary == "2026"
                                ? "delivary active"
                                : "delivary"
                            }
                          >
                            2026
                          </div>
                          <div
                            onClick={() => {
                              setdelivary("2027");
                            }}
                            className={
                              delivary == "2027"
                                ? "delivary active"
                                : "delivary"
                            }
                          >
                            2027
                          </div>
                        </div>
                      </div>
                      <div className="sale_type_div">
                        <h6>sale type</h6>
                        <div className="sales_type">
                          <div
                            onClick={() => {
                              setsaletype("developer_sale");
                            }}
                            className={`${
                              saletype == "developer_sale"
                                ? "sale_type active"
                                : "sale_type"
                            }`}
                          >
                            Primary
                          </div>
                          <div
                            onClick={() => {
                              setsaletype("resale");
                            }}
                            className={`${
                              saletype == "resale"
                                ? "sale_type active"
                                : "sale_type"
                            }`}
                          >
                            resale
                          </div>
                          {/* <div className={`${saletype=="nawy_now"?"sale_type active":"sale_type"}`}
                                    onClick={()=>{
                                      setsaletype("nawy_now")
                                    }}
                                    >nawy now</div> */}
                        </div>
                      </div>
                      <div className="finishing_div">
                        <h6>finishing</h6>
                        <div className="finishings">
                          <div
                            onClick={() => {
                              setfinishigstatus("all");
                            }}
                            className={
                              finishigstatus == "all"
                                ? "finishing active"
                                : "finishing"
                            }
                          >
                            all
                          </div>
                          <div
                            onClick={() => {
                              setfinishigstatus("unfinished");
                            }}
                            className={
                              finishigstatus == "unfinished"
                                ? "finishing active"
                                : "finishing"
                            }
                          >
                            unfinished
                          </div>
                          <div
                            onClick={() => {
                              setfinishigstatus("semi_finished");
                            }}
                            className={
                              finishigstatus == "semi_finished"
                                ? "finishing active"
                                : "finishing"
                            }
                          >
                            semi-finished
                          </div>
                          <div
                            onClick={() => {
                              setfinishigstatus("finished");
                            }}
                            className={`${
                              finishigstatus == "finished"
                                ? "finishing active"
                                : "finishing"
                            }`}
                          >
                            finished
                          </div>
                        </div>
                      </div>
                      <div className="payment_paln_div">
                        <h6 style={{ textTransform: "capitalize" }}>
                          payment plan
                        </h6>
                        <div className="pay_methods">
                          <div className="down_payment">
                            <h5 style={{ textAlign: "center" }}>
                              Down Payment
                            </h5>
                            <input
                              value={downpay}
                              onChange={(e) => {
                                setdownpay(e.target.value);
                              }}
                              type="text"
                              placeholder="Up to"
                            />
                          </div>
                          <div className="monthly_installment">
                            <h5 style={{ textAlign: "center", width: "100%" }}>
                              monthly installment
                            </h5>
                            <input
                              value={monthinstall}
                              onChange={(e) => {
                                setmonthinstall(e.target.value);
                              }}
                              type="text"
                              placeholder="Up to"
                            />
                            <span>EGP</span>
                          </div>
                        </div>
                      </div>
                      <div className="installment_years">
                        <h5>installment years</h5>
                        <div className="installments">
                          <div
                            onClick={() => {
                              setinstall("all");
                            }}
                            className={
                              install == "all" ? "install active" : "install"
                            }
                          >
                            all
                          </div>
                          <div
                            onClick={() => {
                              setinstall("1");
                            }}
                            className={
                              install.replace("+", "") * 1 >= 1
                                ? "install active"
                                : "install"
                            }
                          >
                            1
                          </div>
                          <div
                            onClick={() => {
                              setinstall("2");
                            }}
                            className={
                              install.replace("+", "") * 1 >= 2
                                ? "install active"
                                : "install"
                            }
                          >
                            2
                          </div>
                          <div
                            onClick={() => {
                              setinstall("3");
                            }}
                            className={
                              install.replace("+", "") * 1 >= 3
                                ? "install active"
                                : "install"
                            }
                          >
                            3
                          </div>
                          <div
                            onClick={() => {
                              setinstall("4");
                            }}
                            className={
                              install.replace("+", "") * 1 >= 4
                                ? "install active"
                                : "install"
                            }
                          >
                            4
                          </div>
                          <div
                            onClick={() => {
                              setinstall("5");
                            }}
                            className={
                              install.replace("+", "") * 1 >= 5
                                ? "install active"
                                : "install"
                            }
                          >
                            5
                          </div>
                          <div
                            onClick={() => {
                              setinstall("6+");
                            }}
                            className={
                              install.replace("+", "") * 1 >= 6
                                ? "install active"
                                : "install"
                            }
                          >
                            6+
                          </div>
                        </div>
                      </div>
                      <div className="select_area">
                        <h5 style={{ textTransform: "capitalize" }}>area</h5>
                        <div className="sullocation">
                          <Select
                            style={{ width: "48%" }}
                            value={location_id}
                            className=" nice-select"
                            placeholder="select location"
                            onChange={(e) => {
                              let data = locations.filter(
                                (item) => item.location_id == e
                              );

                              setlocation_id(e);
                              // setbuildingdata({...buildingdata,sub_location_id:data[0]?.sub_location[0]?.sub_id});
                              setlocation_id(e);
                              //setbuildingdata({...buildingdata,location_id:e});
                            }}
                            id=""
                            options={locations.map((item, index) => {
                              return {
                                label: `${item.location_name}`,
                                value: `${item.location_id}`,
                              };
                            })}
                          />
                          <Select
                            style={{ width: "48%" }}
                            value={suplocation_id}
                            className=" nice-select"
                            placeholder="select location"
                            onChange={(e) => {
                              //console.log(e)
                              setsuplocation_id(e);
                            }}
                            id=""
                            // options={Location?.find(item=>item.location_id==buildingdata.location_id)?.sub_location?.map(item=>{
                            //   return {label:`${item.name}`, value:`${item.sub_id}`};
                            // })}
                            options={sublocations?.map((item, index) => {
                              return {
                                label: `${item.name}`,
                                value: `${item.sub_id}`,
                              };
                            })}
                          />
                        </div>
                      </div>
                      <div className="select_compund">
                        <h6 style={{ textTransform: "capitalize" }}>
                          compound
                        </h6>

                        <Select
                          showSearch
                          onChange={(e) => {
                            setcompound_id(e);
                          }}
                          value={compound_id}
                          style={{
                            width: "100%",
                          }}
                          options={compoundes.map((item, index) => {
                            return {
                              label: `${item.compound_name}`,
                              value: `${item.compound_id}`,
                            };
                          })}
                        />

                        {/* <div className="add_compound">
                                    add compound <span>+</span>
                                  </div> */}
                      </div>
                    </div>
                  ) : answer_after == "reschadural" ? (
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <input
                        style={{
                          width: "100%",
                          height: "30px",
                          borderRadius: "10px",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                        type="time"
                        onChange={(e) => {
                          settime(e.target.value);
                        }}
                      />
                      <input
                        style={{
                          width: "100%",
                          height: "30px",
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                        type="date"
                        onChange={(e) => {
                          setdate(e.target.value);
                        }}
                      />
                    </div>
                  ) : answer_after == "to_resail" ? (
                    <div className="resile_div">
                      <div className="row">
                        <div className="col-lg-6">
                          <label htmlFor="">Developer:</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                developer: e.target.value,
                              });
                            }}
                            value={sailData?.developer}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Destination :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                destination: e.target.value,
                              });
                            }}
                            value={sailData?.destination}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Project :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                project: e.target.value,
                              });
                            }}
                            value={sailData?.project}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Type :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                type: e.target.value,
                              });
                            }}
                            value={sailData?.type}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Number Of Beds :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                num_of_bed: e.target.value,
                              });
                            }}
                            value={sailData?.num_of_bed}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Number Of Baths :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                number_of_baths: e.target.value,
                              });
                            }}
                            value={sailData?.number_of_baths}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Delivery date :</label>
                          <input
                            type="date"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                delivery_date: e.target.value,
                              });
                            }}
                            value={sailData?.delivery_date}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Area :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                area: e.target.value,
                              });
                            }}
                            value={sailData?.area}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Down Payment :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                down_payment: e.target.value,
                              });
                            }}
                            value={sailData?.down_payment}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Total Price :</label>
                          <input
                            type="text"
                            onChange={(e) => {
                              setSailData({
                                ...sailData,
                                total_price: e.target.value,
                              });
                            }}
                            value={sailData?.total_price}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Layout :</label>
                          <input
                            type="file"
                            onChange={(e) => {
                              setLayOutFile(e.target.files[0]);
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Master Plan :</label>
                          <input
                            type="file"
                            onChange={(e) => {
                              setMasterPlanFile(e.target.files[0]);
                            }}
                          />
                        </div>
                        <div className="col-lg-6">
                          <label htmlFor="">Payment Plan :</label>
                          <input
                            type="file"
                            onChange={(e) => {
                              setPaymentPlanFile(e.target.files[0]);
                            }}
                          />
                        </div>
                      </div>
                      {/* here */}
                    </div>
                  ) : null}
                </>
              ) : calltype == "not_answer" ? (
                <>
                  <Select
                    placeholder="enter resone of not answer"
                    //defaultValue="lucy"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setnot_answer_Content(e);
                    }}
                    options={[
                      { value: "phone_closed", label: "phone closed" },
                      { value: "out_of_service", label: "out of service" },
                      { value: "wrong_number", label: "wrong number" },
                      {
                        value: "ring_without_response",
                        label: "ring without response",
                      },
                    ]}
                  />
                </>
              ) : null}
            </Modal>
          </div>
          {renderfuncs()}
        </div>
      )}
      <Modal
        title="Add Customer"
        open={showModal}
        onOk={() => {
          if (addLoading) {
            toast.warn("request is running now Please Wait");
            return;
          }
          handleAddClient();
          // handleOk()
        }}
        onCancel={() => {
          setShowModal(false);
        }}
        centered
      >
        <form>
          <input
            onChange={(e) => {
              setNewClient({ ...newClient, name: e.target.value });
            }}
            type="text"
            placeholder="enter client name"
          />
          <input
            onChange={(e) => {
              setNewClient({ ...newClient, phone: e.target.value });
            }}
            type="text"
            placeholder="enter client phone"
          />
          <input
            onChange={(e) => {
              setNewClient({ ...newClient, job: e.target.value });
            }}
            type="text"
            placeholder="enter client job"
          />
          <div style={{ width: "100%" }}>
            <label htmlFor="">Type</label>
            <Select
              style={{ width: "100%" }}
              value={newClient.type}
              onChange={(e) => {
                setNewClient({ ...newClient, type: e });
              }}
              options={[
                { label: "Cold", value: "cold" },
                { label: "Leads", value: "leads" },
              ]}
              classNamePrefix="select2-selection"
            />
          </div>
          <input
            onChange={(e) => {
              setNewClient({ ...newClient, address: e.target.value });
            }}
            type="text"
            placeholder="enter client address"
          />
          {addLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40px",
                width: "100%",
              }}
            >
              <ThreeDots />
            </div>
          )}
          {/* <Select
            defaultValue="lucy"
            style={{
              width: "100%",
            }}
            onChange={(e)=>{
              setNewClient({...newClient,type:e});
            }}
            options={[
              {
                value: 'cold',
                label: 'cold',
              },
              {
                value: 'leads',
                label: 'leads',
              },
            ]}
          /> */}
        </form>
      </Modal>
      <Modal
        title="add new call"
        open={showAddModal}
        onOk={handleOk}
        onCancel={() => {
          setShowAddModal(false);
        }}
      >
        <div
          onClick={() => {
            setSendWhats(!sendWhats);
          }}
          style={{
            margin: "20px 0px",
            width: "fit-content",
            display: "flex",
            alignItems: "center",
            gap: "3px",
            cursor: "pointer",
          }}
        >
          <input
            style={{ width: "20px", height: "20px", display: "block" }}
            type="checkbox"
            checked={sendWhats}
            onChange={() => {}}
          />
          <label style={{ margin: "0px" }} htmlFor="">
            Send WhatsApp
          </label>
        </div>
        <div style={{ width: "100%", margin: "10px 0px" }}>
          <label htmlFor="">FeedBack</label>
          <input
            className="form-control"
            type="text"
            onChange={(e) => {
              setFeedBack(e.target.value);
            }}
            placeholder="FeedBack"
          />
        </div>
        <div className="my-3" style={{ margin: "10px 0px" }}>
          <label className="d-block">Clients</label>
          <Select
            style={{ width: "100%" }}
            value={clientId}
            onChange={(e) => {
              setClientId(e);
            }}
            options={clients.map((item, index) => {
              return { label: item.client_name, value: item.client_id };
            })}
            classNamePrefix="select2-selection"
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              checked={calltype == "answer"}
              onClick={() => {
                setcalltype("answer");
              }}
              style={{ display: "block" }}
              type="radio"
              id="answer"
              name="fav_language"
              value="answer"
            />
            <label
              onClick={() => {
                setcalltype("answer");
              }}
              htmlFor="answer"
              style={{}}
            >
              answer
            </label>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              checked={calltype == "not_answer"}
              onClick={(e) => {
                setcalltype("not_answer");
              }}
              style={{ display: "block" }}
              type="radio"
              id="not_answer"
              name="fav_language"
              value="company"
            />
            <label
              onClick={() => {
                setcalltype("not_answer");
              }}
              style={{}}
              htmlFor="not_answer"
            >
              not answer
            </label>
          </div>
        </div>
        {calltype == "answer" ? (
          <>
            <Select
              value={answertype}
              style={{ width: "100%" }}
              placeholder="enter answer"
              //onChange={handleChange}
              onChange={(e) => {
                //console.log(e)
                setanswertype(e);
              }}
              options={[
                { value: "interested", label: "interested" },
                { value: "not interested", label: "not interested" },
                {
                  value: "interested but not_now",
                  label: "interested but not_now",
                },
                { value: "low_budjet", label: "low budjet" },
                { value: "pending", label: "pending" },
              ]}
            />
            {answertype == "interested_but_not_now" ? (
              <>
                <input
                  style={{
                    width: "100%",
                    marginTop: "20px",
                    padding: "10px",
                    borderRadius: "4px",
                    outline: "none",
                  }}
                  type="date"
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                />
              </>
            ) : answertype == "not_interested" ? null : answertype ==
              "interested" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                  marginTop: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <input
                    checked={answer_after == "continue"}
                    onClick={() => {
                      setanswer_after("continue");
                    }}
                    style={{ display: "block" }}
                    type="radio"
                    id="continue"
                    name="fav_language2"
                    value="continue"
                  />
                  <label
                    htmlFor="continue"
                    onClick={() => {
                      setanswer_after("continue");
                    }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    continue
                  </label>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input
                    checked={answer_after == "reschadural"}
                    onClick={(e) => {
                      setlink("");
                      setnote("");
                      setanswer_after("reschadural");
                    }}
                    style={{ display: "block" }}
                    type="radio"
                    id="reschadural"
                    name="fav_language2"
                    value="reschadural"
                  />
                  <label
                    onClick={() => {
                      setanswer_after("reschadural");
                    }}
                    style={{}}
                    htmlFor="reschadural"
                  >
                    reschadural
                  </label>
                </div>
              </div>
            ) : null}
            {answer_after == "continue" ? (
              <div>
                <div className="time_date">
                  <div>follow up call</div>
                  <div>
                    <input
                      className="form-control mb-2"
                      onChange={(e) => {
                        settime(e.target.value);
                      }}
                      type="time"
                    />
                    <input
                      className="form-control"
                      onChange={(e) => {
                        setdate(e.target.value);
                      }}
                      type="date"
                    />
                  </div>
                </div>
                <div style={{ margin: "10px 0px" }}>
                  <label htmlFor="">Follow Up After Meeting</label>
                  <input
                    className="form-control"
                    type="time"
                    name=""
                    onChange={(e) => {
                      setFollowAffterMeeting(e.target.value);
                    }}
                    id=""
                  />
                </div>

                <input
                  value={link}
                  style={{
                    width: "100%",
                    height: "30px",
                    borderRadius: "10px",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                  type="text"
                  onChange={(e) => {
                    setlink(e.target.value);
                  }}
                  placeholder="enter link of unit"
                />
                {/* <textarea
                                  onChange={(e)=>{
                                    setnote(e.target.value)
                                  }}
                                  placeholder='enter details'
                                  style={{
                                  resize:'none',
                                  width:'100%',
                                  height:'50px',
                                  border:'1px solid #ccc',
                                  padding:'10px',
                                  borderRadius:'10px'
                                }}></textarea> */}
                <div className="property_type_div">
                  <h5>property type</h5>
                  <div className="property_types">
                    <div
                      className="property_type"
                      onClick={() => {
                        setprottype("apartment");
                      }}
                    >
                      <div
                        className={`${prottype == "apartment" ? "active" : ""}`}
                        style={{
                          border: "1px solid #ccc",
                        }}
                      >
                        <MdOutlineApartment />
                      </div>
                      <h6>apartment</h6>
                    </div>
                    <div
                      className="property_type"
                      onClick={() => {
                        setprottype("vila");
                      }}
                    >
                      <div
                        className={`${prottype == "vila" ? "active" : ""}`}
                        style={{
                          border: "1px solid #ccc",
                        }}
                      >
                        <img
                          style={{ width: "30px" }}
                          src={require("../../assets/images/vila.png")}
                          alt=""
                        />
                      </div>
                      <h6>vila</h6>
                    </div>
                    <div
                      className="property_type"
                      onClick={() => {
                        setprottype("twinhouse");
                      }}
                    >
                      <div
                        className={`${prottype == "twinhouse" ? "active" : ""}`}
                        style={{
                          border: "1px solid #ccc",
                        }}
                      >
                        <BiBuildingHouse />
                      </div>
                      <h6>twinhouse</h6>
                    </div>
                    <div
                      className="property_type"
                      onClick={() => {
                        setprottype("duplex");
                      }}
                    >
                      <div
                        className={`${prottype == "duplex" ? "active" : ""}`}
                        style={{
                          border: "1px solid #ccc",
                        }}
                      >
                        <BsHouseSlashFill />
                      </div>
                      <h6>duplex</h6>
                    </div>
                    <div
                      className="property_type"
                      onClick={() => {
                        setprottype("townhouse");
                      }}
                    >
                      <div
                        className={`${prottype == "townhouse" ? "active" : ""}`}
                        style={{
                          border: "1px solid #ccc",
                        }}
                      >
                        <BsHouses />
                      </div>
                      <h6>townhouse</h6>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    margin: "20px 0px",
                  }}
                >
                  <h6>bedrooms number</h6>
                  <div className="bedrooms_numbers">
                    <div
                      onClick={() => {
                        setbedroomsnumber("all");
                      }}
                      className={
                        bedroomsnumber == "all"
                          ? "bedrooms_number active"
                          : "bedrooms_number"
                      }
                    >
                      all
                    </div>
                    <div
                      className={`${
                        bedroomsnumber * 1 >= 1
                          ? "bedrooms_number active"
                          : "bedrooms_number"
                      }`}
                      onClick={() => {
                        setbedroomsnumber("1");
                      }}
                    >
                      1
                    </div>
                    <div
                      className={`${
                        bedroomsnumber * 1 >= 2
                          ? "bedrooms_number active"
                          : "bedrooms_number"
                      }`}
                      onClick={() => {
                        setbedroomsnumber("2");
                      }}
                    >
                      2
                    </div>
                    <div
                      className={`${
                        bedroomsnumber * 1 >= 3
                          ? "bedrooms_number active"
                          : "bedrooms_number"
                      }`}
                      onClick={() => {
                        setbedroomsnumber("3");
                      }}
                    >
                      3
                    </div>
                    <div
                      className={`${
                        bedroomsnumber * 1 >= 4
                          ? "bedrooms_number active"
                          : "bedrooms_number"
                      }`}
                      onClick={() => {
                        setbedroomsnumber("4");
                      }}
                    >
                      4
                    </div>
                    <div
                      className={`${
                        bedroomsnumber * 1 >= 5
                          ? "bedrooms_number active"
                          : "bedrooms_number"
                      }`}
                      onClick={() => {
                        setbedroomsnumber("5");
                      }}
                    >
                      5
                    </div>
                    <div
                      className={`${
                        bedroomsnumber * 1 >= 6
                          ? "bedrooms_number active"
                          : "bedrooms_number"
                      }`}
                      onClick={() => {
                        setbedroomsnumber("6");
                      }}
                    >
                      6+
                    </div>
                  </div>
                </div>
                <div>
                  <h6 style={{ textTransform: "capitalize" }}>
                    bathrooms number
                  </h6>
                  <div className="bathrooms_numbers">
                    <div
                      onClick={() => {
                        setbathroomnumber("all");
                      }}
                      className={
                        bathroomnumber == "all"
                          ? "bathroom_number active"
                          : "bathroom_number"
                      }
                    >
                      all
                    </div>
                    <div
                      onClick={() => {
                        setbathroomnumber("1");
                      }}
                      className={`${
                        bathroomnumber * 1 >= 1
                          ? "bathroom_number active"
                          : "bathroom_number"
                      }`}
                    >
                      1
                    </div>
                    <div
                      onClick={() => {
                        setbathroomnumber("2");
                      }}
                      className={`${
                        bathroomnumber * 1 >= 2
                          ? "bathroom_number active"
                          : "bathroom_number"
                      }`}
                    >
                      2
                    </div>
                    <div
                      onClick={() => {
                        setbathroomnumber("3");
                      }}
                      className={`${
                        bathroomnumber * 1 >= 3
                          ? "bathroom_number active"
                          : "bathroom_number"
                      }`}
                    >
                      3
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        width: "50%",
                        margin: "0px",
                        fontSize: "19px",
                      }}
                    >
                      from
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        width: "50%",
                        margin: "0px",
                        fontSize: "19px",
                      }}
                    >
                      to
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Select
                      showSearch
                      onChange={(e) => {
                        setmoney_from(e);
                      }}
                      value={money_from}
                      style={{
                        width: "50%",
                      }}
                      options={marks.map((item, index) => {
                        return {
                          label: `${item.label}`,
                          value: `${item.value}`,
                        };
                      })}
                    />
                    <Select
                      showSearch
                      onChange={(e) => {
                        setmoney_to(e);
                      }}
                      value={money_to}
                      style={{
                        width: "50%",
                      }}
                      options={marks.map((item, index) => {
                        return {
                          label: `${item.label}`,
                          value: `${item.value}`,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="square_metter">
                  <h5>
                    square meter <span>(m2)</span>
                  </h5>
                  <div className="min_max_square">
                    <div className="min_square">
                      <input
                        value={minsqu}
                        onChange={(e) => {
                          setminsqu(e.target.value);
                        }}
                        type="text"
                        placeholder="min 42"
                      />
                      <span>(m2)</span>
                    </div>
                    <div className="max_square">
                      <input
                        value={maxsqu}
                        onChange={(e) => {
                          setmaxsqu(e.target.value);
                        }}
                        type="text"
                        placeholder="max 1287"
                      />
                      <span>(m2)</span>
                    </div>
                  </div>
                </div>
                <div className="delivary_date">
                  <h6 style={{ textTransform: "capitalize" }}>Delivary Date</h6>
                  <div className="delivaries">
                    <div
                      onClick={() => {
                        setdelivary("move_now");
                      }}
                      className={
                        delivary == "move_now" ? "delivary active" : "delivary"
                      }
                    >
                      Move Now
                    </div>
                    <div
                      onClick={() => {
                        setdelivary("2024");
                      }}
                      className={
                        delivary == "2024" ? "delivary active" : "delivary"
                      }
                    >
                      2024
                    </div>
                    <div
                      onClick={() => {
                        setdelivary("2025");
                      }}
                      className={
                        delivary == "2025" ? "delivary active" : "delivary"
                      }
                    >
                      2025
                    </div>
                    <div
                      onClick={() => {
                        setdelivary("2026");
                      }}
                      className={
                        delivary == "2026" ? "delivary active" : "delivary"
                      }
                    >
                      2026
                    </div>
                    <div
                      onClick={() => {
                        setdelivary("2027");
                      }}
                      className={
                        delivary == "2027" ? "delivary active" : "delivary"
                      }
                    >
                      2027
                    </div>
                  </div>
                </div>
                <div className="sale_type_div">
                  <h6>sale type</h6>
                  <div className="sales_type">
                    <div
                      onClick={() => {
                        setsaletype("developer_sale");
                      }}
                      className={`${
                        saletype == "developer_sale"
                          ? "sale_type active"
                          : "sale_type"
                      }`}
                    >
                      developer sale
                    </div>
                    <div
                      onClick={() => {
                        setsaletype("resale");
                      }}
                      className={`${
                        saletype == "resale" ? "sale_type active" : "sale_type"
                      }`}
                    >
                      resale
                    </div>
                    <div
                      className={`${
                        saletype == "nawy_now"
                          ? "sale_type active"
                          : "sale_type"
                      }`}
                      onClick={() => {
                        setsaletype("nawy_now");
                      }}
                    >
                      nawy now
                    </div>
                  </div>
                </div>
                <div className="finishing_div">
                  <h6>finishing</h6>
                  <div className="finishings">
                    <div
                      onClick={() => {
                        setfinishigstatus("all");
                      }}
                      className={
                        finishigstatus == "all"
                          ? "finishing active"
                          : "finishing"
                      }
                    >
                      all
                    </div>
                    <div
                      onClick={() => {
                        setfinishigstatus("unfinished");
                      }}
                      className={
                        finishigstatus == "unfinished"
                          ? "finishing active"
                          : "finishing"
                      }
                    >
                      unfinished
                    </div>
                    <div
                      onClick={() => {
                        setfinishigstatus("semi_finished");
                      }}
                      className={
                        finishigstatus == "semi_finished"
                          ? "finishing active"
                          : "finishing"
                      }
                    >
                      semi-finished
                    </div>
                    <div
                      onClick={() => {
                        setfinishigstatus("finished");
                      }}
                      className={`${
                        finishigstatus == "finished"
                          ? "finishing active"
                          : "finishing"
                      }`}
                    >
                      finished
                    </div>
                  </div>
                </div>
                <div className="payment_paln_div">
                  <h6 style={{ textTransform: "capitalize" }}>payment plan</h6>
                  <div className="pay_methods">
                    <div className="down_payment">
                      <h5 style={{ textAlign: "center" }}>Down Payment</h5>
                      <input
                        value={downpay}
                        onChange={(e) => {
                          setdownpay(e.target.value);
                        }}
                        type="text"
                        placeholder="Up to"
                      />
                    </div>
                    <div className="monthly_installment">
                      <h5 style={{ textAlign: "center", width: "100%" }}>
                        monthly installment
                      </h5>
                      <input
                        value={monthinstall}
                        onChange={(e) => {
                          setmonthinstall(e.target.value);
                        }}
                        type="text"
                        placeholder="Up to"
                      />
                      <span>EGP</span>
                    </div>
                  </div>
                </div>
                <div className="installment_years">
                  <h5>installment years</h5>
                  <div className="installments">
                    <div
                      onClick={() => {
                        setinstall("all");
                      }}
                      className={
                        install == "all" ? "install active" : "install"
                      }
                    >
                      all
                    </div>
                    <div
                      onClick={() => {
                        setinstall("1");
                      }}
                      className={
                        install.replace("+", "") * 1 >= 1
                          ? "install active"
                          : "install"
                      }
                    >
                      1
                    </div>
                    <div
                      onClick={() => {
                        setinstall("2");
                      }}
                      className={
                        install.replace("+", "") * 1 >= 2
                          ? "install active"
                          : "install"
                      }
                    >
                      2
                    </div>
                    <div
                      onClick={() => {
                        setinstall("3");
                      }}
                      className={
                        install.replace("+", "") * 1 >= 3
                          ? "install active"
                          : "install"
                      }
                    >
                      3
                    </div>
                    <div
                      onClick={() => {
                        setinstall("4");
                      }}
                      className={
                        install.replace("+", "") * 1 >= 4
                          ? "install active"
                          : "install"
                      }
                    >
                      4
                    </div>
                    <div
                      onClick={() => {
                        setinstall("5");
                      }}
                      className={
                        install.replace("+", "") * 1 >= 5
                          ? "install active"
                          : "install"
                      }
                    >
                      5
                    </div>
                    <div
                      onClick={() => {
                        setinstall("6+");
                      }}
                      className={
                        install.replace("+", "") * 1 >= 6
                          ? "install active"
                          : "install"
                      }
                    >
                      6+
                    </div>
                  </div>
                </div>
                <div className="select_area">
                  <h5 style={{ textTransform: "capitalize" }}>area</h5>
                  <div className="sullocation">
                    <Select
                      style={{ width: "48%" }}
                      value={location_id}
                      className=" nice-select"
                      placeholder="select location"
                      onChange={(e) => {
                        let data = locations.filter(
                          (item) => item.location_id == e
                        );

                        setlocation_id(e);
                        // setbuildingdata({...buildingdata,sub_location_id:data[0]?.sub_location[0]?.sub_id});
                        setlocation_id(e);
                        //setbuildingdata({...buildingdata,location_id:e});
                      }}
                      id=""
                      options={locations.map((item, index) => {
                        return {
                          label: `${item.location_name}`,
                          value: `${item.location_id}`,
                        };
                      })}
                    />
                    <Select
                      style={{ width: "48%" }}
                      value={suplocation_id}
                      className=" nice-select"
                      placeholder="select location"
                      onChange={(e) => {
                        //console.log(e)
                        setsuplocation_id(e);
                      }}
                      id=""
                      // options={Location?.find(item=>item.location_id==buildingdata.location_id)?.sub_location?.map(item=>{
                      //   return {label:`${item.name}`, value:`${item.sub_id}`};
                      // })}
                      options={sublocations?.map((item, index) => {
                        return {
                          label: `${item.name}`,
                          value: `${item.sub_id}`,
                        };
                      })}
                    />
                  </div>
                </div>
                <div className="select_compund">
                  <h6 style={{ textTransform: "capitalize" }}>compound</h6>

                  <Select
                    showSearch
                    onChange={(e) => {
                      setcompound_id(e);
                    }}
                    value={compound_id}
                    style={{
                      width: "100%",
                    }}
                    options={compoundes.map((item, index) => {
                      return {
                        label: `${item.compound_name}`,
                        value: `${item.compound_id}`,
                      };
                    })}
                  />

                  {/* <div className="add_compound">
                                    add compound <span>+</span>
                                  </div> */}
                </div>
              </div>
            ) : answer_after == "reschadural" ? (
              <div
                style={{
                  width: "100%",
                }}
              >
                <input
                  style={{
                    width: "100%",
                    height: "30px",
                    borderRadius: "10px",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                  type="time"
                  onChange={(e) => {
                    settime(e.target.value);
                  }}
                />
                <input
                  style={{
                    width: "100%",
                    height: "30px",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  type="date"
                  onChange={(e) => {
                    setdate(e.target.value);
                  }}
                />
              </div>
            ) : null}
          </>
        ) : calltype == "not_answer" ? (
          <>
            <Select
              placeholder="enter resone of not answer"
              //defaultValue="lucy"
              style={{ width: "100%" }}
              onChange={(e) => {
                setnot_answer_Content(e);
              }}
              options={[
                { value: "phone_closed", label: "phone closed" },
                { value: "out_of_service", label: "out of service" },
                { value: "wrong_number", label: "wrong number" },
                {
                  value: "ring_without_response",
                  label: "ring without response",
                },
              ]}
            />
          </>
        ) : null}
      </Modal>

      <Modal
        title="Edit Customer"
        open={showEdit}
        onOk={() => {
          if (addLoading) {
            toast.warn("request is running now Please Wait");
            return;
          }
          handleEdit();
          // handleOk()
        }}
        onCancel={() => {
          setShowEdit(false);
        }}
        centered
      >
        <form>
          <input
            value={rowData.client_name}
            onChange={(e) => {
              setRowData({ ...rowData, client_name: e.target.value });
            }}
            type="text"
            placeholder="enter client name"
          />
          <input
            value={rowData.client_phone}
            onChange={(e) => {
              setRowData({ ...rowData, client_phone: e.target.value });
            }}
            type="text"
            placeholder="enter client phone"
          />
          <input
            value={rowData.client_job}
            onChange={(e) => {
              setRowData({ ...rowData, client_job: e.target.value });
            }}
            type="text"
            placeholder="enter client job"
          />
          <div style={{ width: "100%" }}>
            <label htmlFor="">Type</label>
            <Select
              style={{ width: "100%" }}
              value={rowData.client_type}
              onChange={(e) => {
                setRowData({ ...rowData, client_type: e });
              }}
              options={[
                { label: "Cold", value: "cold" },
                { label: "Leads", value: "leads" },
              ]}
              classNamePrefix="select2-selection"
            />
          </div>
          <input
            value={rowData.client_address}
            onChange={(e) => {
              setRowData({ ...rowData, client_address: e.target.value });
            }}
            type="text"
            placeholder="enter client address"
          />
          {addLoading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "40px",
                width: "100%",
              }}
            >
              <ThreeDots />
            </div>
          )}
        </form>
      </Modal>
    </>
  );
};

export default SellesAssign;
