import React, { useCallback, useMemo, useRef } from 'react'

import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from 'react-tabulator'
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AgGridReact } from "@ag-grid-community/react";
import "@ag-grid-community/styles/ag-grid.css";
// import Tabulator from 'tabulator-tables';
import Lottie from 'lottie-react';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
// import './sellesAssign.css'
import * as XLSX from 'xlsx';

const FileClients = () => {
  const gridRef = useRef();

  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) ,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 3000,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const {state}=useLocation()

  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log('Content copied to clipboard');
      toast.success('Content copied to clipboard')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }


  const phone1lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      // console.log(rowData)
      copyContent(rowData.phone)
    })
    return div;
  }

  const phone2lead= (cell, formatterParams, onRendered)=>{
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div=document.createElement('div');

    const img = document.createElement("img");
    img.src = rowData?.image;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone=document.createElement('span')
    phone.innerText=rowData.phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy=document.createElement("img");

    imgcopy.src=rowData.copyimg
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(`whatsapp://send?text=!&phone=+2${rowData.phone2.startsWith("0")?rowData.phone2:`${rowData.phone2}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData,"copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div=document.createElement('div');
    imgs_div.appendChild(imgcopy)
    imgs_div.appendChild(img)
    div.appendChild(imgs_div)
    div.style.display='flex';
    div.style.alignItems='center';
    div.style.justifyContent='space-between'
    imgcopy.addEventListener('click',function(){
      console.log(rowData)
      copyContent(rowData.phone)
    })
    return div;
  }

  const columns = [
    // { title: 'Number', field: 'number', sorter: 'number', headerFilter: "input" },
    // { title: 'ID', field: 'client_id', sorter: 'number', headerFilter: "input" },
    { title: 'Name', field: 'name', sorter: 'string', headerFilter: "input" },
    { title: 'Phone', field: 'phone',formatter:phone1lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone"  },
    { title: 'Phone2', field: 'phone2',formatter:phone2lead, sorter: 'string', headerFilter: "input", headerFilterPlaceholder: "phone2" },
    { title: 'Address', field: 'address', sorter: 'string', headerFilter: "input" },
    { title: 'Added Date', field: 'added_at', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" },
    // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
    // { title: 'Profile Image', field: 'image', formatter: imageFormatter,cellClick:function(e, cell){

      // let rowData=cell.getRow().getData();
      //e - the event object
      //cell - cell component
      // console.log('yesclick')
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`${rowData.phone}`}`,'_blank')
  // } },
    // { title: 'Print', formatter: deleteButtonFormatter, width: 100 }
  ];

  const [loading,setloading]=useState(true);

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "name",
      aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params?.data&&params?.data?.phone!=null&&params?.data.phone!=""&&
          <div>
                <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params?.data&&params.data.phone)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.phone!=null&&params.data.phone!=""&&
          <div>
            <p>{params.data.phone}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "",
      field: "phone2",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {console.log(params.data)}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
                          <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params.data.phone2)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone2",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
            <p>{params.data.phone2}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "added at",
      field: "added_at",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },



    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },


    {
      headerName: "address",
      field: "address",
      sortable: true,
    },


  ]);
  const [selectedRows,setSelectedRows]=useState([]);


  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  const [data,setData]=useState([]);

  const getData=()=>{
    setloading(true)
    const data_send={
      uplouded_sheets_id:state?.sheet?.uplouded_sheets_id
    }
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/sheet_clients.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res,"this is response")
      if(Array.isArray(res.message)){
        setData(res.message.map((item)=>{
          return {...item,image:require("../../assets/images/whats.png"),copyimg:require("../../assets/images/copy.png")}
        }))
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setloading(false)
    })
  }

  const gridStyle = useMemo(() => ({ height: "100vh" }), []);

  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
    // setFilteredRowCount(rowCount);
  };
  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      // flex: 10,
      width:'fit-content'
    };
  }, []);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows())
    if(event.api.getSelectedRows().lengt>0){
      // setDisConf(true);
    }
    else {
      // setDisConf(false);
    }
  };
  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Client name': item.name || '',
          'Client phone': item.phone || '',
          'Client phone2': item.phone2 || '',
          'Client project_intrested_in': item.project_intrested_in || '',
          'Client time_to_call': item.time_to_call || '',
          'Client unitcode': item.unitcode || '',
          'Client client_type': item.client_type || '',
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          'Client job': item.job || '',
          'Client address': item.address || '',
          // 'agent name': item?.sales[0].name || '',
          // 'agent Email': item?.sales[0].user_email || '',
          // 'agent Phone': item?.sales[0].phone || '',

          // 'feed_back':item.feed_back,
          // "creation_date":moment(item.added_at).format("L"),
          // "last_action_date":moment(item.last_action_date).format("L"),
          // "markter name":item.marketing_name,
          // "last_feed_call":item.last_feed_call,
          // "assigned_date":moment(item.assigned_date).format("L"),

        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };
  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };
  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
      });
  }, []);
  useEffect(()=>{
    getData()
  },[])

  return (
    <>
      {
        loading?(
          <div style={{
            minHeight:'100vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          )
        :(
          <div className='container py-6' style={{marginTop:'78px',overflow:'auto',minHeight:'100vh',minWidth:'100%'}}>
  <div className="my-3">
              <button
                onClick={() => {
                  onExportClick()
                  // handleExport();
                }}
                className="btn btn-primary text-light"
              >
                Export
              </button>
            </div>
            <div   style={gridStyle}
              className={
                "ag-theme-quartz-dark"
              }>
            <AgGridReact
            // autoGroupColumnDef ={}
            onFilterChanged={()=>{
               onFilterChanged()
            }}
            enableAdvancedFilter={true}
            grandTotalRow={"top"}
                ref={gridRef}
                rowData={data}
                // sideBar={true}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowSelection="multiple"
                // columnsi
                rowDragManaged={true}
                tooltipShowDelay={{tooltipShowDelay: 2}}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                // ref={gridRef}
                isRowSelectable={isRowSelectable}
          // pagination={true}
          // quickFilterText={quickSearchValue}


              />

              </div>

{/*
            <ReactTabulator
              data={data}
              options={
                options
              }
              // sortOrderReverse={true}
              columns={columns}
              layout={"fitData"}
              /> */}
          </div>
        )
      }
    </>
  )
}

export default FileClients
