import React, { useCallback } from 'react'
import './assgin.css'
import { Button, Checkbox, Select, Space, Spin, Table, message } from 'antd';
import './assgin.css'
import axios from 'axios'

import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
const Assign = () => {
  const options = [];
  const [employeessdata,setemployeessdata]=useState([]);
  const [clientsdata,setclientsdata]=useState([]);

  const [selectedemployee,setselectedemployee]=useState("");
  const [selectedclient,setselectedclient]=useState("");
  const [assignloading,setassignloading]=useState(false);
  const [selectedclients,setselectedclients]=useState("");
  const [selectedcleintsarray,setselectedcleintsarray]=useState([]);
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}

const geteployees=()=>{
  axios.get("https://ahmed.its.ae/new_quad_wall/admin/select_ageint.php")
  .then((res)=>{
    console.log(res)
    //console.log(res);
    setemployeessdata(res.message);
    setselectedemployee(res?.message[0]?.agent_id)
    //console.log(res?.message[0]?.name);
  })
}
const getclientsdata=()=>{
  axios.get("https://ahmed.its.ae/new_quad_wall/admin/select_client.php")
  .then((res)=>{
    console.log(res)
    console.log(res);
    let newdata=[...res.message];
    let filterddata=newdata.map((item)=>{
      return {...item,check:false};
    })
    setclientsdata(filterddata);
    setselectedclient(res?.message[0]?.name);
  })
}
/* const retnotchecked=useCallback(()=>{
  clientsdata.map((item)=>{
    return {...item,check:false};
  })
},[]) */
const assignfunction=()=>{
  let selected=[...clientsdata];
  let checddata=selected.filter(item=>item.check==true);
  let selectedtxt="";
  if(selectedemployee==""){
    toast.warn("select at least one employee")
  }
  for(let i=0;i<checddata.length;i++){
    if(i==0){
      selectedtxt+=checddata[i].client_id;
    }
    else {
      selectedtxt+="**UAD**"+checddata[i].client_id;
    }
  }
  const data_send={
    client_id_data:selectedtxt,
    agent_id:selectedemployee,
  }
  console.log(data_send);
  setassignloading(true);
  axios.post("https://ahmed.its.ae/new_quad_wall/admin/assign_agent_to_client.php",JSON.stringify(data_send))
  .then((res)=>{
    console.log(res)
    if(res.status==="success"){
      let alldata=[...clientsdata];
      alldata.map((item)=>{
        return {...item,check:false};
      })
      //retnotchecked();
      setclientsdata(alldata)

      toast.success(res.message);
      getclientsdata();
    }
    else if(res.status=="error"){
      toast.error(res.message);
    }
    else {
      toast.error("something is error try again")
    }
  }).finally(()=>{
    setassignloading(false);
  })
}
useEffect(()=>{
  geteployees();
  getclientsdata();
},[])
/* const handleChange = (value) => {
  console.log(`selected ${value}`);
}; */

const onChange = (e) => {
  //console.log(e)
  setclientsdata(
    clientsdata?.map((item,index)=>{
      if(item.client_id==e){
        return {...item,check:!item.check};
      }
      else return{...item};
    })
  );
  //console.log(`checked = ${e.target.checked}`);
};
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },

  {
    title: 'phone',
    dataIndex: 'phone',
    key: 'phone',
  },
  {
    title: 'assigned agent',
    dataIndex: 'assign_agent_name',
    key: 'assign_agent_name',
    render:(_,record)=>(
      <Space>
        {
          record.assign_agent_id==0||record.assign_agent_id==null?(
            <>
              <div
                style={{
                  width:'30px',
                  height:'30px',
                  borderRadius:'50%',
                  backgroundColor:'red'
                }}
              >

              </div>
            </>
          ):(
            <>
              <span>{record.assign_agent_name}</span>
            </>
          )
        }
      </Space>
    )
  },

  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
/*   {
    title: 'agent',
    dataIndex: 'agent',
    key: 'agent',
  }, */
/*   {
    title: 'client type',
    dataIndex: 'client_type',
    key: 'client_type',
  }, */
/*   {
    title: 'assigned by',
    dataIndex: 'agent',
    key: 'agent',
    render:(_,record)=>(
      <Space>
        {record.agent}
        {
          record.agent==0?(
            <>
              no person
            </>
          ):(
            <>
              <span>{record.agent}</span>
            </>
          )
        }
      </Space>
    )
  }, */
  {
    title: 'action',
    dataIndex: 'action',
    key: 'action',
    render:(_,record)=>(
      <Space>
        <Checkbox onChange={()=>{
          onChange(record.client_id)
        }}></Checkbox>
      </Space>
    )
  },
];
  return (
    <div style={{
      marginTop:'50px',

    }} className='container py-5'>
      <form
      onSubmit={(e)=>{
        e.preventDefault();
        assignfunction();
      }}
      style={{
        display:'flex'
      }} className='assignform'>
        <Select
          style={{

          }}
          value={selectedemployee}
          onChange={(e)=>{
            //console.log(e)
            setselectedemployee(e);
          }}
          options={employeessdata.map((item,index)=>{
              return {label:`${item.name}`, value:`${item.agent_id}`};
            })
          }
        />
        <Table dataSource={clientsdata} columns={columns} />
{/*         <Select
          mode="multiple"
          allowClear
          style={{
          }}
          placeholder="select clients"
          //value={selectedclient}
          onChange={(e)=>{
            setselectedcleintsarray(e);
          }}
          options={
            clientsdata.map((item,index)=>{
            return { label: `${item.name}`, value: `${item.client_id}` };

              //return{label:`${item.name}`, value:`${item.client_id}`}
            })}
        /> */}
          <Button onClick={()=>{
              assignfunction();
            }} className='btn btn-primary'>
              {
                assignloading?(
                  <Spin/>
                ):(
                  "assign"
                )
              }
          </Button>
      </form>
    </div>
  )
}

export default Assign

/*
<Select
      mode="multiple"
      disabled
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      defaultValue={['a10', 'c12']}
      onChange={handleChange}
      options={options}
    />

*/
