import React, { useCallback } from 'react'
//import './assgin.css'
import { Button, Checkbox, Select, Space, Spin, Table, message ,Input} from 'antd';
import '../../assgin.css'
import axios from 'axios'
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { useState,useRef } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
const AssAssLeads = () => {
  const localData=localStorage.getItem('authUser');
  let userAuth=localData&&JSON.parse(localData);
  const options = [];
  // const searchInput = useRef(null);
  const [employeessdata,setemployeessdata]=useState([]);
  const [clientsdata,setclientsdata]=useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [selectedemployee,setselectedemployee]=useState("");
  const [selectedclient,setselectedclient]=useState("");
  const [assignloading,setassignloading]=useState(false);
  const [selectedclients,setselectedclients]=useState("");
  const [selectedcleintsarray,setselectedcleintsarray]=useState([]);
for (let i = 10; i < 36; i++) {
  options.push({
    label: i.toString(36) + i,
    value: i.toString(36) + i,
  });
}


const geteployees=()=>{
  axios.get("https://ahmed.its.ae/new_quad_wall/admin/select_ageint.php")
  .then((res)=>{
    console.log(res)
    //console.log(res);
    setemployeessdata(res.message);
    setselectedemployee(res?.message[0]?.agent_id)
    //console.log(res?.message[0]?.name);
  })
}
const getclientsdata=()=>{
  axios.post("https://ahmed.its.ae/new_quad_wall/admin/select_assigned_data.php",{
    type:'leads',
    user_type:userAuth?.user_type,
      user_id:userAuth?.agent_id
  })
  .then((res)=>{
    console.log(res)
    //console.log(res);
    let newdata=[...res.message];
    let filterddata=newdata.map((item)=>{
      return {...item,check:false};
    })
    setclientsdata(filterddata);
    setselectedclient(res?.message[0]?.name);
  })
}
/* const retnotchecked=useCallback(()=>{
  clientsdata.map((item)=>{
    return {...item,check:false};
  })
},[]) */
const assignfunction=()=>{
  let selected=[...clientsdata];
  let checddata=selected.filter(item=>item.check==true);
  let selectedtxt="";
  if(selectedemployee==""){
    toast.warn("select at least one employee")
  }
  for(let i=0;i<checddata.length;i++){
    if(i==0){
      selectedtxt+=checddata[i].client_id;
    }
    else {
      selectedtxt+="**UAD**"+checddata[i].client_id;
    }
  }
  const data_send={
    client_id_data:selectedtxt,
    agent_id:selectedemployee,
  }
  console.log(data_send);
  setassignloading(true);
  axios.post("https://ahmed.its.ae/new_quad_wall/admin/assign_agent_to_client.php",JSON.stringify(data_send))
  .then((res)=>{
    console.log(res)
    if(res.status==="success"){
      let alldata=[...clientsdata];
      alldata.map((item)=>{
        return {...item,check:false};
      })
      //retnotchecked();
      setclientsdata(alldata)

      toast.success(res.message);
      getclientsdata();
    }
    else if(res.status=="error"){
      toast.error(res.message);
    }
    else {
      toast.error("something is error try again")
    }
  }).finally(()=>{
    setassignloading(false);
  })
}
useEffect(()=>{
  geteployees();
  getclientsdata();
},[])
/* const handleChange = (value) => {
  console.log(`selected ${value}`);
}; */

const onChange = (e) => {
  //console.log(e)
  setclientsdata(
    clientsdata?.map((item,index)=>{
      if(item.client_id==e){
        return {...item,check:!item.check};
      }
      else return{...item};
    })
  );
  //console.log(`checked = ${e.target.checked}`);
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
  setSearchText(selectedKeys[0]);
  setSearchedColumn(dataIndex);
};

const handleReset = clearFilters => {
  clearFilters();
  setSearchText('');
};

const getColumnSearchProps = dataIndex => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) =>
    record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
  onFilterDropdownVisibleChange: visible => {
    if (visible) {
      setTimeout(() => searchInput.current.select(), 100);
    }
  },
  render: text =>
    searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
});


const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    ...getColumnSearchProps('name'),
  },
  {
    title: 'Phone',
    dataIndex: 'phone',
    key: 'phone',
    ...getColumnSearchProps('phone'),
  },
  {
    title: 'Assigned Date',
    dataIndex: 'assigned_date',
    key: 'assigned_date',
    render: (_, record) => <div><h5>{moment(record.assigned_date).format('L')}</h5></div>,
  },
  {
    title: 'Job',
    dataIndex: 'job',
    key: 'job',
    ...getColumnSearchProps('job'),
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
    ...getColumnSearchProps('address'),
  },
  {
    title: 'Assigned By',
    dataIndex: 'assign_agent_name',
    key: 'assign_agent_name',
    ...getColumnSearchProps('assign_agent_name'),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    render: (_, record) => (
      <Space>
        <Checkbox checked={record.check} onChange={() => onChange(record.client_id)}></Checkbox>
      </Space>
    ),
  },
];

const handleAss50=(len)=>{
  let allClis=[...clientsdata];
  for(let i=0;i<clientsdata.length;i++){
    allClis[i]['check']=false
  }
  for(let i=0;i<len;i++){
    allClis[i]['check']=!allClis[i]['check']
  }
  setclientsdata(allClis)
  // console.log(allClis)
}
  return (
    <div style={{
      marginTop:'50px',
      overflow:'auto'

    }} className='container py-5'>
      <div style={{
        textAlign:'center',
        marginBottom:'10px',
        textTransform:'capitalize',
        fontSize:'22px'
      }}>
        assign assigned (leads)
      </div>
      <div className='my-5 d-flex align-items-center px-2 justify-content-between'>
          <h5>Bulk assign</h5>
          <div className='d-flex align-items-center gap-2'>
            {
              clientsdata.length>80?
              (
                <>
                  <p onClick={()=>{
                    handleAss50(50)
                  }} className='btn btn-primary'>
                    50
                  </p>
                </>
              )
              :
              null
            }
              {
            clientsdata.length>100&&(
              <>
              <p onClick={()=>{
                handleAss50(100)
              }} className='btn btn-primary'>
                100
              </p>
            </>
            )
          }
          <p onClick={()=>{
            handleAss50(5)
          }} className='btn btn-primary'>
            5
          </p>
          <p onClick={()=>{
            handleAss50(10)
          }} className='btn btn-primary'>
            10
          </p>
          </div>
        </div>
      <form
      onSubmit={(e)=>{
        e.preventDefault();
        assignfunction();
      }}
      style={{
        display:'flex'
      }} className='assignform'>
        <Select
          style={{

          }}
          value={selectedemployee}
          onChange={(e)=>{
            //console.log(e)
            setselectedemployee(e);
          }}
          options={employeessdata.map((item,index)=>{
              return {label:`${item.name}`, value:`${item.agent_id}`};
            })
          }
        />
        <Table dataSource={clientsdata} columns={columns} />
{/*         <Select
          mode="multiple"
          allowClear
          style={{
          }}
          placeholder="select clients"
          //value={selectedclient}
          onChange={(e)=>{
            setselectedcleintsarray(e);
          }}
          options={
            clientsdata.map((item,index)=>{
            return { label: `${item.name}`, value: `${item.client_id}` };

              //return{label:`${item.name}`, value:`${item.client_id}`}
            })}
        /> */}
          <Button onClick={()=>{
              assignfunction();
            }} className='btn btn-primary'>
              {
                assignloading?(
                  <Spin/>
                ):(
                  "assign"
                )
              }
          </Button>
      </form>
    </div>
  )
}

export default AssAssLeads

/*
<Select
      mode="multiple"
      disabled
      style={{
        width: '100%',
      }}
      placeholder="Please select"
      defaultValue={['a10', 'c12']}
      onChange={handleChange}
      options={options}
    />

*/
