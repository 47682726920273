import { Button, Card, Col, Input, Modal, Select, Space, Spin, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AiOutlinePlus, AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import { AppContext } from '../../components/context/AppContextProvider';
import moment from 'moment';
import 'react-tabulator/lib/styles.css';
import 'tabulator-tables/dist/css/tabulator.min.css';
import { ReactTabulator } from 'react-tabulator'
import 'react-tabulator/lib/styles.css';
import * as XLSX from 'xlsx';

import "tabulator-tables/dist/css/tabulator.min.css";
import Lottie from 'lottie-react';
import Highlighter from 'react-highlight-words';
import { AgGridReact } from 'ag-grid-react';
const Search = () => {
  const authUser = localStorage.getItem("authUser");
  let uData = JSON.parse(authUser);
  const [params, setParams] = useSearchParams();
  const [projectInterested,setProjectInterested]=useState([]);
  const [selectedAll,setSelectedAll]=useState(false)
  const  [selectedSale,setSelectedSale]=useState('');
  const [selectedAgents,setSelectedAgents]=useState([])
  const [selectedStatuses,setSelectedStatuses]=useState([]);
  const searchInput = useRef(null);
  const [page, setPage] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [comment, setComment] = useState("");
  const [commentsData, setCommentsData] = useState([]);
  const [ClientId, setClientId] = useState(null);
  const [assignloading, setassignloading] = useState(false);
  const [projects,setProjects]=useState([]);
  const [calls, setCalls] = useState([]);
  const [coulmns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [sales,setSales]=useState([]);
  const [showCalls, setShowCalls] = useState(false);
  const [callLoading, setCallLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [showResult, setShowResult] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const handleGetCalls = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/new_client_calls.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCalls(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };
  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
    // setFilteredRowCount(rowCount);
  };
  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log("Content copied to clipboard");
      toast.success("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  const handleGetComments = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/get_client_comments.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCommentsData(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<AiOutlineSearch/>}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <AiOutlineSearch
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const renderusers = [
    {
      title: 'Number',
      key: 'number',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Select',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <input onClick={()=>{
              setSearchData(searchData.map((item)=>{
                return {...item,check:item.client_id==record.client_id?!record.check:item.check}
              }))
            }} checked={record.check} type="checkbox" style={{ width:'15px',height:'15px' }}/>
          </div>
        </Space>
        )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },

    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
    },
    // {
    //   title: 'Last Action',
    //   dataIndex: 'last_action_date',
    //   key: 'last_action_date',
    //   width: '20%',
    //   ...getColumnSearchProps('last_action_date'),
    // },
    {
      title: 'Time To Call',
      dataIndex: 'time_to_call',
      key: 'time_to_call',
      width: '20%',
      ...getColumnSearchProps('time_to_call'),
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      render: (_, record) => (
        <Space>
        <div>
          {
            record.phone!=null&&record.phone!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${record.phone.startsWith("0")?record.phone:`${record.phone}`}`,'_blank')
              }} src={require("../../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(record.phone)
              }} src={require("../../assets/images/copy.png")} alt="" />
              <p>{record.phone}</p>
            </div>
          }
        </div>
      </Space>
        )
    },
    {
      title: 'phone2',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
        <div>
          {
            record.phone2!=null&&record.phone2!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${record.phone2.startsWith("0")?record.phone2:`${record.phone2}`}`,'_blank')
              }} src={require("../../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(record.phone2)
              }} src={require("../../assets/images/copy.png")} alt="" />
              <p>{record.phone2}</p>
            </div>
          }
        </div>
      </Space>
        )
    },
    {
      title: 'Sale Name',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.name}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Sale Email',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.user_email}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Sale phone',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.phone}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Calls',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={()=>{
            handleGetCalls(record?.client_id);
            setRowData(record?.client_id);
              setShowCalls(true);
            }} className="btn btn-primary">
              Calls
            </button>
          </div>
        </Space>
        )
    },
    {
      title: 'Comments',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%",rowGap:'5px' }}>
            <button onClick={()=>{
                handleGetComments(rowData?.client_id);
                setRowData(rowData?.client_id);
                setClientId(rowData);
            }} className="btn btn-primary">
              Comments
            </button>
            <button onClick={()=>{
                window.location.href = "/UserProfile/" + record?.client_id;
            }} className="btn btn-primary">
              Profile
            </button>

          </div>
        </Space>
        )
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];


  const handleSearch2 = (txt='', txtPage) => {
    if(txt==null){
      txt=''
    }
    console.log(txt.split('\n').join('-'))
    let original_txt=txt.split('\n').join('-');
    // console.log(txt.split("/\n"))
    // console.log(txt.replace("/\n",'-'))
    // let arrString=txt.split("/\n")[0];
    // console.log(arrString)
    // let originalTxt=arrString.replace('/\n',',');
    // console.log(originalTxt)
    // return
    if (txt == "") {
      setShowResult(false);
      // return;
    }
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      newParams.set("page", txtPage);
      return newParams;
    });
    setSearchLoading(true);
    // alert(txtPage)
    const data_send = {
      search_text: original_txt,
      page: txtPage,
      type:uData?.user_type
    };
    console.log(data_send)
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/search_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == "success") {
          setParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set(
              "tb",

              res.total_pages
            );

            newParams.set(
              "page",

              txtPage
            );
            return newParams;
          });
          if (Array.isArray(res.message)) {
            setSearchData(res.message.map((item)=>{
              return{...item,check:false}
            }));
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setSearchLoading(false);
      });
  };

  const handlePageChange = (increment) => {
    let newPage = null;
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      const currentPage = parseInt(params.get("page")) || 1;
      const totalPages = parseInt(params.get("tb")) || 1;
      newPage = currentPage;

      if (increment) {
        newPage = currentPage < totalPages ? currentPage + 1 : currentPage;
      } else {
        newPage = currentPage > 1 ? currentPage - 1 : currentPage;
      }

      newParams.set("page", newPage);
      return newParams;
    });

    handleSearch2(params.get("searchValue"), newPage);
  };
  return (
    <>
      <div style={{
            marginTop:'30px',
            overflow:'auto',
            padding:'30px',
            minWidth:'100%'
          }} className='py-5 container'>
              <div className="row">
                    <textarea
                      placeholder="Search For Client"
                      value={params?.get("searchValue")}
                      onChange={(e) => {
                        // setShowResult(true);
                        setParams((prevParams) => {
                          const newParams = new URLSearchParams(prevParams);
                          newParams.set("searchValue", e.target.value);
                          return newParams;
                        });
                      }}
                      className="w-100 textareat_search" name="" id=""></textarea>
  <button
                    disabled={searchLoading}
                    className="btn btn-success my-2"
                    onClick={() => {
                      handleSearch2(params.get("searchValue"), 1);
                    }}
                  >
                    {
                      searchLoading?
                        <Spin/>
                      :'Search'
                    }
                  </button>
                  </div>
                  <div style={{ overflow:'auto' }}>
                              <Table style={{ marginTop:'10px' }} dataSource={searchData} columns={renderusers} />
                              </div>
                        <div className="pagination my-4">
                          <button
                            className="btn btn-danger mx-2"
                            onClick={() => handlePageChange(false)}
                          >
                            Prev
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={() => handlePageChange(true)}
                          >
                            Next
                          </button>
                        </div>

          </div>
    </>
  )
}

export default Search
