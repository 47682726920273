import { AgGridReact } from 'ag-grid-react';
import axios from 'axios';
import Lottie from 'lottie-react';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import moment from 'moment';

const Logs = () => {
  const gridStyle = useMemo(() => ({ height: "100vh" }), []);
  const gridRef = useRef();
  const [data,setData]=useState([])
  const [selectedRows,setSelectedRows]=useState([]);
  const [pageLoading,setPageLoading]=useState(false)

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      // flex: 10,
      width:'fit-content'
    };
  }, []);
  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) ,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 3000,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "operation_name",
      aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: 'email',
      field: "user_email",
      aggFunc:"sum",
      // tooltipField:"age",
    },


    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.phone!=null&&params.data.phone!=""&&
          <div>
            <p>{params.data.phone}</p>
          </div>
        }
      </div>
      ),
    },



    {
      headerName: "date",
      field: "date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },


    {
      headerName: 'action',
      field: "action",
      aggFunc:"sum",
      // tooltipField:"age",
    },

  ]);

  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows())
    if(event.api.getSelectedRows().lengt>0){
      // setDisConf(true);
    }
    else {
      // setDisConf(false);
    }
  };

  const getLogs=()=>{
    setPageLoading(true)
    axios.get("https://ahmed.its.ae/new_quad_wall/admin/logs.php")
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        if(Array.isArray(res.message)){
          setData(res.message);
        }
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setPageLoading(false)
    })
    .catch(e=>console.log(e))
  }

  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
  };
  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
      });
  }, []);
  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };


  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'name': item.operation_name || '',
          'phone': item.phone || '',
          'email': item.user_email || '',
          "date":moment(item.date).format("L"),
          'action': item.action || '',
        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };

  useEffect(()=>{
    getLogs()
  },[])
  return (
    <div style={{ padding:'10px',marginTop:'60px' }}>
      {
        pageLoading?
        <div style={{
          height:'100vh',
          width:'100%',
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
        }}>
          <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
        </div>
        :
        <>
          <div style={gridStyle}
            className={
              "ag-theme-quartz-dark"
            }>
              <div className="my-3">
              <button
                onClick={() => {
                  onExportClick()
                  // handleExport();
                }}
                className="btn btn-primary text-light"
              >
                Export
              </button>
            </div>
          <AgGridReact
          // autoGroupColumnDef ={}
          onFilterChanged={()=>{
              onFilterChanged()
          }}
          enableAdvancedFilter={true}
          grandTotalRow={"top"}
              ref={gridRef}
              rowData={data}
              // sideBar={true}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              rowSelection="multiple"
              // columnsi
              rowDragManaged={true}
              tooltipShowDelay={{tooltipShowDelay: 2}}
              onSelectionChanged={onSelectionChanged}
              rowMultiSelectWithClick={true}
              // ref={gridRef}
              isRowSelectable={isRowSelectable}
        // pagination={true}
        // quickFilterText={quickSearchValue}


            />

        </div>
        </>
      }
    </div>
  )
}

export default Logs
