import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Container, Row, Col } from "reactstrap";
import "./index.css";
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
import * as XLSX from 'xlsx';

import axios from "axios";
import Lottie from "lottie-react";
import { ReactTabulator } from "react-tabulator";
import { toast } from "react-toastify";
import { Button, Input, Modal, Select, Space, Spin, Table } from "antd";
import { AiOutlinePlusCircle, AiOutlineSearch } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { Loader } from "rsuite";

const Dashboard = () => {
  const [showHistoyModel,setShowHistoyModel]=useState(false);
  const gridStyle = useMemo(() => ({ height: "100vh" }), []);
  const [selectedemployee, setselectedemployee] = useState("");
  const [newStatus,setNewStatus]=useState('interested')
  const [unAssLoading,setUnAssLoading]=useState(false)
  const [employeessdata, setemployeessdata] = useState([]);
  const [exportLoading,setExportLoading]=useState(false)
  // const [searchLoading,setSearchLoading]=useState(false)
  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) ,
        Number(dateParts[0]),
      );
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 3000,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };
  const rowMoved = (row) => {
    console.log("Row moved:", row.getData());
  };
  const gridRef = useRef();

  const [showModal,setShowModal]=useState(false)
  const [selectedRows,setSelectedRows]=useState([]);
  const [filteredRowCount,setFilteredRowCount]=useState([])
  const [disConf,setDisConf]=useState(true)

  const [projectInterested,setProjectInterested]=useState([]);
  const [selectedAll,setSelectedAll]=useState(false)
  const  [selectedSale,setSelectedSale]=useState('');
  const [selectedAgents,setSelectedAgents]=useState([])
  const [selectedStatuses,setSelectedStatuses]=useState([]);
  const [showCalls, setShowCalls] = useState(false);
  const [callLoading, setCallLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [page, setPage] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const [assignloading, setassignloading] = useState(false);
  const [projects,setProjects]=useState([]);
  const [calls, setCalls] = useState([]);
  const [coulmns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [rowData, setRowData] = useState({});
  const [sales,setSales]=useState([]);
  const [newCall, setNewCall] = useState({
    feed_back: "",
    duration: "",
  });

  const copyContent = async (phone) => {
    try {
      await navigator.clipboard.writeText(phone);
      console.log("Content copied to clipboard");
      toast.success("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const phone1lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");

    const br = document.createElement("br");
    const img = document.createElement("img");
    img.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018846/logos--whatsapp-icon_o48mcw.svg";
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement("span");
    phone.innerText = rowData.phone;
    div.appendChild(phone);
    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018964/fa-regular--copy_flp0st.svg";
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(
        `whatsapp://send?text=!&phone=+2${
          rowData.phone.startsWith("0") ? rowData.phone : `${rowData.phone}`
        }`,
        "_blank"
      );
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement("div");
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    // div.appendChild(imgs_div)

    if (rowData?.phone != null && rowData?.phone != "") {
      div.appendChild(imgs_div);
      div.appendChild(br);
      div.appendChild(phone);
    }

    div.style.display = "flex";
    div.style.alignItems = "center";
    div.style.justifyContent = "space-between";
    imgcopy.addEventListener("click", function () {
      // console.log(rowData)
      copyContent(rowData.phone);
    });
    return div;
  };

  const phone2lead = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");

    const img = document.createElement("img");
    img.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018846/logos--whatsapp-icon_o48mcw.svg";
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";

    const phone = document.createElement("span");
    phone.innerText = rowData.phone2;

    // div.appendChild(img);

    const imgcopy = document.createElement("img");

    imgcopy.src =
      "https://res.cloudinary.com/duovxefh6/image/upload/v1720018964/fa-regular--copy_flp0st.svg";
    imgcopy.alt = "copy";
    imgcopy.style.width = "30px";
    imgcopy.style.height = "30px";
    imgcopy.style.cursor = "pointer";

    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(
        `whatsapp://send?text=!&phone=+2${
          rowData.phone2.startsWith("0") ? rowData.phone2 : `${rowData.phone2}`
        }`,
        "_blank"
      );
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });
    imgcopy.addEventListener("click", () => {
      console.log(rowData, "copy");
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.phone.startsWith("0")?rowData.phone:`0${rowData.phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    const imgs_div = document.createElement("div");
    imgs_div.appendChild(imgcopy);
    imgs_div.appendChild(img);
    // console.log(rowData?.phone2)
    if (rowData?.phone2 != null && rowData?.phone2 != "") {
      div.appendChild(imgs_div);
      div.appendChild(phone);
    }
    div.style.display = "flex";
    div.style.alignItems = "center";
    div.style.justifyContent = "space-between";
    imgcopy.addEventListener("click", function () {
      console.log(rowData);
      copyContent(rowData.phone);
    });
    return div;
  };
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) + 0; // Get the row position and add 1 to make it 1-based
  };


  const handleGetCalls = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/new_client_calls.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCalls(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };
  const onFilterChanged = (params) => {
    const rowCount = params.api.getDisplayedRowCount();
    setFilteredRowCount(rowCount);
  };
  const handleGetComments = async (id) => {
    setCallLoading(true);
    const data_send = {
      client_id: id,
    };
    await axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/get_client_comments.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res.message);
        if (res.status == "success") {
          if (Array.isArray(res.message)) {
            setCommentsData(res.message);
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setCallLoading(false);
      });
  };
  const callField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    const btn = document.createElement("button");
    btn.innerText = "Calls";
    btn.style.color = "white";
    btn.style.backgroundColor = "#0b5ed7";
    btn.style.border = "none";
    btn.style.padding = "10px";
    btn.style.borderRadius = "4px";
    btn.addEventListener("click", function () {
      console.log(rowData);
      handleGetCalls(rowData?.client_id);
      setRowData(rowData?.client_id);
      setShowCalls(true);
    });
    div.appendChild(btn);

    return div;
  };

  const commentField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.classList?.add("columnDiv")
    const btn = document.createElement("button");
    const btn2 = document.createElement("button");
    btn.innerText = "Comments";
    btn2.innerText = "Profile";
    btn2.style.color = "white !important";
    btn2.style.backgroundColor = "#0b5ed7";
    btn2.style.border = "none";
    btn2.style.padding = "10px";
    btn2.style.borderRadius = "4px";
    btn.style.color = "white !important";
    btn.style.backgroundColor = "#0b5ed7";
    btn.style.border = "none";
    btn.style.padding = "10px";
    btn.style.borderRadius = "4px";
    btn.addEventListener("click", function () {
      handleGetComments(rowData?.client_id);
      setRowData(rowData?.client_id);
      setClientId(rowData);
    });
    div.appendChild(btn);
    div.appendChild(btn2);
    btn2.addEventListener("click", function () {
      window.location.href = "/UserProfile/" + rowData?.client_id;
    });
    return div;
  };
  var headerMenu = function () {
    var menu = [];
    var columns = this.getColumns();

    for (let column of columns) {
      //create checkbox element using font awesome icons
      let icon = document.createElement("i");
      icon.classList.add("fas");
      icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

      //build label
      let label = document.createElement("span");
      let title = document.createElement("span");

      title.textContent = " " + column.getDefinition().title;

      label.appendChild(icon);
      label.appendChild(title);

      //create menu item
      menu.push({
        label: label,
        action: function (e) {
          //prevent menu closing
          e.stopPropagation();

          //toggle current column visibility
          column.toggle();

          //change menu item icon
          if (column.isVisible()) {
            icon.classList.remove("fa-square");
            icon.classList.add("fa-check-square");
          } else {
            icon.classList.remove("fa-check-square");
            icon.classList.add("fa-square");
          }
        },
      });
    }

    return menu;
  };

  const selectForm2 = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const check_box = document.createElement("input");
    check_box.type = "checkbox";
    check_box.checked = rowData?.check;
    check_box.style.width = "20px";
    check_box.style.height = "20px";
    check_box.style.cursor = "pointer";
    check_box.addEventListener("click", function () {
      setSearchData(
        searchData.map((item) => {
          return {
            ...item,
            check:
              item.client_id == rowData.client_id ? !item.check : item.check,
          };
        })
      );

    });

    return check_box;
    // check_box.
  };

  const [historyLoading,setHistoryLoading]=useState(false)
  const [ClientHistory,setClientHistory]=useState([])
  const handleGetHistoy=(id)=>{
    setHistoryLoading(true)
    const data_send={
      client_id:id,
    }
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/get_client_history.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=='success'){
        if(Array.isArray(res.message)){
          setClientHistory(res.message)
        }
      }
    console.log(res)

    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setHistoryLoading(false)
    })
  }
  const updateColumnTitles = (dataLength) => {
    const updatedColumns = [
      {
        title: "No",
        formatter: rowNumFormatter,
        width: 50,
        hozAlign: "center",
        headerMenu,
      }, // Row number column
      {
        title: "Name",
        field: "name",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      // {
      //   title: "client_id",
      //   field: "client_id",
      //   sorter: "client_id",
      //   headerFilter: "input",
      //   headerMenu,
      // },
      {
        title: "select",
        field: "client_id",
        formatter: selectForm2
      },
      {
        title: "Status",
        field: "status",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Last Action",
        field: "last_action_date",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Time To Call",
        field: "time_to_call",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Phone",
        field: "phone",
        formatter: phone1lead,
        sorter: "string",
        headerFilter: "input",
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      {
        title: "Phone2",
        field: "phone2",
        formatter: phone2lead,
        sorter: "string",
        headerFilter: "input",
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      {
        title: "Address",
        field: "address",
        sorter: "string",
        headerFilter: "input",
        headerMenu,
      },
      {
        title: "Added Date",
        field: "added_at",
        sorter: "date",
        sorterParams: { format: "YYYY-MM-DD" },
        headerFilter: "date",
        headerMenu,
      },
      // { title: 'Action Number', field: 'call_number', sorter: 'number', headerFilter: "input" },
      {
        title: "Calls",
        field: "phone",
        formatter: callField,
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      {
        title: "Comments",
        field: "phone",
        formatter: commentField,
        headerFilterPlaceholder: "phone",
        headerMenu,
      },
      // { title: 'select', field: 'client_id',formatter:selectForm,width:30 },
    ];
    setColumns(updatedColumns);
  };



  const agentField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.name;
    return div;
  };

  const agentEmailField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.user_email;
    return div;
  };

  const agentEmailPhone = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.phone;
    return div;
  };

  // const getSales=()=>{
  //   axios.get('https://ahmed.its.ae/new_quad_wall/admin/select_selles.php')
  //   .then((res)=>{
  //     console.log(res)
  //     if(Array.isArray(res.message)){
  //       setSales(res.message)
  //     }
  //   })
  //   .catch(e=>console.log(e))
  // }

  const callColumns = [
    {
      title: "No",
      formatter: rowNumFormatter,
      width: 50,
      hozAlign: "center",
      headerMenu,
    }, // Row number column
    {
      title: "feed_back",
      field: "feed_back",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    {
      title: "duration",
      field: "duration",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    {
      title: "Last Action Date",
      field: "date",
      sorter: "date",
      sorterParams: { format: "YYYY-MM-DD" },
      headerFilter: "date",
      headerMenu,
    },
    {
      title: "Agent Name",
      field: "agent",
      formatter: agentField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Email",
      field: "agent",
      formatter: agentEmailField,
      sorter: "string",
      headerMenu,
    },
    {
      title: "Agent Phone",
      field: "agent",
      formatter: agentEmailPhone,
      sorter: "string",
      headerMenu,
    },
  ];
  const [oldData, setOldData] = useState(null);
  const [openComment, setOpenNewCommect] = useState(false);
  const commentColumns = [
    // Row number column
    {
      title: "Comment",
      field: "comment",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },

    // {
    //   title: "Update",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // ,
    // {
    //   title: "duration",
    //   field: "duration",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    // {
    //   title: "Last Action Date",
    //   field: "date",
    //   sorter: "date",
    //   sorterParams: { format: "YYYY-MM-DD" },
    //   headerFilter: "date",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Name",
    //   field: "agent",
    //   formatter: agentField,
    //   sorter: "string",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Email",
    //   field: "agent",
    //   formatter: agentEmailField,
    //   sorter: "string",
    //   headerMenu,
    // },
    {
      title: "Actions",
      field: "update",
      formatter: (cell) => {
        const row = cell.getRow();
        const rowData = row.getData();
        const div = document.createElement("div");
        const btn = document.createElement("button");
        btn.innerText = "Update";
        const btn2 = document.createElement("button");
        btn2.innerText = "Delete";
        btn.addEventListener("click", () => {
          const value = cell.getValue();
          setOldComment(rowData);
          setOldData(rowData);
        });
        btn2.addEventListener("click", () => {
          setDeleteComment((prev) => ({ ...prev, rowData }));
          handleDeleteComment(rowData?.comment_id);
        });
        div.appendChild(btn);
        div.appendChild(btn2);
        return div;
      },
      sorter: "string",
      headerMenu,
    },
  ];
  const options = {
    initialSort: [
      { column: "age", dir: "asc" }, // sort by age in ascending order
    ],
    movableRows: true,
    movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  const authUser = localStorage.getItem("authUser");
  let uData = JSON.parse(authUser);
  const [pageLoading, setPageloading] = useState(false);
  const [showResult, setShowResult] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [statisticsData, setStatisticsData] = useState({});
  const [searchData, setSearchData] = useState([]);
  const getStatistics = () => {
    setPageloading(true);
    const data_send = {
      agent_id: uData?.agent_id,
      type: uData?.user_type,
      page: page,
    };
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/op_leader_statistics.php",
        data_send
      )
      .then((res) => {
        console.log(res, "resres");
        if (res.status == "success") {
          setStatisticsData(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageloading(false);
      });
  };

  const handleSearch2 = (txt='', txtPage) => {
    if(txt==null){
      txt=''
    }
    console.log(txt.split('\n').join('-'))
    let original_txt=txt.split('\n').join('-');
    // console.log(txt.split("/\n"))
    // console.log(txt.replace("/\n",'-'))
    // let arrString=txt.split("/\n")[0];
    // console.log(arrString)
    // let originalTxt=arrString.replace('/\n',',');
    // console.log(originalTxt)
    // return
    if (txt == "") {
      setShowResult(false);
      // return;
    }
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);

      newParams.set("page", txtPage);
      return newParams;
    });
    setSearchLoading(true);
    // alert(txtPage)
    const data_send = {
      search_text: original_txt,
      page: txtPage,
      type:uData?.user_type
    };
    console.log(data_send)
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/search_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == "success") {
          setParams((prevParams) => {
            const newParams = new URLSearchParams(prevParams);
            newParams.set(
              "tb",

              res.total_pages
            );

            newParams.set(
              "page",

              txtPage
            );
            return newParams;
          });
          if (Array.isArray(res.message)) {
            setSearchData(res.message.map((item)=>{
              return{...item,check:false}
            }));
          }
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setSearchLoading(false);
      });
  };

  const [comment, setComment] = useState("");
  const [commentsData, setCommentsData] = useState([]);
  const [ClientId, setClientId] = useState(null);

  const handleAddCall = () => {
    // setAddLoading(true)
    const data_send = {
      ...newCall,
      client_id: rowData,
      agent_id: uData?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/add_new_call.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetCalls(rowData);
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  const [params, setParams] = useSearchParams();

  const handleAddComment = () => {
    // setAddLoading(true)
    const data_send = {
      comment: comment,
      client_id: ClientId?.client_id,
      agent_id: uData?.agent_id,
    };
    console.log(data_send);
    // return ;
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/insert_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setNewCall({ duration: "", feed_back: "" });
          handleGetComments(rowData);
          setShowAddModal(false);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const [oldComment, setOldComment] = useState(null);
  const [deleteComment, setDeleteComment] = useState(null);
  const handleUpdateComment = () => {
    // setAddLoading(true)
    const data_send = {
      ...oldData,
    };
    // return ;
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/update_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setOldComment(null);
          handleGetComments(rowData);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const handleDeleteComment = (comment_id) => {
    // setAddLoading(true)
    const data_send = {
      comment_id,
    };
    // return ;
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/delete_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setDeleteComment(null);
          handleGetComments(rowData);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const getProjects=()=>{
    axios.get("https://ahmed.its.ae/new_quad_wall/admin/projects_interested.php")
    .then((res)=>{
      console.log(res.message)
      if(res.status=='success'){
        if(Array.isArray(res.message)){
          setProjects(res.message)
        }
      }
    })
    .catch(e=>console.log(e))
  }
  const geteployees = () => {
    axios
      .get(
        "https://ahmed.its.ae/new_quad_wall/admin/select_selles.php"
      )
      .then((res) => {
        //console.log(res)
        //console.log(res);
        setemployeessdata(res.message);
        setselectedemployee(res?.message[0]?.agent_id);
        //console.log(res?.message[0]?.name);
      });
  };
  const onExportClick = () => {
    // gridApi.api.exportDataAsCsv();
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    const selectedData = selectedNodes.map(node => node.data);
    console.log(selectedData)
    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    selectedData.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Client name': item.name || '',
          'Client phone': item.phone || '',
          'Client phone2': item.phone2 || '',
          'Client project_intrested_in': item.project_intrested_in || '',
          'Client time_to_call': item.time_to_call || '',
          'Client unitcode': item.unitcode || '',
          'Client client_type': item.client_type || '',
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          'Client job': item.job || '',
          'Client address': item.address || '',
          'agent name': item?.agent_name || '',
          'agent Email': item?.agent_email|| '',
          'agent Phone': item?.agent_phone || '',
          'feed_back':item.feed_back,
          // 'Client status': item.status || '',
          // 'Client status label': item.status_label || '',
          "creation_date":moment(item.added_at).format("L"),
          "assign_date":moment(item.assign_date).format("L"),
          "last_action_date":moment(item.last_action_date).format("L"),
          "markter name":item.marketing_name,
          "last_feed_call":item.last_feed_call,

        };
        pp.push(newobj);
      }
    });
    if (selectedData.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);
    let allData = [...data];
    setData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };
  useEffect(() => {
    getStatistics();
    getProjects()
    geteployees()
    // getSales()
  }, []);

  useEffect(() => {
    updateColumnTitles(searchData.length);
  }, [searchData]);

  const handlePageChange = (increment) => {
    let newPage = null;
    setParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      const currentPage = parseInt(params.get("page")) || 1;
      const totalPages = parseInt(params.get("tb")) || 1;
      newPage = currentPage;

      if (increment) {
        newPage = currentPage < totalPages ? currentPage + 1 : currentPage;
      } else {
        newPage = currentPage > 1 ? currentPage - 1 : currentPage;
      }

      newParams.set("page", newPage);
      return newParams;
    });

    handleSearch2(params.get("searchValue"), newPage);
  };

  const [filterData, setFilterData] = useState({
    agent_id: null,
    client_type: null,
    date: null,
    client_status: null,
    to_date:null
  });
  const [agents, setAgents] = useState([]);
  const handlegetSalles = () => {
    axios
      .get(
        "https://ahmed.its.ae/new_quad_wall/admin/select_selles.php"
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          setAgents(res?.message);
          if(res.message.length>0){
            setSelectedSale(res.message[0].agent_id)
          }
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  useEffect(() => {
    handlegetSalles();
  }, []);
  const resetFilters = () => {
    setFilterData({
      date: "",
      client_type: "",
      client_status: "",
      agent_id: "",
    });
  };

  const [statsFilteredData, setStatsFilteredData] = useState([]);
  const getFilteredData = () => {
    setPageloading(true);
    let agentsTxt=selectedAgents.join(',');
    console.log(agentsTxt)
    console.log(selectedStatuses.length)
    // let agsArr=[...selectedAgents];
    // for(let i=0;i<)
    const data_send = {
      agent_id: uData?.agent_id,
      type: uData?.user_type,
      page: page,
      projectInterested:projectInterested.length>0?projectInterested.join(','):null,
      client_statuses:selectedStatuses.length>0?selectedStatuses.join(','):null,
      agentsTxt,
    };
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/filter_clients.php",
        {
          ...filterData,
          projectInterested:projectInterested.length>0?projectInterested.join(','):null,
          client_statuses:selectedStatuses.length>0?selectedStatuses.join(','):null,
          agentsTxt
        }
      )
      .then((res) => {
        console.log(res, "resres");
        if (res.status == "success") {
          setStatsFilteredData(res.message);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setPageloading(false);
      });
  };

  const assignfunction = () => {
    let selected = [...searchData];
    let checddata = selected.filter((item) => item.check == true);
    // console.log(checddata)
    // return
    let selectedtxt = "";
    if (selectedSale == "") {
      toast.warn("select at least one employee");
    }
    for (let i = 0; i < checddata.length; i++) {
      if (i == 0) {
        selectedtxt += checddata[i].client_id;
      } else {
        selectedtxt += "**UAD**" + checddata[i].client_id;
      }
    }
    const data_send = {
      client_id_data: selectedtxt,
      agent_id: selectedSale,
    };
    // console.log(data_send);
    // return
    setassignloading(true);
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/assign_agent_to_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status === "success") {
          let alldata = [...data];
          alldata.map((item) => {
            return { ...item, check: false };
          });
          //retnotchecked();
          setData(alldata);

          toast.success(res.message);
          handleSearch2(params.get("searchValue"), 1);
          setSelectedAll(false)
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("something is error try again");
        }
      })
      .finally(() => {
        setassignloading(false);
      });
  };

  useEffect(()=>{
    setSearchData(searchData.map((item)=>{
      return{...item,check:selectedAll?true:false}
    }))
    // updateColumnTitles(searchData.length)
  },[selectedAll])


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<AiOutlineSearch/>}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <AiOutlineSearch
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleExport = () => {
    const pp = [];
    searchData.map((item, index) => {
      if (item.check === true) {
        console.log('dsd');
        let newobj = {
          'Cleint name': item.name || '',
          'Cleint phone': item.phone || '',
          'Cleint phone2': item.phone2 || '',
          'Cleint project_intrested_in': item.project_intrested_in || '',
          'Cleint time_to_call': item.time_to_call || '',
          'Cleint unitcode': item.unitcode || '',
          'Cleint client_type': item.client_type || '',
          'Cleint job': item.job || '',
          'Cleint address': item.address || '',
          'feed_back':item.feed_back,
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          "creation_date":moment(item.added_at).format("L"),
          "assign_date":moment(item.assign_date).format("L"),
          "last_action_date":moment(item.last_action_date).format("L"),
          "markter name":item.marketing_name,
          "last_feed_call":item.last_feed_call,
        };
        pp.push(newobj);
        let sales=[...item?.sales];
        for(let i=0;i<sales?.length;i++){
          console.log(sales)
          let p=document.createElement('p');
          p.innerText=sales[i].name;
          newobj[`agent Name ${i+1}`]=sales[i].name
          newobj[`agent Email ${i+1}`]=sales[i].user_email
          newobj[`agent Phone ${i+1}`]=sales[i].phone
          // divNames.appendChild(p)
        }
      }

    });
    if (pp.length == 0) {
      toast.warn('إختر عناصر أولا');
      return;
    }
    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    setSelectedAll(false);
    let allData = [...searchData];
    setSearchData(
      allData.map(item => {
        return { ...item, check: false };
      }),
    );
    // XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    // XLSX.writeFile(wb, 'MyExcel.XLSX');
  };

  const renderusers = [
    {
      title: 'Number',
      key: 'number',
      width: '10%',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Select',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <input onClick={()=>{
              setSearchData(searchData.map((item)=>{
                return {...item,check:item.client_id==record.client_id?!record.check:item.check}
              }))
            }} checked={record.check} type="checkbox" style={{ width:'15px',height:'15px' }}/>
          </div>
        </Space>
        )
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '30%',
      ...getColumnSearchProps('name'),
    },

    {
      title: 'status',
      dataIndex: 'status',
      key: 'status',
      width: '20%',
      ...getColumnSearchProps('status'),
    },
    // {
    //   title: 'Last Action',
    //   dataIndex: 'last_action_date',
    //   key: 'last_action_date',
    //   width: '20%',
    //   ...getColumnSearchProps('last_action_date'),
    // },
    {
      title: 'Time To Call',
      dataIndex: 'time_to_call',
      key: 'time_to_call',
      width: '20%',
      ...getColumnSearchProps('time_to_call'),
    },
    {
      title: 'phone',
      dataIndex: 'phone',
      key: 'phone',
      width: '20%',
      render: (_, record) => (
        <Space>
        <div>
          {
            record.phone!=null&&record.phone!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${record.phone.startsWith("0")?record.phone:`${record.phone}`}`,'_blank')
              }} src={require("../../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(record.phone)
              }} src={require("../../assets/images/copy.png")} alt="" />
              <p>{record.phone}</p>
            </div>
          }
        </div>
      </Space>
        )
    },
    {
      title: 'phone2',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
        <div>
          {
            record.phone2!=null&&record.phone2!=""&&
            <div>
                <img style={{width:'30px'}} onClick={()=>{
                      window.open(`whatsapp://send?text=!&phone=+2${record.phone2.startsWith("0")?record.phone2:`${record.phone2}`}`,'_blank')
              }} src={require("../../assets/images/whats.png")} alt="" />
              <img style={{width:'30px'}} onClick={()=>{
                      copyContent(record.phone2)
              }} src={require("../../assets/images/copy.png")} alt="" />
              <p>{record.phone2}</p>
            </div>
          }
        </div>
      </Space>
        )
    },
    {
      title: 'Sale Name',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.name}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Sale Email',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.user_email}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Sale phone',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            {
              record?.sales&&record?.sales.map((item)=>{
                return (
                  <p>{item.phone}</p>
                )
              })
            }
          </div>
        </Space>
        )
    },
    {
      title: 'Calls',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
            <button onClick={()=>{
            handleGetCalls(record?.client_id);
            setRowData(record?.client_id);
              setShowCalls(true);
            }} className="btn btn-primary">
              Calls
            </button>
          </div>
        </Space>
        )
    },
    {
      title: 'Comments',
      dataIndex: 'phone2',
      key: 'phone2',
      width: '20%',
      render: (_, record) => (
        <Space>
          <div style={{ display: "flex", flexWrap: "wrap", width: "100%",rowGap:'5px' }}>
            <button onClick={()=>{
                handleGetComments(rowData?.client_id);
                setRowData(rowData?.client_id);
                setClientId(rowData);
            }} className="btn btn-primary">
              Comments
            </button>
            <button onClick={()=>{
                window.location.href = "/UserProfile/" + record?.client_id;
            }} className="btn btn-primary">
              Profile
            </button>

          </div>
        </Space>
        )
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      ...getColumnSearchProps('address'),
      sorter: (a, b) => a.address.length - b.address.length,
      sortDirections: ['descend', 'ascend'],
    },
  ];

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      editable: true,
      filter: true,
      floatingFilter: true,
      onCellValueChanged: (e) => console.log(e),
      // flex: 10,
      width:'fit-content'
    };
  }, []);

  const onGridReady = useCallback((params) => {
    fetch("https://www.ag-grid.com/example-assets/small-olympic-winners.json")
      .then((resp) => resp.json())
      .then((data) => {
        // setRowData(data);
      });
  }, []);
  const onSelectionChanged = (event) => {
    setSelectedRows(event.api.getSelectedRows())
    if(event.api.getSelectedRows().lengt>0){
      setDisConf(true);
    }
    else {
      setDisConf(false);
    }
  };
  const isRowSelectable = (node) => {
    return true; // or node.data.<column>
  };


  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "#",
      field: "rowNumber",
      valueGetter: (params) => params.node.rowIndex + 1, // This will give you the row number starting from 1
      filter: 'agSetColumnFilter',
      filterParams: {
        caseSensitive: true
      },
      aggFunc:"sum",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      cellStyle: (params) => ({
        backgroundColor: params.value === "Den" ? "#c3c3c3" : "#b7d8d6",
      }),
      cellClass: (params) =>
        params.value === "Den" ? "class_name" : "another_class_name",
      // tooltipField:"age",
      // headerComponentFramework: CustomHeader
    },
    {
      headerName: 'Name',
      field: "name",
      aggFunc:"sum",
      // tooltipField:"age",
    },
    {
      headerName: "project intrested in",
      aggFunc:"sum",
      field: "project_intrested_in",
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

            <p>{params.data&&params.data.project_intrested_in}</p>

        }
      </div>
      ),
      // tooltipField:"age",
      sortable: true,
    },
    {
      headerName: "",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params?.data&&params?.data?.phone!=null&&params?.data.phone!=""&&
          <div>
                <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params?.data&&params.data.phone)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone",
      field: "phone",
      aggFunc:"sum",

      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
        {
          params.data&&params.data.phone!=null&&params.data.phone!=""&&
          <div>
            <p>{params.data.phone}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "",
      field: "phone2",
      aggFunc:"sum",
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {console.log(params.data)}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
                          <img style={{width:'30px'}} onClick={()=>{
                    window.open(`whatsapp://send?text=!&phone=+2${params.data.phone.startsWith("0")?params.data.phone:`${params.data.phone}`}`,'_blank')
            }} src={require("../../assets/images/whats.png")} alt="" />
            <img style={{width:'30px'}} onClick={()=>{
                    copyContent(params.data.phone2)
            }} src={require("../../assets/images/copy.png")} alt="" />
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "phone2",
      field: "phone2",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {
          params.data&&params.data.phone2!=null&&params.data.phone2!=""&&
          <div>
            <p>{params.data.phone2}</p>
          </div>
        }
      </div>
      ),
    },

    {
      headerName: "Comments",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetComments(params?.data?.client_id);
                setRowData(params.data);
                console.log(params?.data)
                setClientId(params?.data);
              }}
            >Comments</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "Calls",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <div style={{ flexDirection:'column' }} className='d-flex flex-column gap-3'>
            <button className="btn btn-primary"
              onClick={()=>{
                handleGetCalls(params.data?.client_id)
                setRowData(params.data)
                setShowCalls(true)
              }}
            >Calls</button>

        </div>
      ),
      sortable: false,
    },
    {
      headerName: "status",
      field: "status",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

          <div>
            <p>{params?.data?.status}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "status label",
      field: "status_label",
      aggFunc:"sum",
      sortable: true,
      // hide: true,
      cellRenderer: (params) => (
        <div>
          {/* {console.log(params.data)} */}
        {

          <div>
            <p>{params?.data?.status_label}</p>
          </div>
        }
      </div>
      ),
    },
    {
      headerName: "Profile",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                window.location.href = "/UserProfile/" + params?.data?.client_id;

              }}
            >Profile</button>
      ),
      sortable: false,
    },
    {
      headerName: "History",
      aggFunc:"sum",
      field: "acttion",
      cellRenderer: (params) => (
        <button className="btn btn-primary"
              onClick={()=>{
                setShowHistoyModel(true)
                console.log(params.data,"paramsparams")
                handleGetHistoy(params?.data?.client_id)
              }}
            >History</button>
      ),
      sortable: false,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
    },
    {
      headerName: "sale name",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.agent_name}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale email",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.agent_email}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "sale phone",
      field: "email",
      sortable: true,
      cellRenderer: (params) => (
        <div>
          {
            params?.data&&<div>
              <p>{params?.data?.agent_phone}</p>
            </div>
          }
        </div>
      ),
    },
    {
      headerName: "address",
      field: "address",
      sortable: true,
    },
    {
      headerName: "added at",
      field: "added_at",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last_action_date",
      field: "last_action_date",
      filter: 'agDateColumnFilter',
      filterParams:filterParams,
      cellEditorParams: {
        minDate: new Date('2000-01-01'), // Set the minimum date
        maxDate: new Date('2030-12-31')  // Set the maximum date
      },
      valueFormatter: params => {
        const date = new Date(params.value);
        return date.toLocaleDateString();
      },
      sortable: true,
    },
    {
      headerName: "last action date",
      field: "added_at",
      sortable: true,
      hide: true,
    },
    // {
    //   headerName: "Feed Back",
    //   field: "feed_back",
    //   sortable: true,
    // },
  ]);


  const reAssisgnFunc=()=>{
    let selected=[...data];
    let checddata=selectedRows;
    let selectedtxt="";
    if(selectedemployee==""){
      toast.warn("select at least one employee")
      return
    }
    for(let i=0;i<checddata.length;i++){
      if(i==0){
        selectedtxt+=checddata[i].client_id;
      }
      else {
        selectedtxt+="**UAD**"+checddata[i].client_id;
      }
    }
    const data_send={
      client_id_data:selectedtxt,
      agent_id:selectedemployee,
      operation_id:uData.agent_id
    }
    console.log(data_send);
    // return
    setassignloading(true);
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/reassign_agent_to_client.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(res.status==="success"){
        let alldata=[...data];
        alldata.map((item)=>{
          return {...item,check:false};
        })
        //retnotchecked();
        setData(alldata)
        setShowModal(false)
        toast.success(res.message);
        // getNotAnswer();
      }
      else if(res.status=="error"){
        toast.error(res.message);
      }
      else {
        toast.error("something is error try again")
      }
    }).finally(()=>{
      setassignloading(false);
    })
  }

  const unAssignCleints=()=>{
    console.log(data)
    setUnAssLoading(true)
    let selectedtxt='';
    const selected=selectedRows
    for(let i=0;i<selected.length;i++){
      if(i==0){
        selectedtxt+=selected[i].client_id;
      }
      else {
        selectedtxt+="**UAD**"+selected[i].client_id;
      }
    }
    if(selectedtxt==''){
      toast.warn('Select From Unassign To Make Request')
      return;
    }
    const data_send={
      client_id_data:selectedtxt,
    }
    console.log(data_send);
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/unassign_clients.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        setShowModal(false)

        // getNotAnswer()
        // setData(data.map((it)=>{
        //   return {...it}
        // }));
      }
      else if(res.status=='error') {
        toast.error(res.message)
      }
      else {
        toast.error(res.message)
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
    setUnAssLoading(false)
    })
  }
  const onExportClickAll = (dataLeads) => {
    // gridApi.api.exportDataAsCsv();

    const params = {
      fileName: 'selected_rows.csv',
      columnSeparator: ',',
      skipHeader: false,
      columnKeys: ['make', 'model', 'price']
    };
    const pp = [];
    dataLeads.map((item, index) => {
      if (true) {
        console.log('dsd');
        let newobj = {
          'Client name': item.name || '',
          'Client phone': item.phone || '',
          'Client phone2': item.phone2 || '',
          'Client project_intrested_in': item.project_intrested_in || '',
          'Client time_to_call': item.time_to_call || '',
          'Client unitcode': item.unitcode || '',
          'Client client_type': 'leads' || '',
          'Client status': item.status || '',
          'Client status label': item.status_label || '',
          'Client job': item.job || '',
          'Client address': item.address || '',
          'agent name': item?.agent_name || '',
          'agent Email': item?.user_email || '',
          'agent Phone': item?.agent_phone || '',
          'feed_back':item.status_feed_back,
          "creation_date":moment(item.added_at).format("L"),
          "last_action_date":moment(item.last_action_date).format("L"),
          "markter name":item.uploaded_by,
          "last_feed_call":item.last_feed_call,
        };
        pp.push(newobj);
      }
    });

    const ids = pp.join('&&');
    var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(pp);
    XLSX.utils.book_append_sheet(wb, ws, 'MySheet1');
    XLSX.writeFile(wb, 'MyExcel.XLSX');
    // setSelectAll(false);

    // gridRef.current.api.exportDataAsCsv(params, {
    //   rowData: selectedData
    // });
  };

  const handleExportFromBack=()=>{
    setExportLoading(true)
    axios.get("https://ahmed.its.ae/new_quad_wall/admin/dashboard_all_leads.php")
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        // window.open(res.message);
        onExportClickAll(res.data)
      }
      else if(res.status=='error'){
        toast.error('SomeThing Went Error');
      }
      else {
        toast.error('Something Went Error')
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setExportLoading(false)
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {pageLoading ? (
            <div
              style={{
                minHeight: "100vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie
                style={{ width: "40%" }}
                animationData={groovyWalkAnimation}
                loop={true}
              />

            </div>
          ) : (
            <>
              {" "}
              <div className="filter-container">
                <div className="filter-item">
                  <label htmlFor="date-filter">From Date</label>
                  <input
                    id="date-filter"
                    type="date"
                    value={filterData.date}
                    onChange={(e) =>
                      setFilterData({ ...filterData, date: e.target.value })
                    }
                  />
                </div>
                <div className="filter-item">
                  <label htmlFor="date-filter">To Date</label>
                  <input
                    id="date-filter"
                    type="date"
                    value={filterData.to_date}
                    onChange={(e) =>
                      setFilterData({ ...filterData, to_date: e.target.value })
                    }
                  />
                </div>
                <div className="filter-item">
                  <label htmlFor="client-type-filter">Client Type</label>
                  <select
                    id="client-type-filter"
                    value={filterData.client_type}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        client_type: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Type</option>
                    {[
                      { id: "1", type: "cold" },
                      { id: "2", type: "leads" },
                    ].map((item) => {
                      return (
                        <option key={item.id} value={item.type}>
                          {item.type}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="filter-item">
                  <label htmlFor="client-status-filter">Client Status</label>
                  <Select
                    mode="multiple"
                    isMulti
                    name="colors"
                    value={selectedStatuses}
                    onChange={(e)=>{
                      console.log(e,"ewwel;")
                      setSelectedStatuses(e)
                    }}
                    options={[
                      { value: "unassigned", label: "unassigned" },
                      { value: "assigned", label: "assigned" },
                      { value: "pending", label: "pending" },
                      { value: "interested", label: "interested" },
                      { value: "not_interested", label: "not_interested" },
                      { value: "low_budget", label: "low_budget" },
                      { value: "no_answer", label: "no_answer" },
                      { value: "call_back", label: "call_back" },
                      { value: "Re_assign", label: "Re_assign" },
                    ]}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  {/* <select
                    id="client-status-filter"
                    value={selectedStatuses}
                    onChange={(e) => {
                      // setFilterData({
                      //   ...filterData,
                      //   client_status: e.target.value,
                      // });
                      setSelectedStatuses(e)
                    }}
                  >
                    <option value="">Select Status</option>
                    {[
                      { id: "1", type: "unassigned" },
                      { id: "2", type: "assigned" },
                      { id: "3", type: "pending" },
                      { id: "4", type: "interested" },
                      { id: "5", type: "not_interested" },
                      { id: "6", type: "low_budget" },
                      { id: "7", type: "no_answer" },
                      { id: "8", type: "call_back" },
                      { id: "9", type: "Re_assign" },
                    ].map((item) => {
                      return (
                        <option key={item.id} value={item.type}>
                          {item.type}
                        </option>
                      );
                    })}
                  </select> */}
                </div>
                <div className="filter-item">
                  <label htmlFor="agent-filter">Agent</label>
                  {/* <select
                    id="agent-filter"
                    value={filterData.agent_id}
                    onChange={(e) => {
                      setFilterData({
                        ...filterData,
                        agent_id: e.target.value,
                      });
                    }}
                  >
                    <option value="">Select Agent</option>
                    {agents &&
                      agents.length &&
                      agents.map((item) => {
                        return (
                          <option key={item.agent_id} value={item.agent_id}>
                            {item.name}
                          </option>
                        );
                      })}
                  </select> */}
                  {/* <Select
                    value={selectedAgents}
                    isMulti
                    onChange={(e)=>{
                      console.log(e)
                      setSelectedAgents(e)
                    }}
                    name="colors"
                    options={agents&&agents.map((item)=>{
                      return {label:item.name,value:item.agent_id}
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  /> */}

                  <Select
                    mode="multiple"
                    isMulti
                    name="colors"
                    value={selectedAgents}
                    onChange={(e)=>{
                      console.log(e,"ewwel;")
                      setSelectedAgents(e)
                    }}
                    options={agents.map((item,index)=>{
                      return {label:item.name,value:item.agent_id}
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />

                </div>
                <div className="filter-item">
                  <label htmlFor="agent-filter">Project Interested</label>
                  {/* <Select
                    className="basic-single"
                    classNamePrefix="select"
                    // isSearchable={false}
                    name="color2"
                    options={
                      projects&&projects.map((item)=>{
                        return {label:item.project_intrested_in,value:item.project_intrested_in}
                      })
                    }
                  /> */}
                  <Select
                  mode="multiple"
                  isMulti
                    name="colors2"
                    isSearchable={true}
                    value={projectInterested}
                    // value={selectedAgents}
                    onChange={(e)=>{
                      setProjectInterested(e);
                    }}
                    options={
                      projects&&projects.map((item)=>{
                        return {label:item.project_intrested_in,value:item.project_intrested_in}
                      })
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
                <br />
                <div className="filter-buttons">
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      getFilteredData(filterData);
                    }}
                  >
                    Apply Filters
                  </button>
                  <button className="btn btn-danger" onClick={resetFilters}>
                    Reset Filters
                  </button>
                </div>
              </div>
              <Row
                style={{ backgroundColor: "white" }}
                className={
                  "d-flex p-3 row_div bg-light row align-items-center gap-2 flex-wrap"
                }
              >
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 d-flex flex-column justify-content-center p-2 text-center col-md-3 col-sm-6 bg-light"
                >
                  <h4>Colds</h4>
                  <p>{statisticsData?.colds??0}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 d-flex flex-column justify-content-center p-2 text-center col-md-3 col-sm-6 bg-light"
                >
                  <h4>Leads</h4>
                  <p>{statisticsData?.leads}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Calls Number</h4>
                  <p>{statisticsData?.calls_number}</p>
                </div>
                {/* <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Calls Number</h4>
                  <p>{statisticsData?.calls_number}</p>
                </div> */}
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>No Answer</h4>
                  <p>{statisticsData?.no_answer}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Interested</h4>
                  <p>{statisticsData?.interested}</p>
                </div>
                <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Not Interested</h4>
                  <p>{statisticsData?.not_interested}</p>
                </div>
                <div className="my-2 text-center p-4">
                  <button disabled={exportLoading} onClick={()=>{
                    handleExportFromBack()
                  }} className="btn btn-success">Export All Leads</button>
                </div>
                {/* <div
                  style={{ backgroundColor: "white" }}
                  className="col-lg-4 p-2 d-flex flex-column justify-content-center text-center col-md-3 col-sm-6  bg-light"
                >
                  <h4>Delay</h4>
                  <p>{statisticsData?.delay}</p>
                </div> */}
              </Row>
              <Row className={"row_div2 my-4 p-3"}>

                <div className="form-control">
                  <button className="btn btn-success mx-2 my-2 ">name</button>
                  <button className="btn btn-primary my-2  ">phone</button>
                  <div className="row">
                    <textarea
                      placeholder="Search For Client"
                      value={params?.get("searchValue")}
                      onChange={(e) => {
                        setShowResult(true);
                        setParams((prevParams) => {
                          const newParams = new URLSearchParams(prevParams);
                          newParams.set("searchValue", e.target.value);
                          return newParams;
                        });
                      }}
                      className="w-100 textareat_search" name="" id=""></textarea>
                    {/* <input
                      value={params?.get("searchValue")}
                      onChange={(e) => {
                        setShowResult(true);
                        setParams((prevParams) => {
                          const newParams = new URLSearchParams(prevParams);
                          newParams.set("searchValue", e.target.value);
                          return newParams;
                        });
                      }}
                      className="w-100"
                      type="text"
                      placeholder="Search For Client"
                    /> */}
                  </div>
                  <button
                    disabled={searchLoading}
                    className="btn btn-success my-2"
                    onClick={() => {
                      handleSearch2(params.get("searchValue"), 1);
                    }}
                  >
                    {
                      searchLoading?
                        <Spin/>
                      :'Search'
                    }
                  </button>
                </div>

                {searchData && searchData?.length && (
                  <div className="my-4">
                    {searchLoading ? (
                      <div
                        style={{
                          minHeight: "40vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Lottie
                          style={{ width: "40%" }}
                          animationData={groovyWalkAnimation}
                          loop={true}
                        />
                      </div>
                    ) : (
                      <div>
                        <div className="my-2 d-flex gap-2 align-items-center">
                          <h4 style={{margin:'0px'}}>Select All</h4>
                          <input style={{width:'15px',height:'15px'}} checked={selectedAll} type="checkbox" onClick={()=>{
                            setSelectedAll(!selectedAll)
                          }}/>
                        </div>
                        <div className="my-2">
                  {
                    searchData&&searchData?.length>0&&
                    <>
                      <label htmlFor="">Sales</label>
                <Select
                    style={{width:'100%'}}
                    value={selectedSale}
                    isMulti
                    onChange={(e)=>{
                      setSelectedSale(e)
                    }}
                    name="colors"
                    options={agents&&agents.map((item)=>{
                      return {label:item.name,value:item.agent_id}
                    })}
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                  <button disabled={assignloading} onClick={()=>{
                    assignfunction()
                  }} className="btn btn-primary my-2">  {assignloading ? <Spin /> : "assign"}</button>
                    </>
                  }
                </div>
                        {/* <ReactTabulator
                          data={searchData}
                          options={options}
                          // sortOrderReverse={true}
                          columns={coulmns}
                          layout={"fitData"}
                        /> */}
                        <div className="my-3">
                        <button
                          onClick={() => {
                            handleExport();
                          }}
                          className="btn btn-primary text-light"
                        >
                          Export
                        </button>
                      </div>
                              <div style={{ overflow:'auto' }}>
                              <Table style={{ marginTop:'10px' }} dataSource={searchData} columns={renderusers} />
                              </div>
                        <div className="pagination my-4">
                          <button
                            className="btn btn-danger mx-2"
                            onClick={() => handlePageChange(false)}
                          >
                            Prev
                          </button>
                          <button
                            className="btn btn-success"
                            onClick={() => handlePageChange(true)}
                          >
                            Next
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </Row>
            </>
          )}
        </Container>
      </div>

      <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Calls"
        open={showCalls}
        onOk={() => {
          setShowCalls(false);
        }}
        onCancel={() => {
          setShowCalls(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />

          </div>
        ) : (
          <>
            <div
              onClick={() => {
                setShowAddModal(true);
              }}
              style={{ width: "fit-content", cursor: "pointer" }}
              className="my-2 gap-2 d-flex align-items-center "
            >
              <AiOutlinePlusCircle
                className="text-success"
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
              <h4>New Call</h4>
            </div>
            <ReactTabulator
              data={calls}
              options={options}
              columns={callColumns}
              layout={"fitData"}
            />
          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw" }}
        title="Client Calls"
        open={showAddModal}
        onOk={() => {
          // setShowAddModal(false)
          handleAddCall();
        }}
        onCancel={() => {
          setShowAddModal(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              FeedBack
            </label>
            <input
              value={newCall?.feed_back}
              onChange={(e) => {
                setNewCall({ ...newCall, feed_back: e.target.value });
              }}
              className="w-100"
              type="text"
              placeholder="FeedBack"
            />
          </div>
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Call Duration
            </label>
            <input
              value={newCall?.duration}
              onChange={(e) => {
                setNewCall({ ...newCall, duration: e.target.value });
              }}
              className="w-100"
              type="text"
              placeholder="Call Duration"
            />
          </div>
        </form>
      </Modal>

      <Modal
        className="client_history_model"
        style={{ width: "90vw" }}
        title="Client Comments"
        open={ClientId}
        onOk={() => {
          setClientId(false);
        }}
        onCancel={() => {
          setClientId(false);
        }}
        centered
      >
        {callLoading ? (
          <div
            style={{
              minHeight: "30vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Lottie
              style={{ width: "40%" }}
              animationData={groovyWalkAnimation}
              loop={true}
            />

          </div>
        ) : (
          <>
            <div
              onClick={() => {
                setOpenNewCommect(true);
              }}
              style={{ width: "fit-content", cursor: "pointer" }}
              className="my-2 gap-2 d-flex align-items-center "
            >
              <AiOutlinePlusCircle
                className="text-success"
                style={{ cursor: "pointer", fontSize: "20px" }}
              />
              <h4>New Comment</h4>
            </div>
            <ReactTabulator
              data={commentsData}
              options={options}
              columns={commentColumns}
              layout={"fitData"}
            />
          </>
        )}
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 123325412 }}
        title="Client Comments"
        open={openComment}
        onOk={() => {
          // setShowAddModal(false)
          handleAddComment();
        }}
        onCancel={() => {
          setOpenNewCommect(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></textarea>
          </div>
        </form>
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 1233223412 }}
        title="Client Comments"
        open={oldComment}
        onOk={() => {
          // setShowAddModal(false)
          handleUpdateComment();
        }}
        onCancel={() => {
          setOldComment(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={oldData?.comment}
              onChange={(e) => {
                setOldData({
                  ...oldData,
                  comment: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal>

      <Modal
        open={statsFilteredData && statsFilteredData?.length}
        onCancel={() => setStatsFilteredData([])}
        onOk={() => setStatsFilteredData([])}
        style={{ width: "90%" }}
        className="modalStats"
      >
      <div style={{marginTop:'30px',marginBottom:'30px'}} className=' d-flex align-items-center justify-content-between'>
            <button
              // disabled={disConf}
              className='btn btn-success'
              onClick={()=>{
                // let selected=data.filter(it=>it.check||it.select||it.selected);
                if(selectedRows.length==0){
                  toast.warn('Select Clients First');
                  return
                }
                setShowModal(true)
              }}
            >Confirm</button>
            <button
                onClick={() => {
                  // handleExport();
                  onExportClick()
                }}
                className="btn btn-primary text-light"
              >
                Export
              </button>
              {/* <ReactTabulator
            data={statsFilteredData}
            options={options}
            // sortOrderReverse={true}
            columns={coulmns}
            layout={"fitData"}
          /> */}
          </div>
        {statsFilteredData && statsFilteredData?.length ? (
          <div style={gridStyle}
          className={
            "ag-theme-quartz-dark"
          }>
            <AgGridReact
              // autoGroupColumnDef ={}
              onFilterChanged={()=>{
               onFilterChanged()
            }}
            enableAdvancedFilter={true}
            grandTotalRow={"top"}
                ref={gridRef}
                rowData={statsFilteredData}
                // sideBar={true}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                rowSelection="multiple"
                // columnsi
                rowDragManaged={true}
                tooltipShowDelay={{tooltipShowDelay: 2}}
                onSelectionChanged={onSelectionChanged}
                rowMultiSelectWithClick={true}
                // ref={gridRef}
              isRowSelectable={isRowSelectable}
              />
          </div>
        ) : (
          <h4>No Data</h4>
        )}
      </Modal>



      <Modal title="Assign Clients" open={showModal}onCancel={()=>{
        setShowModal(false)
      }} centered>
        <div className='new_status'>
          <p onClick={()=>{
            setNewStatus('unAssign')
          }} className={newStatus=='unAssign'?'active':''}>UnAssign</p>
          <p onClick={()=>{
            setNewStatus('re_assign')
          }} className={newStatus=='re_assign'?'active':''}>ReAssign</p>
        </div>
        <div className='my-2'>
          {
            newStatus=='unAssign'?
            <div className='text-center'>
              <Button style={{display:'block',margin:'10px auto'}} onClick={()=>{
                unAssignCleints();
              }} className='btn btn-danger'>
                {
                  unAssLoading?(
                    <Spin/>
                  ):(
                    "UnAssign"
                  )
                }
            </Button>
            </div>
            :
            <div>
              <label htmlFor="">Employees</label>
              <Select
                style={{width:'100%'}}
                // style={{

                // }}
                value={selectedemployee}
                onChange={(e)=>{
                  //console.log(e)
                  setselectedemployee(e);
                }}
                options={employeessdata.map((item,index)=>{
                    return {label:`${item.name}`, value:`${item.agent_id}`};
                  })
                }
              />
              <Button style={{display:'block',margin:'10px auto'}} onClick={()=>{
              reAssisgnFunc();
            }} className='btn btn-primary'>
              {
                assignloading?(
                  <Spin/>
                ):(
                  "Reassign"
                )
              }
          </Button>
            </div>
          }
        </div>
        </Modal>


    </React.Fragment>
  );
};

export default Dashboard;
