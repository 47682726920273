import { Card, Col, Modal, Select } from 'antd';
import React, { useEffect, useState } from 'react'
import { AiOutlinePlus } from 'react-icons/ai';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { AppContext } from '../context/AppContextProvider';



const Adminmettings = () => {
  const authUser=localStorage.getItem("authUser");
  console.log(JSON.parse(authUser));
  let uid=JSON.parse(authUser);
  const {user}=useContext(AppContext);
  let agent_id=JSON.parse(localStorage.getItem("authUser"));
  const [pageloading,setpageloading]=useState(true);
  const [followAffterMeeting,setFollowAffterMeeting]=useState('');
  const [feedBack,setFeedBack]=useState('')
  const [sendWhats,setSendWhats]=useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [calltype,setcalltype]=useState("");
  const [answertype,setanswertype]=useState("");
  const [date,setdate]=useState("");
  const [meeting,setmeeting]=useState("");
  const [answer_after,setanswer_after]=useState("");
  const [time,settime]=useState("");
  const [link,setlink]=useState("");
  const [note,setnote]=useState("");
  const [not_answer_Content,setnot_answer_Content]=useState("");
  const [call, setCall] = useState([])
  const [call_id, setcall_id] = useState(null)
  const nav =useNavigate()



  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    const data_send={
      //client_id:data.client_id||agent_id||user.agent_id,
      agent_id:uid,
      response:calltype,
      date_to_follow:date,
      user_offer:link,
      note:note,
      time:time,
      send_whats:sendWhats?'send':'not Send',
      follow_up_after_meeting:followAffterMeeting,
      answer_details:calltype=="answer"?answertype:not_answer_Content,
      feed_back:feedBack,
    }
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/calling_client.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=="success"){
        toast.success(res.message);
      }
      else if(res.status=="error"){
        toast.error(res.message);
      }
      else{
        toast.error("something is error try again")
      }
    })
  };
  const handleCancel = () => {
    setIsModalOpen2(false);
    setIsModalOpen(false)
  };

  const getadminmettings=()=>{
    axios.get("https://ahmed.its.ae/new_quad_wall/admin/select_meeting.php")
    .then((res)=>{
      console.log(res.message);
      setCall(res.message);
    }).finally(()=>{
      setpageloading(false);
    })
  }
  useEffect(()=>{
    getadminmettings()
  },[])

  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleOk2 = () => {
    setIsModalOpen2(false);
    const data_send={
      //client_id:data.client_id,
      agent_id:uid,
      date:meeting,
      call_id:call_id

    }
    console.log('data',data_send);
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/insert_meating.php",JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=="success"){
        toast.success(res.message);
        setmeeting('')
      }
      else if(res.status=="error"){
        toast.error(res.message);
        setmeeting('')

      }
      else{
        toast.error("something is error try again")
        setmeeting('')

      }
    })
  };



  /* async function geteployees(){
    const respo=await axios.post("https://ahmed.its.ae/new_quad_wall/admin/select_calling_data.php",{
      client_id:data.client_id,
      agent_id:data.agent_id,
    }).finally(()=>{
      setpageloading(false);
    })
    setCall(respo.message)

  } */

/*   useEffect(()=>{
    geteployees();
  },[]) */
  return (
    <>
      {
        pageloading?(
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div style={{
            marginTop:'30px'
          }} className='py-5 container'>
            <table className="table mb-0 table-bordered">

            <thead>
              <tr style={{textAlign:'center'}}>
                <th>Id</th>
                <th>Name</th>
                <th>Address</th>
                <th>called</th>
                <th>Job</th>
                <th>Phone</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>

            </tbody>
            </table>
            <div style={{
              display:'flex',
              alignItems:'center',
              justifyContent:'space-between',
              width:'100%'
            }}>
                    <h4>add new call</h4>
            <AiOutlinePlus
              onClick={showModal}
              style={{
                color:'green',
                fontSize:'20px',
                cursor:'pointer',
              }}
            />
            </div>
            <Modal title="Add new Call Response" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <div onClick={()=>{
              setSendWhats(!sendWhats)
            }} style={{margin:'20px 0px',width:'fit-content',display:'flex',alignItems:'center',gap:'3px',cursor:'pointer'}}>
              <input style={{width:'20px',height:'20px',display:'block'}} type="checkbox" checked={sendWhats} onChange={()=>{
              }} />
              <label style={{margin:'0px'}} htmlFor="">Send WhatsApp</label>
            </div>
            <div>
              <label htmlFor="">FeedBack</label>
              <input className='form-control' type="text" onChange={(e)=>{
                setFeedBack(e.target.value);
              }} placeholder='FeedBack' />
            </div>
              <div style={{
                display:'flex',
                alignItems:'center',
                justifyContent:'space-between'
              }}>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <input
                      onClick={() => {
                        setcalltype("answer")
                      }}
                      style={{ display: "block" }}
                      type="radio"
                      id="answer"
                      name="fav_language"
                      value="answer"
                    />
                    <label
                    onClick={()=>{
                      setcalltype("answer")
                    }}
                      htmlFor="answer"
                      style={{
                      }}
                    >
                      answer
                    </label>
                  </div>
                  <div style={{display:'flex',alignItems:'center'}}>
                    <input
                      onClick={(e) => {
                        setcalltype("not_answer")
                      }}
                      style={{ display: "block" }}
                      type="radio"
                      id="not_answer"
                      name="fav_language"
                      value="company"
                    />
                    <label onClick={()=>{
                        setcalltype("not_answer")
                    }} style={{}} htmlFor="not_answer">
                      not answer
                    </label>
                  </div>
              </div>
              {
                calltype=="answer"?(
                  <>
                    <Select
                      style={{ width:'100%' }}
                      placeholder="enter answer"
                      //onChange={handleChange}
                      onChange={(e)=>{
                        console.log(e)
                        setanswertype(e);
                      }}
                      options={[
                        { value: 'interested', label: 'interested' },
                        { value: 'not_interested', label: 'not_interested' },
                        { value: 'interested_but_not_now', label: 'interested_but_not_now' },
                        { value: 'low_budjet', label: 'low budjet' },
                        { value: 'pending', label: 'pending' },
                      ]}
                    />
                    {
                      answertype=="interested_but_not_now"?(
                        <>
                          <input
                            style={{
                              width:'100%',
                              marginTop:'20px'
                            }}
                            type="date"
                            onChange={(e)=>{
                              setdate(e.target.value)
                            }}
                          />
                        </>
                      )
                      :
                      answertype=="not_interested"?
                      (
                        null
                      )
                      :
                      answertype=="interested"?
                      (
                        <div style={{
                          display:'flex',
                          alignItems:'center',
                          justifyContent:'space-between',
                          marginBottom:'10px',
                          marginTop:'10px'
                        }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",

                            }}
                          >
                            <input
                              onClick={() => {
                                setanswer_after("continue")
                              }}
                              style={{ display: "block" }}
                              type="radio"
                              id="continue"
                              name="fav_language2"
                              value="continue"
                            />
                            <label
                              htmlFor="continue"
                              onClick={()=>{
                                setanswer_after("continue")
                              }}
                              style={{
                                display:'flex',
                                alignItems:'center',
                              }}
                            >
                              continue
                            </label>
                          </div>
                          <div style={{display:'flex',alignItems:'center'}}>
                            <input
                              onClick={(e) => {
                                setanswer_after("reschadural")
                              }}
                              style={{ display: "block" }}
                              type="radio"
                              id="reschadural"
                              name="fav_language2"
                              value="reschadural"
                            />
                            <label onClick={()=>{
                                setanswer_after("reschadural")
                            }} style={{ }} htmlFor="reschadural">
                              reschadural
                            </label>
                          </div>
                        </div>
                      ):(
                        null
                      )
                    }
                          {
                            answer_after=="continue"?(
                              <div>
                                <div className='time_date'>
                                  <div>
                                    Follow Up Call
                                  </div>
                                  <div style={{margin:'10px 0px'}}>
                                  <label htmlFor="">Follow Up After Meeting</label>
                                  <input className='form-control' type="time" name="" onChange={(e)=>{
                                    setFollowAffterMeeting(e.target.value);
                                  }} id="" />
                                </div>
                                  <div>
                                    <input style={{
                                      width:'100%',
                                      // height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px',
                                      border:'1px solid #ccc'

                                    }} onChange={(e)=>{
                                      settime(e.target.value)
                                    }} type="time"/>
                                    <input style={{
                                      width:'100%',
                                      // height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px',
                                      border:'1px solid #ccc'
                                    }} onChange={(e)=>{
                                      setdate(e.target.value)
                                    }} type="date"/>
                                  </div>
                                </div>
                                <input style={{
                                      width:'100%',
                                      // height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px',
                                      border:'1px solid #ccc'
                                    }} type="text"
                                    onChange={(e)=>{
                                      setlink(e.target.value)
                                    }}
                                    placeholder='enter link of unit'/>
                                <textarea
                                  onChange={(e)=>{
                                    setnote(e.target.value)
                                  }}
                                  placeholder='enter details'
                                  style={{
                                  resize:'none',
                                  width:'100%',
                                  // height:'50px',
                                  border:'1px solid #ccc',
                                  padding:'10px',
                                  borderRadius:'10px'
                                }}></textarea>
                              </div>
                            ):answer_after=="reschadural"?(
                              <div style={{
                                width:'100%'
                              }}>
                                <input style={{
                                      width:'100%',
                                      height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px',
                                      marginBottom:'10px'
                                    }}type="time" onChange={(e)=>{
                                  settime(e.target.value)
                                }}/>
                                <input style={{
                                      width:'100%',
                                      height:'30px',
                                      borderRadius:'10px',
                                      padding:'10px'
                                    }} type="date" onChange={(e)=>{
                                  setdate(e.target.value);
                                }}/>
                              </div>
                            ):(
                              null
                            )
                          }
                  </>
                ):
                calltype=="not_answer"?

                (
                  <>
                    <Select
                    placeholder="enter resone of not answer"
                        //defaultValue="lucy"
                        style={{ width: "100%" }}
                        onChange={(e)=>{
                          setnot_answer_Content(e);
                        }}
                        options={[
                          { value: 'phone_closed', label: 'phone closed' },
                          { value: 'out_of_service', label: 'out of service' },
                          { value: 'wrong_number', label: 'wrong number' },
                          { value: 'ring_without_response', label: 'ring without response'},
                        ]}
                    />
                  </>
                )
                :
                (
                  null
                )
              }
            </Modal>
            <Modal title="Add New Meeting" open={isModalOpen2} onOk={handleOk2} onCancel={handleCancel}>
              <>
                          <input
                            style={{
                              width:'100%',
                              marginTop:'20px'
                            }}
                            type="date"
                            value={meeting}
                            onChange={(e)=>{
                              setmeeting(e.target.value)
                            }}
                          />
                        </>

            </Modal>

      <table class="table">
        <thead className='text-center'>
          <tr>
                <th>Call id</th>
                <th>client name</th>
                <th>Agent Name</th>
                <th>Date</th>
                <th>User Offer</th>
          </tr>
        </thead>
        <tbody  className='text-center'>
          {call?.map((items ,index)=>{
            return <tr key={index}>
              <td>
                  {items.call_id}
              </td>
              <td>
                  {items.client_name}
              </td>
              <td>
                  {items.agent_name}
              </td>
              <td>
                  {items.date}
              </td>
              <td>
                  <button className='btn btn-outline-danger'  onClick={()=>{
                    showModal2()
                    setcall_id(items.call_id)
                  }} >Meeting</button>
              </td>
            </tr>

          })}

        </tbody>
      </table>

          </div>
        )
      }
    </>
  )
}

export default Adminmettings
