import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactTabulator } from "react-tabulator";
// import { Card, Modal } from "reactstrap";
import { Modal } from "antd";
import { useContext } from "react";
import { AppContext } from "../../components/context/AppContextProvider";
import './Profile.css'
import { Select } from "antd";
import { BiBuildingHouse } from "react-icons/bi";
import { BsHouseSlashFill, BsHouses } from "react-icons/bs";
import { MdOutlineApartment } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const UserProfileTwo = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { user } = useContext(AppContext);
  // console.log(user)
  const [showChaneModal,setShowChaneModal]=useState(false)
  const [showAddModal, setShowAddModal] = useState(false);
  const [clientId, setClientId] = useState("");
  const [showCalls,setShowCalls]=useState(false);

  const [callBackData,setCallBackData]=useState({
    time:'',
    date:'',
    feed_back:''
  })
  const [newStatus,setNewStatus]=useState('interested')
  // const [feedBack,setFeedBack]=useState('');
  const [changeLoading,setChangeLoading]=useState(false)
  const [statusOptions,setStatusOptions]=useState([
    {
      value:"interested",
      label:"interested",
    },
    {
      value:"not_interested",
      label:"not interested",
    },
    {
      value:"low_budget",
      label:"low budget",
    },
    {
      value:"call_back",
      label:"call back",
    },

    {
      value:"follow_meeting",
      label:"Follow Meeting",
    },
    {
      value:"done_deal",
      label:"Done Deal",
    },
    {
      label:"Lost Deal",
      value:"lost_deal",
    },
    {
      value:"follow_meeting",
      label:"Follow Meeting",
    },
    {
      value:"done_deal",
      label:"Done Deal",
    },
    {
      label:"Lost Deal",
      value:"lost_deal",
    },
    // {
    //   value:"delay",
    //   label:"Delay",
    // },
  ])

  const [newCall,setNewCall]=useState({
    feed_back:'',
    duration:'',
  })
  const [oldData, setOldData] = useState(null);
const [deleteComment, setDeleteComment] = useState(null);
const [showAddMeeting,setShowAddMeeting]=useState(false)

const [meetingData,setMeetingData]=useState({
  date:'',
  time:'',
  feed_back:''
})
const [showAddCallModal,setShowAddCallModal]=useState(false)

const [meetingLoading,setMeetingLoading]=useState(false)

  const [not_answer_Content, setnot_answer_Content] = useState("");
  const authUser = JSON.parse(localStorage.getItem("authUser"));
  const [rowData, setRowData] = useState({});
  const [showEdit, setShowEdit] = useState(false);
const [oldComment, setOldComment] = useState(null);
  const [paymentPlanFile, setPaymentPlanFile] = useState(null);
  const [newClient, setNewClient] = useState({
    name: "",
    phone: "",
    job: "",
    address: "",
    type: "cold",
  });
  const [followAffterMeeting, setFollowAffterMeeting] = useState("");
  const [layoutFile, setLayOutFile] = useState(null);
  const [sublocations, setsublocations] = useState([]);
  const [suplocation_id, setsuplocation_id] = useState("");
  const [coumpound_id, setcoumpound_id] = useState("");
  const [location_id, setlocation_id] = useState("");
  const [date, setdate] = useState("");
  const [note, setnote] = useState("");
  const [answertype, setanswertype] = useState("interested");
  const [time, settime] = useState("");
  const marks = [
    {
      value: 0,
      label: "0",
    },
    {
      value: 500000,
      label: "500,000",
    },
    {
      value: 1000000,
      label: "1000,000",
    },
    {
      value: 1500000,
      label: "1,500,000",
    },
    {
      value: 2000000,
      label: "2,000,000",
    },
    {
      value: 2500000,
      label: "2,500,000",
    },
    {
      value: 3000000,
      label: "3,000,000",
    },
    {
      value: 3500000,
      label: "3,500,000",
    },
    {
      value: 4000000,
      label: "4,000,000",
    },
    {
      value: 4500000,
      label: "4,500,000",
    },
    {
      value: 5000000,
      label: "5,000,000",
    },
    {
      value: 5500000,
      label: "5,500,000",
    },
    {
      value: 6000000,
      label: "6,000,000",
    },
    {
      value: 6500000,
      label: "6,500,000",
    },
    {
      value: 7000000,
      label: "7,000,000",
    },
    {
      value: 7500000,
      label: "7,500,000",
    },
    {
      value: 8000000,
      label: "8,000,000",
    },
    {
      value: 8500000,
      label: "8,500,000",
    },
    {
      value: 9000000,
      label: "9,000,000",
    },
    {
      value: 9500000,
      label: "9,500,000",
    },
    {
      value: 10000000,
      label: "10,000,000",
    },
    {
      value: 11000000,
      label: "11,000,000",
    },
    {
      value: 11500000,
      label: "11,500,000",
    },
    {
      value: 12000000,
      label: "12,000,000",
    },
    {
      value: 12500000,
      label: "12,500,000",
    },
    {
      value: 13000000,
      label: "13,000,000",
    },
    {
      value: 13500000,
      label: "13,500,000",
    },
    {
      value: 14000000,
      label: "14,000,000",
    },
    {
      value: 14500000,
      label: "14,500,000",
    },
    {
      value: 15000000,
      label: "15,000,000",
    },
    {
      value: 15500000,
      label: "15,500,000",
    },
    {
      value: 16000000,
      label: "16,000,000",
    },
    {
      value: 16500000,
      label: "16,500,000",
    },
    {
      value: 17000000,
      label: "17,000,000",
    },
    {
      value: 17500000,
      label: "17,500,000",
    },
    {
      value: 18000000,
      label: "18,000,000",
    },
    {
      value: 18500000,
      label: "18,500,000",
    },
    {
      value: 19000000,
      label: "19,000,000",
    },
    {
      value: 19500000,
      label: "19,500,000",
    },
    {
      value: 20000000,
      label: "20,000,000",
    },
  ];

  const [sailData, setSailData] = useState({
    developer: "",
    destination: "",
    project: "",
    phase: "",
    type: "",
    num_of_bed: "",
    number_of_baths: "",
    delivery_date: "",
    area: "",
    down_payment: "",
    total_price: "",
    layout: "",
    master_plan: "",
    payment_plan: "",
  });
  const [originalLeads, setOriginalLeads] = useState([]);
  const [originalColds, setOriginalColds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const agent_id = JSON.parse(localStorage.getItem("authUser"))?.agent_id;
  const [clients, setclients] = useState([]);
  const [originalClients, setOriginalClients] = useState([]);
  const [addLoading, setAddLoading] = useState(false);
  const [loading, setloading] = useState(true);
  const [showleco, setshowleco] = useState("leads");
  const getsellsclients = () => {
    const data_send = {
      agent_id: state?.selData?.agent_id,
      // agent_id:sta?.agent_id||agent_id,
      // type:authUser?.user_type
    };
    console.log(data_send);
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/select_my_assignes.php",
        data_send
      )
      .then((res) => {
        setclients(res.message);
        if (Array.isArray(res.message)) {
          setclients(res.message);
          setOriginalColds(
            res.message.filter((it) => it.client_type == "cold")
          );
          // console.log(res.message.filter(it=>it.client_type=='cold'))
          setOriginalLeads(
            res.message.filter((it) => it.client_type == "leads")
          );
          if (res.message.length > 0) {
            setClientId(res.message[0].client_id);
          }
          setOriginalClients(res.message);
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  const handleAddMeeting=()=>{
    setMeetingLoading(true)
    const data_send={
      ...rowData,
      agent_id,
      // client_status_id:rowData?.
      ...meetingData,
      client_status_id:data?.client?.status_id
    }
    console.log(data_send)
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/add_new_meeting.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        getSheets()
        setShowAddMeeting(false)
      }

      else if(res.status=='faild'){
        toast.error(res.message)
      }
      else{
        toast.error('Something Went Error')
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setMeetingLoading(false)
    })
  }


  const handleOk6 = () => {
    const data_send = {
      client_id: clientId,
      feed_back: feedBack,
      send_whats: sendWhats ? "send" : "not Send",
      response: calltype,
      follow_up_after_meeting: followAffterMeeting,
      answer_details: calltype == "answer" ? answertype : not_answer_Content,
    };
      data_send["user_offer"] = link;
      data_send["note"] = "note";
      data_send["time"] = time;
      data_send["bedrooms"] = bedroomsnumber;
      data_send["bathroom"] = bathroomnumber;
      data_send["min_price"] = money_from;
      data_send["max_price"] = money_to;
      data_send["max_square_meter"] = maxsqu;
      data_send["min_square_meter"] = minsqu;
      data_send["delivery_date"] = delivary;
      data_send["sale_type"] = saletype;
      data_send["finishing"] = finishigstatus;
      data_send["down_payment_plan"] = downpay;
      data_send["monthly_payment_plan"] = monthinstall;
      data_send["installment_year"] = install;
      data_send["location_id"] = location_id;
      data_send["sub_location_id"] = suplocation_id;
      data_send["compound_id"] = compound_id;
      data_send["property_type"] = prottype;
      data_send["client_id"] = data?.client?.client_id;

    data_send["agent_id"] = agent_id;
    // return
    // console.log(JSON.stringify(data_send))
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/insert_request.php",
        JSON.stringify(data_send)
      )
      .then((res) => {

        if (res.status == "success") {
          toast.success(res.message);
          setShowAddModal(false);
         window.location.reload()
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("something is error try again");
        }
      });
  };
  const handleAddCall=()=>{
    // setAddLoading(true)
    const data_send={
      ...newCall,
      client_id:id,
      agent_id:authUser?.agent_id
    }
    // console.log(data_send)
    // console.log(data_send)
    // return ;
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/add_new_call.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        setNewCall({duration:'',feed_back:''})
        getSheets()
        setShowAddCallModal(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setAddLoading(false)
    })
  }

  const getcompounds = () => {
    axios
      .get(
        "https://ahmed.its.ae/new_quad_wall/admin/select_compounds.php"
      )
      .then((res) => {
        //console.log(res)
        setcompoundes(res?.message);
        if (res?.message.length > 0) {
          setcoumpound_id(res?.message[0]?.compound_id);
        }
      });
  };

  const handleCancel = () => {
    setShowAddReq(false);
  };
  const handleOk = () => {
    if (answer_after == "to_resail") {
      const data_send = {
        ...sailData,
        agent_id: authUser.agent_id,
      };
      const formData1 = new FormData();
      formData1.append("image", layoutFile);
      axios
        .post(
          "https://ahmed.its.ae/new_quad_wall/admin/insert_resial_data.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          getdata()
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setIsModalOpen(false);
      const data_send = {
        client_id: clientId,
        agent_id: authUser?.agent_id * 1,
        response: calltype,
        date_to_follow: date,
        send_whats: sendWhats ? "send" : "not Send",
        feed_back: feedBack,
        answer_details: calltype == "answer" ? answertype : not_answer_Content,
      };
      if (calltype == "answer" && answertype == "interested") {
        data_send["user_offer"] = link;
        data_send["note"] = "note";
        data_send["time"] = time;
        data_send["service_apart"] = serviceApart;
        data_send["bedrooms"] = bedroomsnumber;
        data_send["bathroom"] = bathroomnumber;
        data_send["min_price"] = money_from;
        data_send["max_price"] = money_to;
        data_send["max_square_meter"] = maxsqu;
        data_send["min_square_meter"] = minsqu;
        data_send["delivery_date"] = delivary;
        data_send["sale_type"] = saletype;
        data_send["finishing"] = finishigstatus;
        data_send["down_payment_plan"] = downpay;
        data_send["monthly_payment_plan"] = monthinstall;
        data_send["installment_year"] = install;
        data_send["location_id"] = location_id;
        data_send["sub_location_id"] = suplocation_id;
        data_send["compound_id"] = compound_id;
        data_send["property_type"] = prottype;
      }
      //console.log(JSON.stringify(data_send))
      // console.log(data_send)
      // return

      axios
        .post(
          "https://ahmed.its.ae/new_quad_wall/admin/calling_client.php",
          JSON.stringify(data_send)
        )
        .then((res) => {
          setcalltype("");
          setlink("");
          setbedroomsnumber("");
          setbathroomnumber("");
          setmoney_from("");
          setmoney_to("");
          setmaxsqu("");
          setminsqu("");
          setmaxsqu("");
          setdelivary("");
          setsaletype("");
          setfinishigstatus("");
          setdownpay("");
          setmonthinstall("");
          setinstall("");
          setprottype("");
          setanswer_after("");
          if (res.status == "success") {
            toast.success(res.message);
            // geteployees();
          } else if (res.status == "error") {
            toast.error(res.message);
          } else {
            toast.error("something is error try again");
          }
        });
    }
  };

  async function getdata() {
    let Location_data = await axios
      .get(`https://camp-coding.tech/quad_crm/user/select_locations.php`)
      .catch((err) => {
        console.log(err);
      });
    //setLocation(Location_data.message)
    let category_data = await axios
      .get(`https://camp-coding.tech/quad_crm/user/select_category.php`)
      .catch((err) => {
        console.log(err);
      });
    let companies = await axios
      .get(
        "https://ahmed.its.ae/new_quad_wall/admin/select_company.php"
      )
      .catch((err) => {
        console.log(err);
      });
    //console.log(Location_data.message,"dd")

    setlocations(Location_data.message);
    if (Location_data.message.length > 0) {
      setlocation_id(Location_data?.message[0]?.location_id);
    }
  }

  const handelDel = (client_id) => {
    const data_send = {
      client_id,
    };
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/delete_client.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getsellsclients();
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    getdata();
    getcompounds();
    getsellsclients();
  }, []);
  const { id } = useParams();
  const [showReqModal, setShowReqModal] = useState(false);
  const [calldata, setCalldata] = useState({});
  const [showAddReq, setShowAddReq] = useState(false);
  const rowMoved = (row) => {
    console.log("Row moved:", row.getData());
  };
  const initialData = {
    property_type: "",
    bedrooms: "",
    bathroom: "",
    min_price: "",
    max_price: "",
    max_square_meter: "",
    min_square_meter: "",
    delivery_date: "",
    sale_type: "",
    finishing: "",
    down_payment_plan: "",
    monthly_payment_plan: "",
    installment_year: "",
    location_id: "",
    sub_location_id: "",
    compound_id: "",
    date: "",
    service_apart: "",
    client_id: "",
  };
  const [formData, setFormData] = useState(initialData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    setShowAddReq(false);
  };
  const [compound_id, setcompound_id] = useState("");

  const [compoundes, setcompoundes] = useState([]);
  const [link, setlink] = useState("");
  const [masterPlanFile, setMasterPlanFile] = useState(null);
  const [answer_after, setanswer_after] = useState("continue");
  const [bedroomsnumber, setbedroomsnumber] = useState("");
  const [bathroomnumber, setbathroomnumber] = useState("");
  const [delivary, setdelivary] = useState("");
  const [saletype, setsaletype] = useState("");
  const [finishigstatus, setfinishigstatus] = useState("");
  const [install, setinstall] = useState("");
  const [money_from, setmoney_from] = useState("");
  const [money_to, setmoney_to] = useState("");
  const [minsqu, setminsqu] = useState("");
  const [maxsqu, setmaxsqu] = useState("");
  const [downpay, setdownpay] = useState("");
  const [monthinstall, setmonthinstall] = useState("");
  const [serviceApart, setServiceApart] = useState("residential");
  const [prottype, setprottype] = useState("");
  const [calltype, setcalltype] = useState("answer");
  const [feedBack, setFeedBack] = useState("");
  const [sendWhats, setSendWhats] = useState(false);
  const [locations, setlocations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  var headerMenu = function () {
    var menu = [];
    var columns = this.getColumns();

    for (let column of columns) {
      //create checkbox element using font awesome icons
      let icon = document.createElement("i");
      icon.classList.add("fas");
      icon.classList.add(column.isVisible() ? "fa-check-square" : "fa-square");

      //build label
      let label = document.createElement("span");
      let title = document.createElement("span");

      title.textContent = " " + column.getDefinition().title;

      label.appendChild(icon);
      label.appendChild(title);

      //create menu item
      menu.push({
        label: label,
        action: function (e) {
          //prevent menu closing
          e.stopPropagation();

          //toggle current column visibility
          column.toggle();

          //change menu item icon
          if (column.isVisible()) {
            icon.classList.remove("fa-square");
            icon.classList.add("fa-check-square");
          } else {
            icon.classList.remove("fa-check-square");
            icon.classList.add("fa-square");
          }
        },
      });
    }

    return menu;
  };
  const agentField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.name;
    return div;
  };

  const agentEmailField = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.user_email;
    return div;
  };

  const agentEmailPhone = (cell, formatterParams, onRendered) => {
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();
    const div = document.createElement("div");
    div.innerText = rowData?.agent?.phone;
    return div;
  };
  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) + 0; // Get the row position and add 1 to make it 1-based
  };
  const callColumns = [
    {
      title: "No",
      formatter: rowNumFormatter,
      width: 50,
      hozAlign: "center",
      headerMenu,
    }, // Row number column
    {
      title: "feed_back",
      field: "feed_back",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    // {
    //   title: "duration",
    //   field: "duration",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    {
      title: "Last Action Date",
      field: "date",
      sorter: "date",
      sorterParams: { format: "YYYY-MM-DD" },
      headerFilter: "date",
      headerMenu,
    },
    {
      title: "Agent Name",
      field: "agent",
      formatter: agentField,
      sorter: "string",
      headerMenu,
    },
    // {
    //   title: "Agent Email",
    //   field: "agent",
    //   formatter: agentEmailField,
    //   sorter: "string",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Phone",
    //   field: "agent",
    //   formatter: agentEmailPhone,
    //   sorter: "string",
    //   headerMenu,
    // },
  ];
  const options = {
    initialSort: [
      { column: "age", dir: "asc" }, // sort by age in ascending order
    ],
    movableRows: true,
    movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  const getSheets = () => {
    const data_send = {
      client_id: id,
    };

    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/all_client_data.php",
        data_send
      )
      .then((res) => {
        console.log(res);
        setData(res.message);
      })
      .catch((e) => console.log(e))
      .finally(() => {});
  };
  const handleDeleteComment = (comment_id) => {
    // setAddLoading(true)
    const data_send = {
      comment_id,
    };
    console.log(data_send,"data_send")
    // return ;
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/delete_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setDeleteComment(null);
          getSheets();
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };

  const [data, setData] = useState([]);
  useEffect(() => {
    if (id) getSheets();
  }, [id]);


  const historyColumns=[
    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center',headerMenu }, // Row number column
    { title: 'status', field: 'status', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'status_label', field: 'status_label', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    { title: 'Last Action Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date" ,headerMenu},
    { title: 'Agent Name', field: 'agent_name',formatter:agentField, sorter: 'string',headerMenu},
  ];
  const commentColumns = [
    {
      title: "No",
      formatter: rowNumFormatter,
      width: 50,
      hozAlign: "center",
      headerMenu,
    }, // Row number column
    // Row number column
    {
      title: "Comment",
      field: "comment",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },

    {
      title: "Agent Name",
      field: "name",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },
    {
      title: "Actions",
      field: "update",
      formatter: (cell) => {
        const row = cell.getRow();
        const rowData = row.getData();
        const div = document.createElement("div");
        const btn = document.createElement("button");
        btn.innerText = "Update";
        btn.style.backgroundColor='#157347';
        btn.style.color='#fff';
        btn.style.border='none';
        btn.style.padding='10px';
        btn.style.borderRadius='4px'
        btn.style.marginRight='4px'
        const btn2 = document.createElement("button");
        btn2.innerText = "Delete";
        btn2.style.backgroundColor='#bb2d3b';
        btn2.style.color='#fff';
        btn2.style.border='none';
        btn2.style.padding='10px';
        btn2.style.borderRadius='4px'
        btn2.style.marginRight='4px'
        btn.addEventListener("click", () => {
          const value = cell.getValue();
          setOldComment(rowData);
          setOldData(rowData);
        });
        btn2.addEventListener("click", () => {
          setDeleteComment((prev) => ({ ...prev, rowData }));
          handleDeleteComment(rowData?.comment_id);
          console.log(rowData)
        });
        div.appendChild(btn);
        div.appendChild(btn2);
        const table = cell.getTable(); // Get the table instance
        const rowIndex = row.getPosition(); // Get the row index
        const totalRows = table.getRows().length;
        if(rowIndex === totalRows ){
          return div;
        }
      },
      sorter: "string",
      headerMenu,
    },
    // {
    //   title: "Agent Phone",
    //   field: "phone",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },

    // {
    //   title: "Agent Email",
    //   field: "email",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // {
    //   title: "Update",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // ,
    // {
    //   title: "duration",
    //   field: "duration",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    // {
    //   title: "Last Action Date",
    //   field: "date",
    //   sorter: "date",
    //   sorterParams: { format: "YYYY-MM-DD" },
    //   headerFilter: "date",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Name",
    //   field: "agent",
    //   formatter: agentField,
    //   sorter: "string",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Email",
    //   field: "agent",
    //   formatter: agentEmailField,
    //   sorter: "string",
    //   headerMenu,
    // },
    // {
    //   title: "Actions",
    //   field: "update",
    //   formatter: (cell) => {
    //     const row = cell.getRow();
    //     const rowData = row.getData();
    //     const div = document.createElement("div");
    //     const btn = document.createElement("button");
    //     btn.innerText = "Update";
    //     const btn2 = document.createElement("button");
    //     btn2.innerText = "Delete";
    //     btn.addEventListener("click", () => {
    //       const value = cell.getValue();
    //       setOldComment(rowData);
    //       setOldData(rowData);
    //     });
    //     btn2.addEventListener("click", () => {
    //       setDeleteComment((prev) => ({ ...prev, rowData }));
    //       handleDeleteComment(rowData?.comment_id);
    //     });
    //     div.appendChild(btn)
    //     div.appendChild(btn2)
    //     return div;
    //   },
    //   sorter: "string",
    //   headerMenu,
    // },
  ];
  const meetingsColumns = [
    {
      title: "No",
      formatter: rowNumFormatter,
      width: 50,
      hozAlign: "center",
      headerMenu,
    }, // Row number column
    // Row number column
    {
      title: "Agent Name",
      field: "name",
      sorter: "string",
      headerFilter: "input",
      headerMenu,
    },

    // {
    //   title: "Agent Phone",
    //   field: "phone",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },

    // {
    //   title: "Agent Email",
    //   field: "user_email",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },

    {
      title: "Date",
      field: "date",
      sorter: "string",
      headerFilter: "date",
      headerMenu,
    },
    {
      title: "time",
      field: "time",
      sorter: "string",
      headerFilter: "time",
      headerMenu,
    },
    // {
    //   title: "Update",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // ,
    // {
    //   title: "duration",
    //   field: "duration",
    //   sorter: "string",
    //   headerFilter: "input",
    //   headerMenu,
    // },
    // // { title: 'feed_back', field: 'feed_back', sorter: 'string', headerFilter: "input",headerMenu },
    // {
    //   title: "Last Action Date",
    //   field: "date",
    //   sorter: "date",
    //   sorterParams: { format: "YYYY-MM-DD" },
    //   headerFilter: "date",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Name",
    //   field: "agent",
    //   formatter: agentField,
    //   sorter: "string",
    //   headerMenu,
    // },
    // {
    //   title: "Agent Email",
    //   field: "agent",
    //   formatter: agentEmailField,
    //   sorter: "string",
    //   headerMenu,
    // },
    // {
    //   title: "Actions",
    //   field: "update",
    //   formatter: (cell) => {
    //     const row = cell.getRow();
    //     const rowData = row.getData();
    //     const div = document.createElement("div");
    //     const btn = document.createElement("button");
    //     btn.innerText = "Update";
    //     const btn2 = document.createElement("button");
    //     btn2.innerText = "Delete";
    //     btn.addEventListener("click", () => {
    //       const value = cell.getValue();
    //       setOldComment(rowData);
    //       setOldData(rowData);
    //     });
    //     btn2.addEventListener("click", () => {
    //       setDeleteComment((prev) => ({ ...prev, rowData }));
    //       handleDeleteComment(rowData?.comment_id);
    //     });
    //     div.appendChild(btn)
    //     div.appendChild(btn2)
    //     return div;
    //   },
    //   sorter: "string",
    //   headerMenu,
    // },
  ];


  const handleChangeStatus=()=>{
    console.log(rowData)
    // setLoading(true)
    setAddLoading(true)
    if(changeLoading){
      toast.warn('Something in Action Now')
      return
    }
    setChangeLoading(true)
    const data_send={
      client_id:id,
      agent_id,
      status:newStatus,
      ...callBackData,
      feedBack:callBackData?.feed_back,
      status_label:statusOptions.filter(it=>it.value==newStatus)[0].label,
    }
    // console.log(data_send,"data_senddata_send")
    // return
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/change_status.php",JSON.stringify(data_send))
    .then((res)=>{
    console.log(res)
      if(res.status=='success'){
        toast.success(res.message)
        getSheets()
        setFeedBack('')
        setShowChaneModal(false)
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else{
        toast.error("Something Went Error")
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setChangeLoading(false)
    setAddLoading(false)
    })
  }

  const handleUpdateComment = () => {
    // setAddLoading(true)
    const data_send = {
      ...oldData,
    };
    // return ;
    axios
      .post(
        "https://ahmed.its.ae/new_quad_wall/admin/update_comment.php",
        JSON.stringify(data_send)
      )
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          setOldComment(null);
          getSheets(rowData);
        } else if (res.status == "error") {
          toast.error(res.message);
        } else {
          toast.error("Something Went Error");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setAddLoading(false);
      });
  };
  return (
    <div
      className="container py-6"
      style={{
        marginTop: "78px",
        overflow: "auto",
        minHeight: "100vh",
        minWidth: "100%",
      }}
    >
      <div
        className="d-flex row-gap-2 flex-wrap flex-direction-column"
        style={{ margin: "40px 0px" }}
      >
        <div className="container emp-profile">
          <form method="post" onSubmit={(e) => e.preventDefault()}>
            <div className="row">

            </div>
            <div className="col-md-800 prof_user_info">
            <div className="col-md-1000 text-center">
                <div className="profile-head">
                  <h5>{data?.client?.name}</h5>
                  {/* <h6>{data?.client?.added_at}</h6> */}
                  <p className="proile-rating">
                    Date Added : <span>{data?.client?.added_at}</span>
                  </p>
                  {/* <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="home-tab"
                        data-toggle="tab"
                        href="#home"
                        role="tab"
                        aria-controls="home"
                        aria-selected="true"
                      >
                        About
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="profile-tab"
                        data-toggle="tab"
                        href="#profile"
                        role="tab"
                        aria-controls="profile"
                        aria-selected="false"
                      >
                        Timeline
                      </a>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="tab-content profile-tab" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>User Id</label>
                    </div>
                    <div className="col-md-6">
                      <p>{data?.client?.client_id}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Name</label>
                    </div>
                    <div className="col-md-6">
                      <p>{data?.client?.name}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Email</label>
                    </div>
                    <div className="col-md-6">
                      <p>{data?.client?.email}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Phone</label>
                    </div>
                    <div className="col-md-6">
                      <p>{data?.client?.phone}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Phone 2</label>
                    </div>
                    <div className="col-md-6">
                      <p>{data?.client?.phone2}</p>
                    </div>
                  </div>{" "}
                  <div className="row">
                    <div className="col-md-6">
                      <label>Type</label>
                    </div>
                    <div className="col-md-6">
                      <p>{data?.client?.client_type}</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Current Status</label>
                    </div>
                    <div className="col-md-6">
                      <p>{data?.client?.status}</p>
                    </div>
                    <div  className="col-md-12 text-center">
                      <button onClick={()=>{
                        setShowChaneModal(true)
                    }} className="btn btn-primary">Change Status</button>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <label>Experience</label>
                    </div>
                    <div className="col-md-6">
                      <p>Expert</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Hourly Rate</label>
                    </div>
                    <div className="col-md-6">
                      <p>10$/hr</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Total Projects</label>
                    </div>
                    <div className="col-md-6">
                      <p>230</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>English Level</label>
                    </div>
                    <div className="col-md-6">
                      <p>Expert</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label>Availability</label>
                    </div>
                    <div className="col-md-6">
                      <p>6 months</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>Your Bio</label>
                      <br />
                      <p>Your detail description</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-100">
                <div className="profile-work">
                  {true ? (
                    <>
                      <div className="d-flex justify-content-between align-items-center my-4">
                        <h1>Calls</h1>
                        <button onClick={()=>{
                          setShowAddCallModal(true)
                        }} className="btn btn-success">Add</button>
                      </div>
                      <ReactTabulator
                        data={data?.calls}
                        options={options}
                        columns={callColumns}
                        layout={"fitData"}
                      />
                    </>
                  ) : null}
                </div>
              </div>

              <div className="col-md-100">
                {data?.requests && data?.requests?.length == 0 && (
                  <div>
                    <h1>ٌRequests</h1>
                    <button
                      className="btn btn-success"
                      onClick={() => {
                        setShowAddReq(true);
                      }}
                    >
                      Add Request
                    </button>
                  </div>
                )}
                <div className="profile-work">
                  {data?.requests && data?.requests?.length ? (
                    <>
                      <div
                        className="py-6 p-6 container"
                        style={{ marginTop: "78px" }}
                      >
                        <h1>ٌRequests</h1>

                        <table className="table table-nowrap table-hover mb-0">
                          <thead>
                            <tr>
                              <th scope="col">number</th>
                              <th scope="col">id</th>
                              <th scope="col">down_payment_plan </th>
                              <th scope="col">Finishing</th>
                              <th scope="col">installment_year</th>
                              <th scope="col">max_price</th>
                              <th scope="col">max_price</th>
                              <th scope="col">max_square_meter</th>
                              <th scope="col">min_price</th>
                              {/* <th scope="col">max_price</th> */}
                              {/* <th scope="col">Request</th> */}
                              <th scope="col" style={{ width: "120px" }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data?.requests &&
                              Array.isArray(data?.requests) &&
                              data?.requests?.map((item, index) => {
                                return (
                                  <tr>
                                    <th>{index + 1}</th>
                                    <th
                                      onClick={() => {
                                        //navigate("/clientprofile",{state:{data:item}});
                                        // navigate("/clientprofile2", {
                                        //   state: { data: item },
                                        // });
                                        return;
                                      }}
                                      style={{
                                        cursor: "pointer",
                                        color: "blue",
                                        textDecoration: "underline",
                                      }}
                                      scope="row"
                                    >
                                      {item?.client_id}
                                    </th>
                                    <td>
                                      <Link to="#" className="text-dark">
                                        {item?.down_payment_plan}
                                      </Link>
                                    </td>
                                    <td>
                                      <Link to="#" className="text-dark">
                                        {item?.finishing}
                                      </Link>
                                    </td>
                                    <td>
                                      <span className="badge badge-soft-primary font-size-12">
                                        {item?.installment_year}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="badge badge-soft-primary font-size-12">
                                        {item?.max_price}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="badge badge-soft-primary font-size-12">
                                        {item?.max_square_meter}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="badge badge-soft-primary font-size-12">
                                        {item?.min_price}
                                      </span>
                                    </td>
                                    <td>
                                      <span className="badge badge-soft-primary font-size-12">
                                        {item?.min_square_meter}
                                      </span>
                                    </td>

                                    <td>
                                      <button
                                        onClick={() => {
                                          setShowReqModal(
                                            data?.requests &&
                                              data?.requests?.length &&
                                              data?.requests[0]
                                          );
                                        }}
                                        className="btn btn-primary"
                                      >
                                        Request
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-md-100 col-lg-5">
                  <div className="profile-work">
                    {data?.comments && data?.comments?.length ? (
                      <>
                        <h1>Comments</h1>
                        <ReactTabulator
                          data={data?.comments}
                          options={options}
                          columns={commentColumns}
                          layout={"fitData"}
                        />
                      </>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-100 col-lg-5">
                  <div className="profile-work">
                    {data?.meetings && data?.meetings?.length ? (
                      <div >
                        <div className="my-4 d-flex justify-content-between align-items-center">
                          <h1>meetings</h1>
                          {/* <button onClick={()=>{
                              setShowAddMeeting(true)
                              setRowData(rowData)
                          }} className="btn btn-success">Add</button> */}
                        </div>
                        <ReactTabulator
                          data={data?.meetings}
                          options={options}
                          columns={meetingsColumns}
                          layout={"fitData"}
                        />
                      </div>
                    ) : null}
                  </div>
                </div>


                <div className="col-md-100 col-lg-12">
                  <div className="profile-work">
                    {data?.history && data?.history?.length ? (
                      <div >
                        <div className="my-4 d-flex justify-content-between align-items-center">
                          <h1>Client History</h1>
                          {/* <button onClick={()=>{
                              setShowAddMeeting(true)
                              setRowData(rowData)
                          }} className="btn btn-success">Add</button> */}
                        </div>
                        <ReactTabulator
                          data={data?.history}
                          options={
                            options
                          }
                          columns={historyColumns}
                          layout={"fitData"}
                          />
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

            </div>
          </form>
        </div>
        <Modal
          title="add new Request"
          open={showAddReq}
          onOk={handleOk6}
          onCancel={handleCancel}
        >

          {calltype == "answer" ? (
            <>


              {answer_after == "continue" ? (
                <div>
                  <div className="time_date">
                    <div>follow up call</div>
                    <div>
                      <input
                        style={{
                          width: "100%",
                          height: "30px",
                          borderRadius: "10px",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                        onChange={(e) => {
                          settime(e.target.value);
                        }}
                        type="time"
                      />
                      <input
                        style={{
                          width: "100%",
                          height: "30px",
                          borderRadius: "10px",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                        onChange={(e) => {
                          setdate(e.target.value);
                        }}
                        type="date"
                      />
                    </div>
                  </div>
                  <input
                    value={link}
                    style={{
                      width: "100%",
                      height: "30px",
                      borderRadius: "10px",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                    type="text"
                    onChange={(e) => {
                      setlink(e.target.value);
                    }}
                    placeholder="enter link of unit"
                  />
                  {/* <textarea
                                  onChange={(e)=>{
                                    setnote(e.target.value)
                                  }}
                                  placeholder='enter details'
                                  style={{
                                  resize:'none',
                                  width:'100%',
                                  height:'50px',
                                  border:'1px solid #ccc',
                                  padding:'10px',
                                  borderRadius:'10px'
                                }}></textarea> */}
                  <div className="" style={{ margin: "30px 0px" }}>
                    <label htmlFor="">serviced apartments</label>
                    <Select
                      style={{ width: "100%" }}
                      placeholder="enter answer"
                      //onChange={handleChange}
                      value={serviceApart}
                      onChange={(e) => {
                        setServiceApart(e);
                      }}
                      options={[
                        { value: "residential", label: "Residential" },
                        { value: "costal", label: "Costal" },
                        { value: "commercial", label: "Commercial" },
                        { value: "admin", label: "Admin" },
                        { value: "medical", label: "Medical" },
                      ]}
                    />
                  </div>
                  <div className="property_type_div">
                    <h5>property type</h5>
                    <div className="property_types">
                      <div
                        className="property_type"
                        onClick={() => {
                          setprottype("apartment");
                        }}
                      >
                        <div
                          className={`${
                            prottype == "apartment" ? "active" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <MdOutlineApartment />
                        </div>
                        <h6>apartment</h6>
                      </div>
                      <div
                        className="property_type"
                        onClick={() => {
                          setprottype("vila");
                        }}
                      >
                        <div
                          className={`${prottype == "vila" ? "active" : ""}`}
                          style={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <img
                            style={{ width: "30px" }}
                            src={require("../../assets/images/vila.png")}
                            alt=""
                          />
                        </div>
                        <h6>vila</h6>
                      </div>
                      <div
                        className="property_type"
                        onClick={() => {
                          setprottype("twinhouse");
                        }}
                      >
                        <div
                          className={`${
                            prottype == "twinhouse" ? "active" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <BiBuildingHouse />
                        </div>
                        <h6>twinhouse</h6>
                      </div>
                      <div
                        className="property_type"
                        onClick={() => {
                          setprottype("duplex");
                        }}
                      >
                        <div
                          className={`${prottype == "duplex" ? "active" : ""}`}
                          style={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <BsHouseSlashFill />
                        </div>
                        <h6>duplex</h6>
                      </div>
                      <div
                        className="property_type"
                        onClick={() => {
                          setprottype("townhouse");
                        }}
                      >
                        <div
                          className={`${
                            prottype == "townhouse" ? "active" : ""
                          }`}
                          style={{
                            border: "1px solid #ccc",
                          }}
                        >
                          <BsHouses />
                        </div>
                        <h6>townhouse</h6>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: "20px 0px",
                    }}
                  >
                    <h6>bedrooms number</h6>
                    <div className="bedrooms_numbers">
                      <div
                        onClick={() => {
                          setbedroomsnumber("all");
                        }}
                        className={
                          bedroomsnumber == "all"
                            ? "bedrooms_number active"
                            : "bedrooms_number"
                        }
                      >
                        all
                      </div>
                      <div
                        className={`${
                          bedroomsnumber * 1 >= 1
                            ? "bedrooms_number active"
                            : "bedrooms_number"
                        }`}
                        onClick={() => {
                          setbedroomsnumber("1");
                        }}
                      >
                        1
                      </div>
                      <div
                        className={`${
                          bedroomsnumber * 1 >= 2
                            ? "bedrooms_number active"
                            : "bedrooms_number"
                        }`}
                        onClick={() => {
                          setbedroomsnumber("2");
                        }}
                      >
                        2
                      </div>
                      <div
                        className={`${
                          bedroomsnumber * 1 >= 3
                            ? "bedrooms_number active"
                            : "bedrooms_number"
                        }`}
                        onClick={() => {
                          setbedroomsnumber("3");
                        }}
                      >
                        3
                      </div>
                      <div
                        className={`${
                          bedroomsnumber * 1 >= 4
                            ? "bedrooms_number active"
                            : "bedrooms_number"
                        }`}
                        onClick={() => {
                          setbedroomsnumber("4");
                        }}
                      >
                        4
                      </div>
                      <div
                        className={`${
                          bedroomsnumber * 1 >= 5
                            ? "bedrooms_number active"
                            : "bedrooms_number"
                        }`}
                        onClick={() => {
                          setbedroomsnumber("5");
                        }}
                      >
                        5
                      </div>
                      <div
                        className={`${
                          bedroomsnumber * 1 >= 6
                            ? "bedrooms_number active"
                            : "bedrooms_number"
                        }`}
                        onClick={() => {
                          setbedroomsnumber("6");
                        }}
                      >
                        6+
                      </div>
                    </div>
                  </div>
                  <div>
                    <h6 style={{ textTransform: "capitalize" }}>
                      bathrooms number
                    </h6>
                    <div className="bathrooms_numbers">
                      <div
                        onClick={() => {
                          setbathroomnumber("all");
                        }}
                        className={
                          bathroomnumber == "all"
                            ? "bathroom_number active"
                            : "bathroom_number"
                        }
                      >
                        all
                      </div>
                      <div
                        onClick={() => {
                          setbathroomnumber("1");
                        }}
                        className={`${
                          bathroomnumber * 1 >= 1
                            ? "bathroom_number active"
                            : "bathroom_number"
                        }`}
                      >
                        1
                      </div>
                      <div
                        onClick={() => {
                          setbathroomnumber("2");
                        }}
                        className={`${
                          bathroomnumber * 1 >= 2
                            ? "bathroom_number active"
                            : "bathroom_number"
                        }`}
                      >
                        2
                      </div>
                      <div
                        onClick={() => {
                          setbathroomnumber("3");
                        }}
                        className={`${
                          bathroomnumber * 1 >= 3
                            ? "bathroom_number active"
                            : "bathroom_number"
                        }`}
                      >
                        3
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <p
                        style={{
                          textAlign: "center",
                          width: "50%",
                          margin: "0px",
                          fontSize: "19px",
                        }}
                      >
                        from
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          width: "50%",
                          margin: "0px",
                          fontSize: "19px",
                        }}
                      >
                        to
                      </p>
                    </div>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Select
                        showSearch
                        onChange={(e) => {
                          setmoney_from(e);
                        }}
                        value={money_from}
                        style={{
                          width: "50%",
                        }}
                        options={marks.map((item, index) => {
                          return {
                            label: `${item.label}`,
                            value: `${item.value}`,
                          };
                        })}
                      />
                      <Select
                        showSearch
                        onChange={(e) => {
                          setmoney_to(e);
                        }}
                        value={money_to}
                        style={{
                          width: "50%",
                        }}
                        options={marks.map((item, index) => {
                          return {
                            label: `${item.label}`,
                            value: `${item.value}`,
                          };
                        })}
                      />
                    </div>
                  </div>
                  <div className="square_metter">
                    <h5>
                      square meter <span>(m2)</span>
                    </h5>
                    <div className="min_max_square">
                      <div className="min_square">
                        <input
                          value={minsqu}
                          onChange={(e) => {
                            setminsqu(e.target.value);
                          }}
                          type="text"
                          placeholder="min 42"
                        />
                        <span>(m2)</span>
                      </div>
                      <div className="max_square">
                        <input
                          value={maxsqu}
                          onChange={(e) => {
                            setmaxsqu(e.target.value);
                          }}
                          type="text"
                          placeholder="max 1287"
                        />
                        <span>(m2)</span>
                      </div>
                    </div>
                  </div>
                  <div className="delivary_date">
                    <h6 style={{ textTransform: "capitalize" }}>
                      Delivary Date
                    </h6>
                    <div className="delivaries">
                      <div
                        onClick={() => {
                          setdelivary("move_now");
                        }}
                        className={
                          delivary == "move_now"
                            ? "delivary active"
                            : "delivary"
                        }
                      >
                        Move Now
                      </div>
                      <div
                        onClick={() => {
                          setdelivary("2024");
                        }}
                        className={
                          delivary == "2024" ? "delivary active" : "delivary"
                        }
                      >
                        2024
                      </div>
                      <div
                        onClick={() => {
                          setdelivary("2025");
                        }}
                        className={
                          delivary == "2025" ? "delivary active" : "delivary"
                        }
                      >
                        2025
                      </div>
                      <div
                        onClick={() => {
                          setdelivary("2026");
                        }}
                        className={
                          delivary == "2026" ? "delivary active" : "delivary"
                        }
                      >
                        2026
                      </div>
                      <div
                        onClick={() => {
                          setdelivary("2027");
                        }}
                        className={
                          delivary == "2027" ? "delivary active" : "delivary"
                        }
                      >
                        2027
                      </div>
                    </div>
                  </div>
                  <div className="sale_type_div">
                    <h6>sale type</h6>
                    <div className="sales_type">
                      <div
                        onClick={() => {
                          setsaletype("developer_sale");
                        }}
                        className={`${
                          saletype == "developer_sale"
                            ? "sale_type active"
                            : "sale_type"
                        }`}
                      >
                        Primary
                      </div>
                      <div
                        onClick={() => {
                          setsaletype("resale");
                        }}
                        className={`${
                          saletype == "resale"
                            ? "sale_type active"
                            : "sale_type"
                        }`}
                      >
                        resale
                      </div>
                      {/* <div className={`${saletype=="nawy_now"?"sale_type active":"sale_type"}`}
                                    onClick={()=>{
                                      setsaletype("nawy_now")
                                    }}
                                    >nawy now</div> */}
                    </div>
                  </div>
                  <div className="finishing_div">
                    <h6>finishing</h6>
                    <div className="finishings">
                      <div
                        onClick={() => {
                          setfinishigstatus("all");
                        }}
                        className={
                          finishigstatus == "all"
                            ? "finishing active"
                            : "finishing"
                        }
                      >
                        all
                      </div>
                      <div
                        onClick={() => {
                          setfinishigstatus("unfinished");
                        }}
                        className={
                          finishigstatus == "unfinished"
                            ? "finishing active"
                            : "finishing"
                        }
                      >
                        unfinished
                      </div>
                      <div
                        onClick={() => {
                          setfinishigstatus("semi_finished");
                        }}
                        className={
                          finishigstatus == "semi_finished"
                            ? "finishing active"
                            : "finishing"
                        }
                      >
                        semi-finished
                      </div>
                      <div
                        onClick={() => {
                          setfinishigstatus("finished");
                        }}
                        className={`${
                          finishigstatus == "finished"
                            ? "finishing active"
                            : "finishing"
                        }`}
                      >
                        finished
                      </div>
                    </div>
                  </div>
                  <div className="payment_paln_div">
                    <h6 style={{ textTransform: "capitalize" }}>
                      payment plan
                    </h6>
                    <div className="pay_methods">
                      <div className="down_payment">
                        <h5 style={{ textAlign: "center" }}>Down Payment</h5>
                        <input
                          value={downpay}
                          onChange={(e) => {
                            setdownpay(e.target.value);
                          }}
                          type="text"
                          placeholder="Up to"
                        />
                      </div>
                      <div className="monthly_installment">
                        <h5 style={{ textAlign: "center", width: "100%" }}>
                          monthly installment
                        </h5>
                        <input
                          value={monthinstall}
                          onChange={(e) => {
                            setmonthinstall(e.target.value);
                          }}
                          type="text"
                          placeholder="Up to"
                        />
                        <span>EGP</span>
                      </div>
                    </div>
                  </div>
                  <div className="installment_years">
                    <h5>installment years</h5>
                    <div className="installments">
                      <div
                        onClick={() => {
                          setinstall("all");
                        }}
                        className={
                          install == "all" ? "install active" : "install"
                        }
                      >
                        all
                      </div>
                      <div
                        onClick={() => {
                          setinstall("1");
                        }}
                        className={
                          install.replace("+", "") * 1 >= 1
                            ? "install active"
                            : "install"
                        }
                      >
                        1
                      </div>
                      <div
                        onClick={() => {
                          setinstall("2");
                        }}
                        className={
                          install.replace("+", "") * 1 >= 2
                            ? "install active"
                            : "install"
                        }
                      >
                        2
                      </div>
                      <div
                        onClick={() => {
                          setinstall("3");
                        }}
                        className={
                          install.replace("+", "") * 1 >= 3
                            ? "install active"
                            : "install"
                        }
                      >
                        3
                      </div>
                      <div
                        onClick={() => {
                          setinstall("4");
                        }}
                        className={
                          install.replace("+", "") * 1 >= 4
                            ? "install active"
                            : "install"
                        }
                      >
                        4
                      </div>
                      <div
                        onClick={() => {
                          setinstall("5");
                        }}
                        className={
                          install.replace("+", "") * 1 >= 5
                            ? "install active"
                            : "install"
                        }
                      >
                        5
                      </div>
                      <div
                        onClick={() => {
                          setinstall("6+");
                        }}
                        className={
                          install.replace("+", "") * 1 >= 6
                            ? "install active"
                            : "install"
                        }
                      >
                        6+
                      </div>
                    </div>
                  </div>
                  <div className="select_area">
                    <h5 style={{ textTransform: "capitalize" }}>area</h5>
                    <div className="sullocation">
                      <Select
                        style={{ width: "48%" }}
                        value={location_id}
                        className=" nice-select"
                        placeholder="select location"
                        onChange={(e) => {
                          let data = locations.filter(
                            (item) => item.location_id == e
                          );

                          setlocation_id(e);
                          // setbuildingdata({...buildingdata,sub_location_id:data[0]?.sub_location[0]?.sub_id});
                          setlocation_id(e);
                          //setbuildingdata({...buildingdata,location_id:e});
                        }}
                        id=""
                        options={locations.map((item, index) => {
                          return {
                            label: `${item.location_name}`,
                            value: `${item.location_id}`,
                          };
                        })}
                      />
                      <Select
                        style={{ width: "48%" }}
                        value={suplocation_id}
                        className=" nice-select"
                        placeholder="select location"
                        onChange={(e) => {
                          //console.log(e)
                          setsuplocation_id(e);
                        }}
                        id=""
                        // options={Location?.find(item=>item.location_id==buildingdata.location_id)?.sub_location?.map(item=>{
                        //   return {label:`${item.name}`, value:`${item.sub_id}`};
                        // })}
                        options={sublocations?.map((item, index) => {
                          return {
                            label: `${item.name}`,
                            value: `${item.sub_id}`,
                          };
                        })}
                      />
                    </div>
                  </div>
                  <div className="select_compund">
                    <h6 style={{ textTransform: "capitalize" }}>compound</h6>

                    <Select
                      showSearch
                      onChange={(e) => {
                        setcompound_id(e);
                      }}
                      value={compound_id}
                      style={{
                        width: "100%",
                      }}
                      options={compoundes.map((item, index) => {
                        return {
                          label: `${item.compound_name}`,
                          value: `${item.compound_id}`,
                        };
                      })}
                    />

                    {/* <div className="add_compound">
                                    add compound <span>+</span>
                                  </div> */}
                  </div>
                </div>
              ) : answer_after == "reschadural" ? (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <input
                    style={{
                      width: "100%",
                      height: "30px",
                      borderRadius: "10px",
                      padding: "10px",
                      marginBottom: "10px",
                    }}
                    type="time"
                    onChange={(e) => {
                      settime(e.target.value);
                    }}
                  />
                  <input
                    style={{
                      width: "100%",
                      height: "30px",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                    type="date"
                    onChange={(e) => {
                      setdate(e.target.value);
                    }}
                  />
                </div>
              ) : answer_after == "to_resail" ? (
                <div className="resile_div">
                  <div className="row">
                    <div className="col-lg-6">
                      <label htmlFor="">Developer:</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            developer: e.target.value,
                          });
                        }}
                        value={sailData?.developer}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Destination :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            destination: e.target.value,
                          });
                        }}
                        value={sailData?.destination}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Project :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            project: e.target.value,
                          });
                        }}
                        value={sailData?.project}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Type :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            type: e.target.value,
                          });
                        }}
                        value={sailData?.type}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Number Of Beds :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            num_of_bed: e.target.value,
                          });
                        }}
                        value={sailData?.num_of_bed}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Number Of Baths :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            number_of_baths: e.target.value,
                          });
                        }}
                        value={sailData?.number_of_baths}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Delivery date :</label>
                      <input
                        type="date"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            delivery_date: e.target.value,
                          });
                        }}
                        value={sailData?.delivery_date}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Area :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            area: e.target.value,
                          });
                        }}
                        value={sailData?.area}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Down Payment :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            down_payment: e.target.value,
                          });
                        }}
                        value={sailData?.down_payment}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Total Price :</label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setSailData({
                            ...sailData,
                            total_price: e.target.value,
                          });
                        }}
                        value={sailData?.total_price}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Layout :</label>
                      <input
                        type="file"
                        onChange={(e) => {
                          setLayOutFile(e.target.files[0]);
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Master Plan :</label>
                      <input
                        type="file"
                        onChange={(e) => {
                          setMasterPlanFile(e.target.files[0]);
                        }}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label htmlFor="">Payment Plan :</label>
                      <input
                        type="file"
                        onChange={(e) => {
                          setPaymentPlanFile(e.target.files[0]);
                        }}
                      />
                    </div>
                  </div>
                  {/* here */}
                </div>
              ) : null}
            </>
          ) : calltype == "not_answer" ? (
            <>
              <Select
                placeholder="enter resone of not answer"
                //defaultValue="lucy"
                style={{ width: "100%" }}
                onChange={(e) => {
                  setnot_answer_Content(e);
                }}
                options={[
                  { value: "phone_closed", label: "phone closed" },
                  { value: "out_of_service", label: "out of service" },
                  { value: "wrong_number", label: "wrong number" },
                  {
                    value: "ring_without_response",
                    label: "ring without response",
                  },
                ]}
              />
            </>
          ) : null}
        </Modal>

        <Modal className='' style={{ width:'90vw' }} title="Add New Call" open={showAddCallModal} onOk={()=>{
        // setShowAddModal(false)
        handleAddCall();
      }} onCancel={()=>{
        setShowAddCallModal(false)
      }} centered>
        <form style={{width:'100%'}} action="">
          <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">FeedBack</label>
            <input value={newCall?.feed_back} onChange={(e)=>{
              setNewCall({...newCall,feed_back:e.target.value})
            }} className='w-100' type="text" placeholder='FeedBack' />
          </div>
          {/* <div style={{width:'100%'}}>
            <label className='w-100' htmlFor="">Call Duration</label>
            <input value={newCall?.duration} onChange={(e)=>{
              setNewCall({...newCall,duration:e.target.value})
            }} className='w-100' type="text" placeholder='Call Duration' />
          </div> */}
        </form>
      </Modal>


      <Modal className='' style={{ width:'90vw' }} title="Add Meeting" open={showAddMeeting} onOk={()=>{
        // setShowAddModal(false)
        handleAddMeeting();
      }} onCancel={()=>{
        setShowAddMeeting(false)
      }} centered>
        <form style={{width:'100%'}} action="">
        <>
            <label style={{marginTop:'10px'}} className='' htmlFor="">Date</label>
              <input
                style={{
                  width:'100%',
                  padding:'10px',
                  borderRadius:'10px',
                  outline:'none',
                }}
                type="date"
                value={meetingData?.date}
                onChange={(e)=>{
                  setMeetingData({...meetingData,date:e.target.value})
                }}
              />
              <label style={{marginTop:'10px'}} htmlFor="">Time</label>
              <input
                type="time"
                style={{
                  width:'100%',
                  padding:'10px',
                  borderRadius:'10px',
                  outline:'none',
                }}
                value={meetingData?.time}
                onChange={(e)=>{
                  setMeetingData({...meetingData,time:e.target.value})
                }}
              />
              <label style={{marginTop:'10px'}} htmlFor="">FeedBack</label>
              <input
                type="text"
                style={{
                  width:'100%',
                  padding:'10px',
                  borderRadius:'10px',
                  outline:'none',
                }}
                value={meetingData?.feed_back}
                onChange={(e)=>{
                  setMeetingData({...meetingData,feed_back:e.target.value})
                }}
              />
          </>
        </form>
        {
          meetingLoading&&<div className='my-3'>
            <p>Loading...</p>
          </div>
        }
      </Modal>

      <Modal
        className=""
        style={{ width: "90vw", zIndex: 1233223412 }}
        title="Client Comments"
        open={oldComment}
        onOk={() => {
          // setShowAddModal(false)
          handleUpdateComment();
        }}
        onCancel={() => {
          setOldComment(false);
        }}
        centered
      >
        <form style={{ width: "100%" }} action="">
          <div style={{ width: "100%" }}>
            <label className="w-100" htmlFor="">
              Comment
            </label>
            <textarea
              className="w-100"
              type="text"
              placeholder="Comment"
              value={oldData?.comment}
              onChange={(e) => {
                setOldData({
                  ...oldData,
                  comment: e.target.value,
                });
              }}
            ></textarea>
          </div>
        </form>
      </Modal>





      <Modal title="Change Client Status" open={showChaneModal} onOk={()=>{
        if(addLoading){
          toast.warn('request is running now Please Wait');
          return
        }
        // handleAddClient()
        // handleOk()
        handleChangeStatus()
      }} onCancel={()=>{
        setShowChaneModal(false)
      }} centered>
        <div>
          <label htmlFor="">New Status</label>
          <select value={newStatus} onChange={(e)=>{
            setNewStatus(e.target.value)
          }} style={{width:'100%',padding:'4px',border:'1px solid #ccc',borderRadius:'10px',backgroundColor:'transparent'}} name="" id="">
            {
              statusOptions&&statusOptions.map((item,index)=>{
                return(
                  <option value={item.value}>{item.label}</option>
                )
              })
            }
          </select>
        </div>
        <div>
          {
            newStatus=='call_back'&&
            <div className='my-2 feed_back'>
              <div>
                <label htmlFor="">Date</label>
                <input type="date" onChange={(e)=>{
                  setCallBackData({
                    ...callBackData,
                    date:e.target.value
                  })
                }} />
              </div>
              <div>
                <label htmlFor="">Time</label>
                <input type="time" onChange={(e)=>{
                  setCallBackData({
                    ...callBackData,
                    time:e.target.value
                  })
                }} />
              </div>
            </div>
          }
        </div>
        <div>
          {
            true&&
            <div className='my-2 feed_back'>
              <label htmlFor="">Feed Back</label>
              <input value={callBackData?.feed_back} placeholder='FeedBack' type="text"  onChange={(e)=>{
                  setCallBackData({
                    ...callBackData,
                    feed_back:e.target.value
                  })
                }} />
            </div>
          }
        </div>
      </Modal>



      </div>
    </div>
  );
};

export default UserProfileTwo;
