import React from 'react'
import { useContext } from 'react'
import { AppContext } from '../../components/context/AppContextProvider'
import axios from 'axios';
import { useEffect } from 'react';
import { useState } from 'react';
import 'react-tabulator/lib/styles.css';
import "tabulator-tables/dist/css/tabulator.min.css"; //import Tabulator stylesheet
import { ReactTabulator } from 'react-tabulator'
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Select } from 'antd'
import { toast } from 'react-toastify';

// import Tabulator from 'tabulator-tables';
import Lottie from 'lottie-react';
import groovyWalkAnimation from "../../assets/images/Animation - 1717928166617.json";
// import './sellesAssign.css'
const Sheets = () => {
  const navigate=useNavigate()
  const authUser=JSON.parse(localStorage.getItem("authUser"));

  const [rowData,setRowData]=useState({});
  const [showDelModel,setShowDelModel]=useState(false)
  const imageFormatter = (cell, formatterParams, onRendered) => {
    // console.log(cell.getValue())
    // console.log(row.getRow())
    const value = cell.getValue();
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the image element
    const img = document.createElement("img");
    img.src = value;
    img.alt = "Profile";
    img.style.width = "30px";
    img.style.height = "30px";
    img.style.cursor = "pointer";
    img.style.display='block';
    img.style.margin='auto'

    // Add click event listener
    img.addEventListener("click", () => {
      console.log(rowData);
      window.open(rowData?.sheet_link,"_blank")
      // window.open(`whatsapp://send?text=!&phone=+2${rowData.client_phone.startsWith("0")?rowData.client_phone:`${rowData.client_phone}`}`,'_blank')
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    return img;
  };

  const FileBtn = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "Show File Clients";
    button.style.cursor = "pointer";
    // button?.classList?.add('btn-success')
    button.style.color='white'
    button.style.backgroundColor='green'
    button.style.border='none'
    button.style.borderRadius='4px'
    button.style.padding='4px'
    // Add click event listener
    button.addEventListener("click", () => {
      console.log(rowData);
      navigate("/file_clients",{state:{sheet:rowData}});
      // handelDel(rowData?.client_id)
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    return button;
  };

  const DeleteBtn = (cell, formatterParams, onRendered) => {
    const row = cell.getRow();
    const rowData = row.getData();

    // Create the button element
    const button = document.createElement("button");
    button.innerText = "Delete File";
    button.style.cursor = "pointer";
    // button?.classList?.add('btn-success')
    button.style.color='white'
    button.style.backgroundColor='red'
    button.style.border='none'
    button.style.borderRadius='4px'
    button.style.padding='4px'
    // Add click event listener
    button.addEventListener("click", () => {
      setShowDelModel(true)
      setRowData(rowData);
      // handelDel(rowData?.client_id)
      // alert(JSON.stringify(rowData, null, 2)); // For demonstration purposes
    });

    return button;
  };

  const rowNumFormatter = (cell) => {
    return cell.getRow().getPosition(true) +0; // Get the row position and add 1 to make it 1-based
};

const countFormatter = (cell) => {
  const count = cell.getTable().getDataCount(); // Get total count of rows
  return `Count: ${count}`;
};


  const columns = [

    { title: 'No', formatter: rowNumFormatter, width: 50, hozAlign: 'center' }, // Row number column
    { title: `Title`, field: 'title', sorter: 'string', headerFilter: "input",topCalc: 'count' },
    { title: 'source', field: 'data_source', sorter: 'string', headerFilter: "input",topCalc: 'count' },
    { title: 'Sheet Row Count', field: 'raw_count', sorter: 'string', headerFilter: "input" ,topCalc: 'count'},
    { title: 'Number Of Client In Sheet', field: 'done_inserted', sorter: 'number', headerFilter: "input" ,topCalc: 'count'},
    { title: 'Uploaded Date', field: 'date', sorter: 'date',sorterParams: { format: 'YYYY-MM-DD' }, headerFilter: "date",topCalc: 'count' },
    { title: 'Uploaded By Agent Name', field: 'uplouded_by_agent_name', sorter: 'string' ,topCalc: 'count'},
    { title: 'File', field: 'excelSheet', sorter: 'string' , formatter: imageFormatter,topCalc: 'count'},
    { title: 'Clients In File', field: 'client_id',formatter:FileBtn},
    { title: 'Delete File', field: 'client_id',formatter:DeleteBtn},
  ];


  const [title,setTitle]=useState('');
  const [file,setFile]=useState(null);
  const [sourceId,setSourceId]=useState('');
  const [sources,setSources]=useState([]);
  const [showAddModel,setShowAddModel]=useState(false)
  const [upLoading,setUpLoading]=useState(false)


  const handleUpload=()=>{
    if(file==null){
      toast.warn('Select File')
      return;
    }
    setUpLoading(true)
    const formData=new FormData();
    formData.append('file_attachment',file);
    formData.append('source_id',sourceId);
    formData.append('title',title);
    formData.append('type',"leads");
    formData.append('added_by_agent_id',authUser?.agent_id);
    console.log(formData)
    // set
    // return
    axios.post('https://ahmed.its.ae/new_quad_wall/admin/uploud_clients_excel.php',formData)
    .then((res)=>{
      console.log(res)
      toast.success(`Success To Upload ${res?.add_counter} Client From This File`)
      toast.warn(`There Are ${res?.duplication_counter} Duplicated Client Or Existed Before In This File`)
      getSheets()
      setShowAddModel(false)
      // if(res.status=='success'){
      //   toast.success(res.message)
      // }
      // else if(res.status=='error'){
      //   toast.error(res.message)
      // }
      // else {
      //   toast.error("Something Went Error");
      // }
      // setTitle('');
      // setFile(null)
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setUpLoading(false)
    })
  }

  const getSources=()=>{
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/select_sources.php",{
      "type":"leads"
    })
    .then((res)=>{
      if(Array.isArray(res.message)){
        setSources(res.message)
        if(res.message.length>0){
          setSourceId(res.message[0].source_id)
        }
      }
    })
    .catch(e=>console.log(e))
  }



  const rowMoved = (row) => {
    console.log('Row moved:', row.getData());
  };
  const options = {
    initialSort: [
      { column: 'age', dir: 'asc' }, // sort by age in ascending order
    ],
    movableRows: true,movableColumns: true, // enable row dragging
    rowMoved: rowMoved, // optional, handle row move events
  };
  const [data,setData]=useState([]);
  const [pageLoading,setPageLoading]=useState(false)
  const getSheets=()=>{
    setPageLoading(true)
    const data_send={
      type:'leads',
      agent_id:authUser?.agent_id,
      user_type:authUser?.user_type,
    }
    axios.post("https://ahmed.its.ae/new_quad_wall/admin/select_sheets.php",JSON.stringify(data_send))
    .then((res)=>{
      console.log(res)
      if(Array.isArray(res.message)){
        setData(res.message.map((item)=>{
          return {...item,excelSheet:require("../../assets/images/excel.png")}
        }))
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setPageLoading(false)
    })
  }

  const handleDelete=()=>{
    setUpLoading(true)
    const data_send={
      uplouded_sheets_id:rowData?.uplouded_sheets_id,
    }
    axios.post('https://ahmed.its.ae/new_quad_wall/admin/delete_sheet.php',JSON.stringify(data_send))
    .then((res)=>{
      if(res.status=='success'){
        toast.success(res.message)
        setShowDelModel(false)
        getSheets()
      }
      else if(res.status=='error'){
        toast.error(res.message)
      }
      else {
        toast.error('Something Went Error')
      }
    })
    .catch(e=>console.log(e))
    .finally(()=>{
      setUpLoading(false)
    })
    console.log(data_send);
  }

  useEffect(()=>{
    getSheets()
    getSources()
  },[

  ])
  return (
    <>
      {
        pageLoading?(
          <div style={{
            minHeight:'100vh',
            display:'flex',
            alignItems:'center',
            justifyContent:'center'
          }}>
            <Lottie style={{ width:'40%' }}  animationData={groovyWalkAnimation} loop={true} />
            {/* <img src={require("../../assets/images/Animation - 1717926811158(1).gif")} alt="" /> */}
          </div>
          )
        :(
          <div className='container py-6' style={{marginTop:'78px',overflow:'auto',minHeight:'100vh',minWidth:'100%'}}>
              <div style={{margin:'40px 0px'}} className='d-flex align-items-center justify-content-between '>
              <h5>Uploaded Leads Sheets</h5>
              <img style={{width:'30px',cursor:'pointer'}} onClick={()=>{
                setShowAddModel(true)
              }} src={require("../../assets/images/upload_file.png")} alt="" />
            </div>
            <ReactTabulator
              data={data}
              options={
                options
              }
              // sortOrderReverse={true}
              columns={columns}
              layout={"fitData"}
              />
                    <Modal onOk={handleUpload} title="Add Customer" open={showAddModel} onCancel={()=>{
        setShowAddModel(false)
      }} centered>
        <form onSubmit={(e)=>{e.preventDefault()}} action="">
        <div style={{width:'100%'}} className='form_control'>
            <label htmlFor="">Title</label>
            <input value={title} onChange={(e)=>{
              setTitle(e.target.value)
            }} type="text" />
          </div>
          <div style={{width:'100%'}} className='form_control'>
            <label htmlFor="">Uploaded File</label>
            <input onChange={(e)=>{
              setFile(e.target.files[0])
            }} type="file" />
          </div>
          <div style={{width:'100%'}} className='form_control'>
            <label htmlFor="">The Source</label>
            <select
              style={{width:'100%'}}
              onChange={(e)=>{
                setSourceId(e.target.value)
              }}
              value={sourceId}
            >
              {
                sources&&sources.map((item,index)=>{
                  return (
                    <option value={item.source_id}>{item.name}</option>
                  )
                })
              }
            </select>
          </div>
        </form>
        {
          upLoading&&<div>
            <h5>...loading</h5>
          </div>
        }
      </Modal>


      <Modal onOk={handleDelete} title="Add Customer" open={showDelModel} onCancel={()=>{
        setShowDelModel(false)
      }} centered>
        <div>
          <h5>Do You Want To Delete This File</h5>
          <p className='my-2'>You Should Know That the clients in this file will deleted too</p>

        </div>
        {
          upLoading&&<div>
            <h5>...loading</h5>
          </div>
        }
      </Modal>



          </div>
        )
      }
    </>
  )
}

export default Sheets
