import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { Card, Col, Modal, Select, Spin } from "antd";
import axios from 'axios'
import { useEffect } from "react";
import { toast } from "react-toastify";


function Review() {
  const location = useLocation();
  const { data } = location.state;
  const [pageloading,setpageloading]=useState(true);
  const [addcomment, setaddcomment] = useState({
    agent_id: 1,
    client_id: data.client_id,
    comment: "He doesn't want to pay",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comments, setcomments] = useState([])
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
    handelcomments()

  };
  async function handelcomments(){
    const data_send={
      ...addcomment
    }
    const res= await axios.post("https://ahmed.its.ae/new_quad_wall/admin/insert_comment.php",data_send)
    console.log(res);
    if(res.status=="success"){
      toast.success(res.message);
      geteployees();
      setaddcomment({
        agent_id: 1,
        client_id: data.client_id,
        comment: '',
      })
    }
    else if(res.status=="error"){
      toast.error(res.message);
    }
    else{
      toast.error("something is error try again");
    }
  }



  async function geteployees(){
    const respo=await axios.post("https://ahmed.its.ae/new_quad_wall/admin/select_client_comment.php",{
      client_id:data.client_id,
      agent_name:data.name
    }).finally(()=>{
      setpageloading(false);
    })
    setcomments(respo.message)
  }

  useEffect(()=>{
    geteployees();
  },[])

  return (
    <>
      {
        pageloading?(
          [1, 2, 3].map(x => (
            <Col xs={24} md={12} lg={8} key={x}>
              <Card loading minHeight={200} />
            </Col>
          ))
        ):(
          <div className="container-fluid py-5">
          <div className="page-content py-5">
            <div className="row  pb-3">
              <div className="col-lg-6">
                <h2 className="text-black">Comments</h2>
              </div>
              <div className="col-lg-6 text-end">
                <button className="btn btn-outline-primary" onClick={showModal}>
                  Add Comments
                </button>
              </div>
              <Modal
                title="Add comment"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
              >
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <Select
      //defaultValue="lucy"
      style={{
        width: '100%',
      }}
      value={addcomment.comment}
      onChange={(e)=>{
        setaddcomment({ ...addcomment, comment: e})
      }}

      options={[
        {
          value: "He doesn't want to pay",
          label: "He doesn't want to pay",
        },

        {
          value: "wrong",
          label: "wrong",
        },

        {
          value: "busy",
          label: "busy",
        },
        {
          value: "Dodge",
          label: "Dodge",
        },
        {
          value: "sales of another company",
          label: "sales of another company",
        },
        {
          value: "block us",
          label: "block us",
        },
        {
          value: "low budget",
          label: "low budget",
        },
        {
          value: "contact in WhatsApp",
          label: "contact in WhatsApp",
        },
        {
          value:'have budjet',
          label:'have budjet',
        }
      ]}
    />
                  {/* <textarea
                    onChange={(e) => {
                      setaddcomment({ ...addcomment, comment: e.target.value });
                    }}
                    name="comment"
                    className="form-control"
                    placeholder="Write Your Comment"
                    id=""
                    cols="10"
                    rows="10"
                    value={addcomment.comment}
                  ></textarea> */}
                </form>
              </Modal>
            </div>

            {
              comments.length>0?(
                <div className="container bg-white rounded-3 shadow-lg py-3">
              {comments?.map((items)=>{
                return  <div className="row  text-center justify-content-center align-items-center p-3 border-bottom">
                <div className="col-lg-4">
                  <div className="row g-0">
                      <h3 className="text-black">{items.agent_name}</h3>

                    </div>
                  </div>
                <div className="col-lg-8">
                  <span className="text-muted fs-4">
                    {items.comment}
                  </span>
                </div>
              </div>
              })}


          </div>
              ):(
                null
              )
            }
           </div>

        </div>
        )
      }
    </>
  );
}

export default Review;
